import { AssetByAssetCodeQuery, RegistryCode } from "@/app/types/generated/graphql";

export const demoAssets: AssetByAssetCodeQuery = {
  assetByAssetCode: {
    attributes: [
      {
        name: "location",
        registryCode: RegistryCode.Mrets,
        values: [
          {
            code: "AL",
            displayName: "Alabama",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AK",
            displayName: "Alaska",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AS",
            displayName: "American Samoa",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AZ",
            displayName: "Arizona",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AR",
            displayName: "Arkansas",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AA",
            displayName: "Armed Forces Americas",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AE",
            displayName: "Armed Forces Europe",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AP",
            displayName: "Armed Forces Pacific",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "CA",
            displayName: "California",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "CO",
            displayName: "Colorado",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "CT",
            displayName: "Connecticut",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "DE",
            displayName: "Delaware",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "DC",
            displayName: "District of Columbia",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "FL",
            displayName: "Florida",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "GA",
            displayName: "Georgia",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "GU",
            displayName: "Guam",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "HI",
            displayName: "Hawaii",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "ID",
            displayName: "Idaho",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "IL",
            displayName: "Illinois",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "IN",
            displayName: "Indiana",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "IA",
            displayName: "Iowa",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "KS",
            displayName: "Kansas",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "KY",
            displayName: "Kentucky",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "LA",
            displayName: "Louisiana",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "ME",
            displayName: "Maine",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MH",
            displayName: "Marshall Islands",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MD",
            displayName: "Maryland",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MA",
            displayName: "Massachusetts",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MI",
            displayName: "Michigan",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MN",
            displayName: "Minnesota",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MS",
            displayName: "Mississippi",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MO",
            displayName: "Missouri",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "MT",
            displayName: "Montana",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NE",
            displayName: "Nebraska",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NV",
            displayName: "Nevada",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NH",
            displayName: "New Hampshire",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NJ",
            displayName: "New Jersey",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NM",
            displayName: "New Mexico",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NY",
            displayName: "New York",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NC",
            displayName: "North Carolina",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "ND",
            displayName: "North Dakota",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "NP",
            displayName: "Northern Mariana Islands",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "OH",
            displayName: "Ohio",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "OK",
            displayName: "Oklahoma",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "OR",
            displayName: "Oregon",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "PA",
            displayName: "Pennsylvania",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "PR",
            displayName: "Puerto Rico",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "RI",
            displayName: "Rhode Island",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "SC",
            displayName: "South Carolina",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "SD",
            displayName: "South Dakota",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "TN",
            displayName: "Tennessee",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "TX",
            displayName: "Texas",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "VI",
            displayName: "US Virgin Islands",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "UT",
            displayName: "Utah",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "VT",
            displayName: "Vermont",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "VA",
            displayName: "Virginia",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "WA",
            displayName: "Washington",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "WV",
            displayName: "West Virginia",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "WI",
            displayName: "Wisconsin",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "WY",
            displayName: "Wyoming",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "UM",
            displayName: "United States Minor Outlying Islands",
            key: "USA",
            __typename: "AttributeValue",
          },
          {
            code: "AB",
            displayName: "Alberta",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "BC",
            displayName: "British Columbia",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "MB",
            displayName: "Manitoba",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "NB",
            displayName: "New Brunswick",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "NF",
            displayName: "Newfoundland and Labrador",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "NT",
            displayName: "Northwest Territories",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "NS",
            displayName: "Nova Scotia",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "NU",
            displayName: "Nunavut",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "ON",
            displayName: "Ontario",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "PE",
            displayName: "Prince Edward Island",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "QC",
            displayName: "Quebec",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "SK",
            displayName: "Saskatchewan",
            key: "Canada",
            __typename: "AttributeValue",
          },
          {
            code: "YT",
            displayName: "Yukon",
            key: "Canada",
            __typename: "AttributeValue",
          },
        ],
        id: "2dfb8263-eb14-43e3-9e48-903e06dae326",
        mandatory: false,
        __typename: "AssetAttribute",
      },
      {
        name: "eligibility",
        registryCode: RegistryCode.Mrets,
        values: [
          {
            code: "ecologo",
            displayName: "Ecologo Certified",
            __typename: "AttributeValue",
          },
          {
            code: "ia",
            displayName: "Iowa",
            __typename: "AttributeValue",
          },
          {
            code: "ia_aep",
            displayName: "Iowa AEP",
            __typename: "AttributeValue",
          },
          {
            code: "ia_voluntary_green",
            displayName: "Iowa Voluntary Green",
            __typename: "AttributeValue",
          },
          {
            code: "il_ares",
            displayName: " Illinois ARES",
            __typename: "AttributeValue",
          },
          {
            code: "il_utility",
            displayName: "Illinois Utility",
            __typename: "AttributeValue",
          },
          {
            code: "mb",
            displayName: "Manitoba",
            __typename: "AttributeValue",
          },
          {
            code: "mn",
            displayName: "Minnesota",
            __typename: "AttributeValue",
          },
          {
            code: "mn_ses_small_solar",
            displayName: "Minnesota SES Small Solar",
            __typename: "AttributeValue",
          },
          {
            code: "mt",
            displayName: "Montana",
            __typename: "AttributeValue",
          },
          {
            code: "nd",
            displayName: "North Dakota",
            __typename: "AttributeValue",
          },
          {
            code: "oh",
            displayName: "Ohio",
            __typename: "AttributeValue",
          },
          {
            code: "sd",
            displayName: "South Dakota",
            __typename: "AttributeValue",
          },
          {
            code: "wi_bankable",
            displayName: "Wisconsin Bankable",
            __typename: "AttributeValue",
          },
          {
            code: "wi_non_bankable",
            displayName: "Wisconsin Non Bankable",
            __typename: "AttributeValue",
          },
        ],
        id: "6ca39e14-fd25-46a3-ae37-0c08fa6de25b",
        mandatory: false,
        __typename: "AssetAttribute",
      },
      {
        name: "vintage",
        registryCode: RegistryCode.Mrets,
        values: [
          {
            code: "2022",
            displayName: "2022",
            __typename: "AttributeValue",
          },
          {
            code: "2021",
            displayName: "2021",
            __typename: "AttributeValue",
          },
          {
            code: "2020",
            displayName: "2020",
            __typename: "AttributeValue",
          },
          {
            code: "2019",
            displayName: "2019",
            __typename: "AttributeValue",
          },
          {
            code: "2018",
            displayName: "2018",
            __typename: "AttributeValue",
          },
          {
            code: "2017",
            displayName: "2017",
            __typename: "AttributeValue",
          },
          {
            code: "2016",
            displayName: "2016",
            __typename: "AttributeValue",
          },
          {
            code: "2015",
            displayName: "2015",
            __typename: "AttributeValue",
          },
        ],
        id: "d590f97d-5317-4558-b148-7a1c327c660c",
        mandatory: true,
        __typename: "AssetAttribute",
      },
      {
        name: "fuelSource",
        registryCode: RegistryCode.Mrets,
        values: [
          {
            code: "ARS",
            displayName: "Air Source",
            __typename: "AttributeValue",
          },
          {
            code: "ALT",
            displayName: "Alternate Use",
            __typename: "AttributeValue",
          },
          {
            code: "BIG",
            displayName: "Biogas",
            __typename: "AttributeValue",
          },
          {
            code: "BIM",
            displayName: "Biomass",
            __typename: "AttributeValue",
          },
          {
            code: "BM2",
            displayName: "Biomass (WI Eligible)",
            __typename: "AttributeValue",
          },
          {
            code: "BAF",
            displayName: "Biomass - Alt fuels from plant oils or animal fats",
            __typename: "AttributeValue",
          },
          {
            code: "BLQ",
            displayName: "Biomass - Black Liquor",
            __typename: "AttributeValue",
          },
          {
            code: "RWB",
            displayName: "Biomass - Recovered Woody Biomass",
            __typename: "AttributeValue",
          },
          {
            code: "BSW",
            displayName: "Biomass - sustainably managed woody biomass",
            __typename: "AttributeValue",
          },
          {
            code: "AB1",
            displayName: "Biomass - Agricultural Crop (closed loop)",
            __typename: "AttributeValue",
          },
          {
            code: "AB2",
            displayName: "Biomass - Agricultural Crop (open loop)",
            __typename: "AttributeValue",
          },
          {
            code: "BAW",
            displayName: "Biomass - Agricultural Waste",
            __typename: "AttributeValue",
          },
          {
            code: "BA3",
            displayName: "Biomass - Animal Waste - Other",
            __typename: "AttributeValue",
          },
          {
            code: "BA2",
            displayName: "Biomass - Animal Waste - Poultry",
            __typename: "AttributeValue",
          },
          {
            code: "BA1",
            displayName: "Biomass - Animal Waste - Swine",
            __typename: "AttributeValue",
          },
          {
            code: "BBW",
            displayName: "Biomass - Biological Waste",
            __typename: "AttributeValue",
          },
          {
            code: "BHV",
            displayName: "Biomass - Herbaceous Vegetative Matter or Residue",
            __typename: "AttributeValue",
          },
          {
            code: "BID",
            displayName: "Biomass - Incineration of Construction Debris",
            __typename: "AttributeValue",
          },
          {
            code: "BGR",
            displayName: "Biomass - Incineration of Garbage",
            __typename: "AttributeValue",
          },
          {
            code: "BBL",
            displayName: "Biomass - Liquids",
            __typename: "AttributeValue",
          },
          {
            code: "BNV",
            displayName: "Biomass - Non vegetation waste",
            __typename: "AttributeValue",
          },
          {
            code: "PA1",
            displayName: "Biomass - Paper-derived",
            __typename: "AttributeValue",
          },
          {
            code: "RDF",
            displayName: "Biomass - Refuse-Derived Fuel",
            __typename: "AttributeValue",
          },
          {
            code: "SLW",
            displayName: "Biomass - Sludge Waste",
            __typename: "AttributeValue",
          },
          {
            code: "BTW",
            displayName: "Biomass - Treated Organic Waste Biomass",
            __typename: "AttributeValue",
          },
          {
            code: "BUW",
            displayName: "Biomass - Untreated Organic Waste Biomass",
            __typename: "AttributeValue",
          },
          {
            code: "WDR",
            displayName: "Biomass - Wood - Railroad Ties",
            __typename: "AttributeValue",
          },
          {
            code: "WDU",
            displayName: "Biomass - Wood - Utility Poles",
            __typename: "AttributeValue",
          },
          {
            code: "WDL",
            displayName: "Biomass - Wood - Wood Waste Liquids",
            __typename: "AttributeValue",
          },
          {
            code: "WDS",
            displayName: "Biomass - Wood - Wood/Wood Waste Solids",
            __typename: "AttributeValue",
          },
          {
            code: "CHE",
            displayName: "Compost Heat Exchange System",
            __typename: "AttributeValue",
          },
          {
            code: "CON",
            displayName: "Conversion of Fuel from Natural Gas Pipeline",
            __typename: "AttributeValue",
          },
          {
            code: "FC1",
            displayName: "Fuel Cells",
            __typename: "AttributeValue",
          },
          {
            code: "BFG",
            displayName: "Gas - Blast-Furnace Gas",
            __typename: "AttributeValue",
          },
          {
            code: "GE1",
            displayName: "Geothermal Energy",
            __typename: "AttributeValue",
          },
          {
            code: "GWS",
            displayName: "Ground And Water Source Head Pump",
            __typename: "AttributeValue",
          },
          {
            code: "HY2",
            displayName: "Hydro (WI Eligible)",
            __typename: "AttributeValue",
          },
          {
            code: "H2O",
            displayName: "Hydroelectric Water",
            __typename: "AttributeValue",
          },
          {
            code: "HYD",
            displayName: "Hydrogen",
            __typename: "AttributeValue",
          },
          {
            code: "MS1",
            displayName: "Municipal solid Waste",
            __typename: "AttributeValue",
          },
          {
            code: "RNG",
            displayName: "Renewable Natural Gas",
            __typename: "AttributeValue",
          },
          {
            code: "SO1",
            displayName: "Solar",
            __typename: "AttributeValue",
          },
          {
            code: "SOT",
            displayName: "Solar Thermal",
            __typename: "AttributeValue",
          },
          {
            code: "OC1",
            displayName: "Tidal or Wave",
            __typename: "AttributeValue",
          },
          {
            code: "TDF",
            displayName: "Tire Derived Fuel",
            __typename: "AttributeValue",
          },
          {
            code: "WHR",
            displayName: "Waste Heat Recovery",
            __typename: "AttributeValue",
          },
          {
            code: "WND",
            displayName: "Wind",
            __typename: "AttributeValue",
          },
          {
            code: "WD2",
            displayName: "Wind (WI Eligible)",
            __typename: "AttributeValue",
          },
          {
            code: "WBS",
            displayName: "Woody Biomass System",
            __typename: "AttributeValue",
          },
        ],
        id: "fcd96876-29cf-41a4-b968-f29530b68fec",
        mandatory: false,
        __typename: "AssetAttribute",
      },
      {
        name: "certification",
        registryCode: RegistryCode.Mrets,
        values: [
          {
            code: "CRS",
            displayName: "CRS Listed",
            __typename: "AttributeValue",
          },
        ],
        id: "cc166dce-a1d9-4776-9fd5-30b00ade7164",
        mandatory: false,
        __typename: "AssetAttribute",
      },
      {
        name: "project",
        registryCode: RegistryCode.Mrets,
        values: [
          {
            code: "Chipmunk-Solar",
            displayName: "Chipmunk Solar",
          },
          {
            code: "Holiday-Creek-Solar",
            displayName: "Holiday Creek Solar",
          },
          {
            code: "Viking-Wind",
            displayName: "Viking Wind",
          },
          {
            code: "Nobles-Wind",
            displayName: "Nobles Wind",
          },
        ],
        id: "57721abc-b0ea-43bb-b8a0-7cb8ac3616b2",
        mandatory: false,
      },
    ],
    displayName: "REC",
    code: "REC",
    id: "910b3752-f4b1-4713-b940-cf51dce65a9d",
    name: "REC",
    __typename: "Asset",
  },
};
