import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: number;
  Date: string;
  DateTime: string;
  JSON: any;
  LocalTime: any;
  Long: any;
  PositiveInt: number;
  UUID: any;
};

/** Input for accepting policies */
export type AcceptPoliciesInput = {
  /** Whether or not the user has accepted the privacy policy. */
  privacyPolicyAccepted?: InputMaybe<Scalars["Boolean"]>;
  /** Whether or not the user has accepted the rules. */
  rulesAccepted?: InputMaybe<Scalars["Boolean"]>;
  /** Whether or not the user has accepted the terms. */
  termsAccepted?: InputMaybe<Scalars["Boolean"]>;
};

export type AcceptPoliciesResponse = {
  __typename?: "AcceptPoliciesResponse";
  account?: Maybe<Account>;
};

/** The account entity representing a user in the system. */
export type Account = {
  __typename?: "Account";
  /** Indicates the latest audit trail whenever customer has acknowledges their profile details. */
  acknowledgementDate?: Maybe<Scalars["DateTime"]>;
  /** List of a bank an account holds */
  banks?: Maybe<Array<Bank>>;
  company?: Maybe<Company>;
  /** @deprecated Since TX-2511 this field support is dropped */
  contactNumber?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  endDateTime?: Maybe<Scalars["DateTime"]>;
  enrolledFactors: Array<EnrolledFactor>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Reference ID from third-party Identity Provider for the user account */
  identityProviderRef: Scalars["String"];
  lastName?: Maybe<Scalars["String"]>;
  linkedPaymentMethods: Array<LinkedPaymentMethod>;
  linkedRegistries: Array<LinkedRegistry>;
  /**
   * List of a Member holds by an account
   * @deprecated Since TX-4015 this is moved into linkedRegistries on a per registry basis
   */
  members?: Maybe<Array<Member>>;
  /** Represents the time and date that the NRM terms were accepted or re-accepted in UTC by NRM user (e.g., 2007-12-03T10:15:30+00:00) */
  nrmTermsAcceptedDate?: Maybe<Scalars["DateTime"]>;
  /** Represents the time and date that the privacy policy was accepted or re-accepted in UTC (e.g., 2007-12-03T10:15:30+00:00) */
  privacyPolicyAcceptedDate?: Maybe<Scalars["DateTime"]>;
  /**
   * Represents the time and date that the rules were accepted or re-accepted in UTC (e.g., 2007-12-03T10:15:30+00:00)
   * @deprecated Since TX-4015 this is moved into linkedRegistries on a per registry basis
   */
  rulesAcceptedDate?: Maybe<Scalars["DateTime"]>;
  startDateTime: Scalars["DateTime"];
  status: AccountStatus;
  tenantId?: Maybe<Scalars["UUID"]>;
  /** Represents the time and date that the terms were accepted or re-accepted in UTC (e.g., 2007-12-03T10:15:30+00:00) */
  termsAcceptedDate?: Maybe<Scalars["DateTime"]>;
  /** Type of account */
  type?: Maybe<AccountType>;
};

/** The account entity representing a user in the system. */
export type AccountMembersArgs = {
  where?: InputMaybe<MemberFilter>;
};

export type AccountDetailsInput = {
  /** To fulfill KYC requirement, customer has to acknowledge the details they have submitted in their user profile */
  acknowledgementAccepted?: InputMaybe<Scalars["Boolean"]>;
  contactNumber?: InputMaybe<Scalars["String"]>;
  /** First name of the owner of this trading account */
  firstName?: InputMaybe<Scalars["String"]>;
  /** Last name of the owner of this trading account */
  lastName?: InputMaybe<Scalars["String"]>;
  /**
   * Whether or not the NRM user has accepted the NRM terms.
   * @deprecated Since TX-4046 this is moved into linkRegistry on a per registry basis
   */
  nrmTermsAccepted?: InputMaybe<Scalars["Boolean"]>;
  /** Whether or not the user has accepted the privacy policy. */
  privacyPolicyAccepted?: InputMaybe<Scalars["Boolean"]>;
  /** Whether or not the user has accepted the TraceX rules. */
  rulesAccepted?: InputMaybe<Scalars["Boolean"]>;
  tenantUid?: InputMaybe<Scalars["UUID"]>;
  /** Whether or not the user has accepted the terms. */
  termsAccepted?: InputMaybe<Scalars["Boolean"]>;
  /**
   * Type of account that is onboarding
   * @deprecated Since TX-4046 this is moved into linkRegistry on a per registry basis
   */
  type?: InputMaybe<AccountType>;
};

/** Filter criteria for Account query */
export type AccountFilter = {
  /** Company filter for the associated account */
  company?: InputMaybe<CompanyFilter>;
  /** Id of the account */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Member filter for the associated account */
  member?: InputMaybe<MemberFilter>;
  /** Registry code */
  registryCode?: InputMaybe<RegistryCode>;
  /** Status of the account */
  status?: InputMaybe<AccountStatus>;
  /** type of account */
  type?: InputMaybe<AccountType>;
};

export type AccountRegistry = {
  __typename?: "AccountRegistry";
  /** Id off the account linked to the registry */
  accountId: Scalars["UUID"];
  /** Tyoe of account linked with the registry */
  accountType?: Maybe<AccountType>;
  /** Business name of the account linked to the registry */
  businessName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Registry code */
  registryCode: RegistryCode;
  /** Registry id */
  registryId: Scalars["UUID"];
  /** The registry org Id for the account. will only be set for REGISTRY accounts */
  registryOrgLinkId?: Maybe<Scalars["String"]>;
  /** Status of the linked registry */
  status: RegistryLinkStatus;
};

export type AccountRegistryFilter = {
  registryCode: RegistryCode;
  registryOrgIdLike?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<RegistryLinkStatus>;
};

export type AccountRegistryInput = {
  /** Pagination input */
  paginationInput: PaginationInput;
  /** Sort orders */
  sortOrderInputs?: InputMaybe<Array<SortOrderInput>>;
  /** Filter for account registry query */
  where: AccountRegistryFilter;
};

export type AccountRegistryResponse = {
  __typename?: "AccountRegistryResponse";
  /** Account registries */
  accountRegistries: Array<AccountRegistry>;
  /** Pagination info */
  offsetInfo: PageDetail;
};

/** Page info */
export type AccountServiceOffsetInfo = {
  __typename?: "AccountServiceOffsetInfo";
  /** Current offset limit */
  limit?: Maybe<Scalars["Int"]>;
  /** Current offset */
  offset?: Maybe<Scalars["Int"]>;
  /** Total offsets */
  total?: Maybe<Scalars["Int"]>;
};

/** Offset pagination input */
export type AccountServiceOffsetPaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** Sort order input */
export type AccountServiceSortOrderInput = {
  field: Scalars["String"];
  sortOrder: OrderByDirection;
};

/** The possible states of an account. */
export enum AccountStatus {
  /** Account is fully verified and enabled to trade. */
  Active = "ACTIVE",
  /** Account needs reverification. */
  AwaitingReverification = "AWAITING_REVERIFICATION",
  /** Account has submitted an application and is awaiting verification. */
  AwaitingVerification = "AWAITING_VERIFICATION",
  /** Account is still onboarding and has not yet submitted an application. */
  Onboarding = "ONBOARDING",
  Retry = "RETRY",
  RetryReviewing = "RETRY_REVIEWING",
}

export enum AccountType {
  /** This type of accounts are linked to powerledger's registry */
  NonRegistry = "NON_REGISTRY",
  /** This type of accounts have their own registry linked */
  Registry = "REGISTRY",
}

/** This enum denotes who supervised the export */
export enum ActionInitiator {
  /** Indicates that the action is initiated by super admin */
  InternalAdmin = "INTERNAL_ADMIN",
  /** Indicates that the action is initiated by respective account user */
  Self = "SELF",
}

export type ActivateFactor = {
  activateString: Scalars["String"];
  factor: Factor;
};

export type AddFundingSourceInput = {
  /** Code from a failed initialisation attempt when adding the funding source */
  errorCode?: InputMaybe<Scalars["String"]>;
  /** Human readable message from a failed initialisation attempt when adding the funding source */
  errorMessage?: InputMaybe<Scalars["String"]>;
  /** Identifier of the funding source. Likely to be a third party account number */
  identifier?: InputMaybe<Scalars["String"]>;
  /** Linked payment method code for which the funding source will be added */
  paymentMethodCode: PaymentMethodCode;
  /** Tokenized representation of the funding source */
  token?: InputMaybe<Scalars["String"]>;
};

export type AddFundingSourceResponse = {
  __typename?: "AddFundingSourceResponse";
  linkedPaymentMethod?: Maybe<LinkedPaymentMethod>;
};

export type AddProjectIdInput = {
  projectId: Scalars["String"];
};

export type AddProjectIdResponse = {
  __typename?: "AddProjectIdResponse";
  projectId: Scalars["String"];
};

/** Input for adding a trading account User */
export type AddTradingAccountUserInput = {
  /** Email address of the user to be added */
  email: Scalars["String"];
  /** Position of the user to be added */
  position?: InputMaybe<Scalars["String"]>;
  /** User type of the user to be added */
  tradingAccountUserType: TradingAccountUserType;
};

export type AddTradingAccountUserResponse = {
  __typename?: "AddTradingAccountUserResponse";
  tradingAccountUser?: Maybe<TradingAccountUser>;
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  line1?: InputMaybe<Scalars["String"]>;
  line2?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type AdminAccount = {
  __typename?: "AdminAccount";
  /** Email of an Admin */
  email: Scalars["String"];
  /** First Name of an Admin */
  firstName: Scalars["String"];
  id: Scalars["UUID"];
  /** Last Name of an Admin */
  lastName: Scalars["String"];
  /** Tenant Id of an admin */
  tenantId: Scalars["UUID"];
  /** Tenant Name of an admin (TraceCC or TraceX) */
  tenantName?: Maybe<Scalars["String"]>;
};

export type ApplicationHost = {
  __typename?: "ApplicationHost";
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["UUID"];
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["DateTime"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  tenantId: Scalars["UUID"];
  tenantName: Scalars["String"];
  urlPrefix?: Maybe<Scalars["String"]>;
};

export type ApplicationHostFilter = {
  applicationHostIds?: InputMaybe<Array<Scalars["UUID"]>>;
  keyword?: InputMaybe<Scalars["String"]>;
  tenantIds?: InputMaybe<Array<Scalars["UUID"]>>;
};

/** Mutation data to create Application Host */
export type ApplicationHostInput = {
  /** Physical location where application host is located */
  location?: InputMaybe<Scalars["String"]>;
  /** Application host name */
  name: Scalars["String"];
  /** Identifier for tenant that this application host is associated to */
  tenantId: Scalars["UUID"];
  /** Name of tenant that this application host is associated to */
  tenantName: Scalars["String"];
  /** URL prefix for the application host. Will be used to create url as: {protocol}{urlPrefix}.{domain}. */
  urlPrefix?: InputMaybe<Scalars["String"]>;
};

export type ApplicationHostsResult = {
  __typename?: "ApplicationHostsResult";
  applicationHosts: Array<ApplicationHost>;
  pageInfo?: Maybe<OffsetPaginationInfo>;
};

/** Indicates external KYC check state */
export type ApprovalState = {
  __typename?: "ApprovalState";
  /** Parseable error code for UI branching logic and translations */
  errorCode?: Maybe<Scalars["String"]>;
  /** Human readable error message to aid developers in debugging */
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** By default all the entities are in not requested state. */
  status: ApprovalStatus;
};

export enum ApprovalStatus {
  /** Payment provider has approved the details. */
  Approved = "APPROVED",
  /** Payment provider has deactivated customer. Check the ApprovalState -> errorMessage for more information. */
  Deactivated = "DEACTIVATED",
  /** Failed to submit details to paymentProvider. Check the ApprovalState -> errorMessage for more information. */
  Error = "ERROR",
  /** Initial State when payment provider has not yet requested */
  NotRequested = "NOT_REQUESTED",
  /** Under payment provider's verification process. */
  Pending = "PENDING",
  /** Payment provider has rejected the details. Check the ApprovalState -> errorMessage for more information. */
  Rejected = "REJECTED",
  /** Payment provider has deactivated customer. Check the ApprovalState -> errorMessage for more information. */
  Suspended = "SUSPENDED",
}

export type Asset = {
  __typename?: "Asset";
  attributes?: Maybe<Array<AssetAttribute>>;
  code: Scalars["String"];
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
};

/** Input for assigning assets to trade contract */
export type AssetAssignmentInput = {
  assetType: AssetType;
  /** assets to assign to the trade contract */
  assetsToAssign: Array<AssetSpecificationInput>;
  registryCode: RegistryCode;
  /** id of the trade contract to assign assets to */
  tradeContractId: Scalars["UUID"];
};

export type AssetAttribute = {
  __typename?: "AssetAttribute";
  id: Scalars["UUID"];
  mandatory: Scalars["Boolean"];
  name: Scalars["String"];
  registryCode: RegistryCode;
  values?: Maybe<Array<AttributeValue>>;
};

export type AssetAttributeInput = {
  mandatory: Scalars["Boolean"];
  name: Scalars["String"];
  values?: InputMaybe<Array<AttributeValueInput>>;
};

/** Union of all possible attributes for each class of asset. */
export type AssetAttributes = CcAssetAttributes | RecAssetAttributes;

export type AssetFilter = {
  codeIn?: InputMaybe<Array<Scalars["String"]>>;
  idIn?: InputMaybe<Array<Scalars["UUID"]>>;
  tenantIdIn?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum AssetGenerationType {
  Biomass = "BIOMASS",
  Grid = "GRID",
  Hydro = "HYDRO",
  Nuclear = "NUCLEAR",
  SolarPv = "SOLAR_PV",
  Unknown = "UNKNOWN",
  Wind = "WIND",
}

/** Specification of volume and attributes of assets */
export type AssetSpecificationInput = {
  /** REC asset attributes */
  recAttributes?: InputMaybe<RecAttributeInput>;
  /** volume of the assets to assign */
  volume: Scalars["Int"];
};

export enum AssetSubType {
  Battery = "BATTERY",
  Household = "HOUSEHOLD",
  Inverter = "INVERTER",
  Load = "LOAD",
}

export enum AssetType {
  Cc = "CC",
  Rec = "REC",
}

export type AttributeValue = {
  __typename?: "AttributeValue";
  code: Scalars["String"];
  displayName?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
};

export type AttributeValueInput = {
  code: Scalars["String"];
  displayName?: InputMaybe<Scalars["String"]>;
  key?: InputMaybe<Scalars["String"]>;
};

export type AuthorizedUserInput = {
  /** Authorized Contact Number of a Member */
  contactNumber: Scalars["String"];
  /** Authorized Email of a Member */
  email: Scalars["String"];
};

export type Bank = {
  __typename?: "Bank";
  /** Bank account number */
  accountNumber: Scalars["String"];
  /** Bank Code */
  bankCode?: Maybe<Scalars["String"]>;
  bankName: Scalars["String"];
  /** Physical Address of a bank */
  branchAddress: Scalars["String"];
  id: Scalars["UUID"];
  /** An Indian Financial System Code */
  ifsCode?: Maybe<Scalars["String"]>;
  /** Swift Code */
  swiftCode?: Maybe<Scalars["String"]>;
  /** Specifies a bank type */
  type: BankType;
};

/** Supported bank account types which can be used as a funding source. */
export enum BankAccountType {
  /** Checking account */
  Checking = "CHECKING",
  /** Savings account */
  Savings = "SAVINGS",
}

/** Tokenized bank account being used as a funding source. */
export type BankAccountV2 = FundingSource & {
  __typename?: "BankAccountV2";
  /** Account name of the bank account */
  accountName?: Maybe<Scalars["String"]>;
  /** Masked account number of the bank account. Shows as the last four digits i.e., 1234 */
  accountNumberMasked?: Maybe<Scalars["String"]>;
  /** The bank account type. This can only be checking or savings in most countries */
  accountType?: Maybe<BankAccountType>;
  /** Metadata associated to the funding source. This is typically sensitive account information from a third-party provider */
  associatedMetadata?: Maybe<Scalars["JSON"]>;
  /** Currency code of the bank account */
  currencyCode?: Maybe<CurrencyCode>;
  /** Parseable error code for UI branching logic and translations */
  errorCode?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Status of the bank account */
  status: FundingSourceStatus;
  /** Human readable status message to aid developers in debugging */
  statusMessage?: Maybe<Scalars["String"]>;
};

export type BankInput = {
  /** Bank account number */
  accountNumber: Scalars["String"];
  /** Bank Code for certain Bank */
  bankCode?: InputMaybe<Scalars["String"]>;
  bankName: Scalars["String"];
  /** Physical Branch Address of a bank */
  branchAddress: Scalars["String"];
  /** Bank Unique Id */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Indian Financial System Code */
  ifsCode?: InputMaybe<Scalars["String"]>;
  /** Swift (Society for Worldwide Interbank Financial Telecommunication) Code */
  swiftCode?: InputMaybe<Scalars["String"]>;
  /** Specifies a type of a Bank */
  type: BankType;
};

export enum BankType {
  /** Bank account used to load the funds */
  Fund = "FUND",
  /** Bank account used for settlements */
  Settlement = "SETTLEMENT",
}

export type BillingAccount = {
  __typename?: "BillingAccount";
  accountDisplayName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  address?: Maybe<BillingAccountAddress>;
  applicationHostName?: Maybe<Scalars["String"]>;
  billingInfo?: Maybe<BillingAccountInfo>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  tenantName?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  tradingGroupName?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["UUID"]>;
};

export type BillingAccountAddress = {
  __typename?: "BillingAccountAddress";
  addressLine?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
};

export type BillingAccountAddressInput = {
  addressLine?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
};

export type BillingAccountInfo = {
  __typename?: "BillingAccountInfo";
  dateOfFirstBill?: Maybe<Scalars["DateTime"]>;
  invoiceInterval?: Maybe<InvoiceInterval>;
  lastInvoiceDateTime?: Maybe<Scalars["DateTime"]>;
  manualInvoice?: Maybe<Scalars["Boolean"]>;
  nextInvoiceDateTime?: Maybe<Scalars["DateTime"]>;
};

export type BillingAccountInfoInput = {
  dateOfFirstBill?: InputMaybe<Scalars["DateTime"]>;
  invoiceInterval?: InputMaybe<InvoiceInterval>;
  lastInvoiceDateTime?: InputMaybe<Scalars["DateTime"]>;
  manualInvoice?: InputMaybe<Scalars["Boolean"]>;
  nextInvoiceDateTime?: InputMaybe<Scalars["DateTime"]>;
};

export type BillingAccountInput = {
  accountDisplayName?: InputMaybe<Scalars["String"]>;
  accountNumber?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<BillingAccountAddressInput>;
  applicationHostName?: InputMaybe<Scalars["String"]>;
  billingInfo?: InputMaybe<BillingAccountInfoInput>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  ownershipDateRange?: InputMaybe<OwnershipDateRangeInput>;
  tenantName?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

export type BillingAccountOwnership = {
  __typename?: "BillingAccountOwnership";
  accountNumber?: Maybe<Scalars["String"]>;
  billingAccount?: Maybe<BillingAccount>;
  tradingGroupName?: Maybe<Scalars["String"]>;
};

export type BillingAccountRef = {
  __typename?: "BillingAccountRef";
  accountNumber?: Maybe<Scalars["String"]>;
  tradingGroupName?: Maybe<Scalars["String"]>;
};

export type BusinessClassification = {
  __typename?: "BusinessClassification";
  id: Scalars["UUID"];
  industryClassifications: Array<IndustryClassification>;
  name: Scalars["String"];
};

/** Attributes relevant to a Carbon Credit asset class. */
export type CcAssetAttributes = {
  __typename?: "CCAssetAttributes";
  /** Code of the client that the carbon credit belongs to */
  clientCode?: Maybe<Scalars["String"]>;
  /** Co Benefits that is acquired from given Carbon Credit */
  coBenefits?: Maybe<Array<Scalars["String"]>>;
  /** Country for the Carbon Credit */
  country: Scalars["String"];
  /** Id of the project Carbon Credit is associated with */
  projectId: Scalars["String"];
  /** Registry webpage for the carbon credit */
  projectLink: Scalars["String"];
  /** Name of the project Carbon Credit is associated with */
  projectName: Scalars["String"];
  /** Types of the project */
  projectTypes?: Maybe<Array<Scalars["String"]>>;
  /** User's account name for the registry */
  registryAccountName: Scalars["String"];
  /** User's account number for the registry */
  registryAccountNumber: Scalars["String"];
  /** Name of the registry Carbon Credit is associated with */
  registryName: Scalars["String"];
  /** SDG goals of the specific Carbon Credit */
  sdgGoals?: Maybe<Array<Scalars["String"]>>;
  /** Sectorial Scopes that this specific Carbon Credit possess */
  sectoralScopes?: Maybe<Array<Scalars["String"]>>;
  /** Unique serial number of the carbon credit */
  serialNumber: Scalars["String"];
  /** Year on which carbon credit was produced */
  vintage: Scalars["Int"];
  /** Starting date of the vintage */
  vintageFrom?: Maybe<Scalars["String"]>;
  /** End date of the vintage */
  vintageTo?: Maybe<Scalars["String"]>;
};

export type CcHoldingFilter = {
  accountId?: InputMaybe<Scalars["UUID"]>;
  clientCodes?: InputMaybe<Array<Scalars["String"]>>;
  coBenefits?: InputMaybe<Array<Scalars["String"]>>;
  countries?: InputMaybe<Array<Scalars["String"]>>;
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  projectId?: InputMaybe<Scalars["String"]>;
  registries?: InputMaybe<Array<Scalars["String"]>>;
  vintages?: InputMaybe<Array<Scalars["Int"]>>;
};

export type CcOrderAttributeFilter = {
  coBenefits?: InputMaybe<Array<Scalars["String"]>>;
  country?: InputMaybe<Array<Scalars["String"]>>;
  projectId?: InputMaybe<Scalars["String"]>;
  projectTypes?: InputMaybe<Array<Scalars["String"]>>;
  registryName?: InputMaybe<Array<Scalars["String"]>>;
  sdgGoals?: InputMaybe<Array<Scalars["String"]>>;
  sectoralScopes?: InputMaybe<Array<Scalars["String"]>>;
  vintage?: InputMaybe<Scalars["Int"]>;
};

export type CcOrderAttributeInput = {
  clientCode?: InputMaybe<Scalars["String"]>;
  coBenefits?: InputMaybe<Array<Scalars["String"]>>;
  country?: InputMaybe<Array<Scalars["String"]>>;
  projectId?: InputMaybe<Scalars["String"]>;
  projectLink?: InputMaybe<Scalars["String"]>;
  projectName?: InputMaybe<Scalars["String"]>;
  projectTypes?: InputMaybe<Array<Scalars["String"]>>;
  registryAccountName?: InputMaybe<Scalars["String"]>;
  registryAccountNumber?: InputMaybe<Scalars["String"]>;
  registryName?: InputMaybe<Scalars["String"]>;
  sdgGoals?: InputMaybe<Array<Scalars["String"]>>;
  sectoralScopes?: InputMaybe<Array<Scalars["String"]>>;
  serialNumber?: InputMaybe<Scalars["String"]>;
  vintage?: InputMaybe<Scalars["Int"]>;
  vintageFrom?: InputMaybe<Scalars["String"]>;
  vintageTo?: InputMaybe<Scalars["String"]>;
};

export type CcOrderAttributes = {
  __typename?: "CCOrderAttributes";
  clientCode?: Maybe<Scalars["String"]>;
  coBenefits?: Maybe<Array<Scalars["String"]>>;
  country?: Maybe<Array<Scalars["String"]>>;
  projectId?: Maybe<Scalars["String"]>;
  projectLink?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
  projectTypes?: Maybe<Array<Scalars["String"]>>;
  registryAccountName?: Maybe<Scalars["String"]>;
  registryAccountNumber?: Maybe<Scalars["String"]>;
  registryName?: Maybe<Scalars["String"]>;
  sdgGoals?: Maybe<Array<Scalars["String"]>>;
  sectoralScopes?: Maybe<Array<Scalars["String"]>>;
  serialNumber?: Maybe<Scalars["String"]>;
  vintage?: Maybe<Scalars["Int"]>;
  vintageFrom?: Maybe<Scalars["String"]>;
  vintageTo?: Maybe<Scalars["String"]>;
};

/** The entity representing carbon credit  in the system */
export type CarbonCredit = {
  __typename?: "CarbonCredit";
  /** Account details of the user owning carbon credit (federated) */
  account: Account;
  /** Code of the client that the carbon credit belongs to */
  clientCode?: Maybe<Scalars["String"]>;
  coBenefits?: Maybe<Array<Scalars["String"]>>;
  /** Country for the Carbon Credit */
  country: Scalars["String"];
  /** Id of the carbon credit */
  id: Scalars["UUID"];
  /** Name of the project Carbon Credit is associated with */
  projectId: Scalars["String"];
  /** Registry webpage for the carbon credit */
  projectLink: Scalars["String"];
  /** Name of the project Carbon Credit is associated with */
  projectName: Scalars["String"];
  /** Total amount of Carbon Credit imported */
  projectTypes?: Maybe<Array<Scalars["String"]>>;
  /** User's account name for the registry */
  registryAccountName: Scalars["String"];
  /** User's account number for the registry */
  registryAccountNumber: Scalars["String"];
  /** Name of the registry Carbon Credit is associated with */
  registryName: Scalars["String"];
  sdgGoals?: Maybe<Array<Scalars["String"]>>;
  sectoralScopes?: Maybe<Array<Scalars["String"]>>;
  /** Unique serial number of the carbon credit */
  serialNumber: Scalars["String"];
  /** Tenant id of the user owning carbon credit */
  tenantId: Scalars["UUID"];
  /** Year on which carbon credit was produced */
  vintage: Scalars["Int"];
  vintageFrom?: Maybe<Scalars["String"]>;
  vintageTo?: Maybe<Scalars["String"]>;
  /** Total amount of Carbon Credit imported */
  volume: Scalars["PositiveInt"];
};

/** The entity representing carbon credit audit in the system */
export type CarbonCreditAudit = {
  __typename?: "CarbonCreditAudit";
  /** Carbon credit details */
  carbonCredit: CarbonCredit;
  /** Id of the audit */
  id: Scalars["UUID"];
  /** Remarks of the audit */
  remarks?: Maybe<Array<Scalars["String"]>>;
  /** Status of the audit */
  status: CarbonCreditAuditStatus;
};

/** Status of the carbon credit audit */
export enum CarbonCreditAuditStatus {
  /** The imported carbon credit is accepted by the admin */
  Accepted = "ACCEPTED",
  /** The imported carbon credit is sill awaiting review from Admin */
  Pending = "PENDING",
  /** The imported carbon credit is rejected by the admin */
  Rejected = "REJECTED",
}

export type CarbonCreditFilter = {
  coBenefits?: InputMaybe<Array<Scalars["String"]>>;
  countries?: InputMaybe<Array<Scalars["String"]>>;
  id?: InputMaybe<Scalars["UUID"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  registries?: InputMaybe<Array<Scalars["String"]>>;
  status?: InputMaybe<Array<CarbonCreditAuditStatus>>;
  vintages?: InputMaybe<Array<Scalars["Int"]>>;
};

export type CarbonCreditInfo = {
  __typename?: "CarbonCreditInfo";
  clientCode?: Maybe<Scalars["String"]>;
  coBenefits?: Maybe<Array<Scalars["String"]>>;
  country: Scalars["String"];
  projectId: Scalars["String"];
  projectLink: Scalars["String"];
  projectName: Scalars["String"];
  projectTypes?: Maybe<Array<Scalars["String"]>>;
  registryAccountName: Scalars["String"];
  registryAccountNumber: Scalars["String"];
  registryName: RegistryName;
  sdgGoals?: Maybe<Array<Scalars["String"]>>;
  sectoralScopes?: Maybe<Array<Scalars["String"]>>;
  serialNumber: Scalars["String"];
  vintage: Scalars["PositiveInt"];
  vintageFrom?: Maybe<Scalars["String"]>;
  vintageTo?: Maybe<Scalars["String"]>;
};

/** Carbon credit details */
export type CarbonCreditInput = {
  /** Code of the client that the carbon credit belongs to */
  clientCode?: InputMaybe<Scalars["String"]>;
  coBenefits?: InputMaybe<Array<Scalars["String"]>>;
  /** Country for the Carbon Credit */
  country: Scalars["String"];
  /** Id of the project Carbon Credit is associated with */
  projectId: Scalars["String"];
  /** Registry webpage for the carbon credit */
  projectLink: Scalars["String"];
  /** Name of the project Carbon Credit is associated with */
  projectName: Scalars["String"];
  /** Type of the project */
  projectTypes?: InputMaybe<Array<Scalars["String"]>>;
  /** User's account name for the registry */
  registryAccountName: Scalars["String"];
  /** User's account number for the registry */
  registryAccountNumber: Scalars["String"];
  /** Name of the registry Carbon Credit is associated with */
  registryName: Scalars["String"];
  sdgGoals?: InputMaybe<Array<Scalars["String"]>>;
  sectoralScopes?: InputMaybe<Array<Scalars["String"]>>;
  /** Unique serial number of the carbon credit */
  serialNumber: Scalars["String"];
  /** Year on which carbon credit was produced */
  vintage: Scalars["Int"];
  vintageFrom?: InputMaybe<Scalars["String"]>;
  vintageTo?: InputMaybe<Scalars["String"]>;
  /** Total amount of Carbon Credit imported */
  volume: Scalars["Int"];
};

/** Clearing Record attributes */
export type ClearingRecord = {
  __typename?: "ClearingRecord";
  /** Buyer's client code. */
  buyerClientCode: Scalars["String"];
  /** Buyer Fee applied on matched buy order */
  buyerFees: Money;
  /** GST of Buyer Fee */
  buyerFeesGst: Money;
  /** Buyer's member code. */
  buyerMemberCode: Scalars["String"];
  /** Total amount spent by the buyer (inclusive of GST and fees) */
  buyerTotalAmount: Money;
  /** Info of matched carbon credit. */
  carbonCreditInfo: CarbonCreditInfo;
  /** Date and time when Clearing Record is created. */
  createdAt: Scalars["DateTime"];
  /** Clearing Record's id. */
  id: Scalars["UUID"];
  /** The type of matched order for clearing. */
  orderType?: Maybe<OrderType>;
  /** Reference code for Clearing Record. This is generated for each Clearing Record as an identifier. */
  referenceCode: Scalars["String"];
  /** Seller's client code. */
  sellerClientCode: Scalars["String"];
  /** Seller Fee applied on matched sell order */
  sellerFees: Money;
  /** GST of Seller Fee */
  sellerFeesGst: Money;
  /** Seller's member code. */
  sellerMemberCode: Scalars["String"];
  /** Total amount earned by the seller (inclusive of GST and fees) */
  sellerTotalAmount: Money;
  /** Current status of Clearing Record. */
  status: ClearingRecordStatus;
  /** Tax applied to the matched order. */
  taxAmount: Money;
  /** The amount traded between buyer and seller (exclusive of GST and fees) */
  tradeAmount: Money;
  /** Unit price of the matched order. */
  unitPrice: Money;
  /** Number of CC that are matched. */
  volume: Scalars["PositiveInt"];
};

/** All possible Clearing Record status */
export enum ClearingRecordStatus {
  /** Cancellation process has started. */
  CancellationPending = "CANCELLATION_PENDING",
  /** The clearing process is cancelled. */
  Cancelled = "CANCELLED",
  /** The transfer of commodities has failed. */
  CommodityTransferFailure = "COMMODITY_TRANSFER_FAILURE",
  /** The transfer of commodities is in progress and is being handled by the ICX registry adapter. */
  CommodityTransferPending = "COMMODITY_TRANSFER_PENDING",
  /** The transfer of commodities has succeeded. */
  CommodityTransferSuccess = "COMMODITY_TRANSFER_SUCCESS",
  /** The clearing process is completed. */
  Completed = "COMPLETED",
  /** Process to complete the clearing has started. */
  CompletionPending = "COMPLETION_PENDING",
  /** Clearing Record is created */
  New = "NEW",
  /** The pay-in process has failed. */
  PayInFailure = "PAY_IN_FAILURE",
  /** The pay-in has been submitted to bank and is now waiting for a response from the bank. */
  PayInPending = "PAY_IN_PENDING",
  /** The pay-in is waiting to be added to the next bank file transfer. */
  PayInReady = "PAY_IN_READY",
  /** The pay-in process has succeeded. */
  PayInSuccess = "PAY_IN_SUCCESS",
  /** The pay-out process has failed. */
  PayOutFailure = "PAY_OUT_FAILURE",
  /** The pay-out is in progress and is being handled by the ICX payment adapter. */
  PayOutPending = "PAY_OUT_PENDING",
  /** The pay-out is waiting to be added to the next bank file transfer. */
  PayOutReady = "PAY_OUT_READY",
  /** The pay-out process has succeeded. */
  PayOutSuccess = "PAY_OUT_SUCCESS",
  /** Notification sent to seller to generate invoice and is waiting for seller to email and provide the invoice to ICX */
  SellerInvoicePending = "SELLER_INVOICE_PENDING",
  /** Seller has emailed invoice to ICX and ICX admin has confirmed this through the admin portal. */
  SellerInvoiceReceived = "SELLER_INVOICE_RECEIVED",
}

/** Filter to fetch a single ClearingRecord */
export type ClearingRecordsFilterInput = {
  /** Clearing Record's id */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Clearing Record's reference code */
  referenceCode?: InputMaybe<Scalars["String"]>;
  /** Clearing Record's status to include */
  statusIn?: InputMaybe<Array<ClearingRecordStatus>>;
  /** Clearing Record's status to exclude */
  statusNotIn?: InputMaybe<Array<ClearingRecordStatus>>;
};

export type ClearingRecordsResponse = {
  __typename?: "ClearingRecordsResponse";
  clearingRecords: Array<ClearingRecord>;
};

export type ClientInput = {
  /** Account Id to put client under */
  accountId: Scalars["UUID"];
  /** Input for Client details */
  client: MemberInput;
};

export type Command = {
  __typename?: "Command";
  applicationHostId?: Maybe<Scalars["UUID"]>;
  commandEnd: Scalars["DateTime"];
  commandStart: Scalars["DateTime"];
  id: Scalars["UUID"];
  meterId: Scalars["UUID"];
  operation?: Maybe<Operation>;
  power: Scalars["BigDecimal"];
  settlementId: Scalars["UUID"];
  status: CommandStatus;
  tenantId?: Maybe<Scalars["UUID"]>;
  transactionId: Scalars["UUID"];
  type: CommandType;
  vendor: Scalars["String"];
  vendorDispatchId?: Maybe<Scalars["String"]>;
};

export type CommandFilter = {
  applicationHostId: Scalars["UUID"];
  date?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  meterId: Scalars["UUID"];
  status?: InputMaybe<CommandStatus>;
  tenantId: Scalars["UUID"];
  transactionId: Scalars["UUID"];
};

export enum CommandStatus {
  Created = "CREATED",
  ExecutedFailure = "EXECUTED_FAILURE",
  ExecutedSuccess = "EXECUTED_SUCCESS",
}

export enum CommandType {
  Created = "CREATED",
  Updated = "UPDATED",
}

/** Commodity Action could be retirement or export */
export type CommodityActionRequest = {
  /** accountId of the commodity holder */
  accountId: Scalars["UUID"];
  /** Asset type */
  assetType: AssetType;
  /** Completed date */
  completedOn?: Maybe<Scalars["DateTime"]>;
  /** Id of action request */
  id: Scalars["UUID"];
  /** Initiator of action request */
  initiatedBy: ActionInitiator;
  /** Requested date */
  requestedOn: Scalars["DateTime"];
  /** Status message in case of failure */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Tenant Id of the action entity */
  tenantId: Scalars["UUID"];
  /** Summation of volume of each sub requests (attributes set / commodities) */
  totalVolume: Scalars["Long"];
};

/** Commodity Action Request details */
export type CommodityActionRequestDetail = {
  /** Attributes of requested commodity */
  attributes: AssetAttributes;
  /** Transaction details for each attribute set */
  commodityTransactions?: Maybe<Array<CommodityTransaction>>;
  /** Commodity Action Request Detail id */
  id: Scalars["UUID"];
  /** Status message in case of failure */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Volume requested for retirement */
  volume: Scalars["Long"];
};

/** Representation group of vintage date and volumes for each vintage date for a particular transaction */
export type CommodityGenerationSummary = {
  __typename?: "CommodityGenerationSummary";
  /** Vintage Date */
  date: Scalars["Date"];
  id: Scalars["UUID"];
  /** Transferred RECs volume  */
  volume: Scalars["Long"];
};

export type CommodityGroup = {
  __typename?: "CommodityGroup";
  /** Account information (federated) */
  account: Account;
  /** Commodity attributes */
  attributes: AssetAttributes;
  /** Volume available for trade */
  availableVolume?: Maybe<Scalars["Long"]>;
  /** Total volume */
  currentVolume?: Maybe<Scalars["Long"]>;
  /** Vintage date of the commodity */
  generatedOn: Scalars["DateTime"];
  /** country of the generator */
  generatorCountry: Scalars["String"];
  /** Generator Description */
  generatorDesc: Scalars["String"];
  /** Generator Name */
  generatorName: Scalars["String"];
  /** Commodity group id */
  id: Scalars["UUID"];
  /** Currently locked volume */
  lockedVolume?: Maybe<Scalars["Long"]>;
  /** Start serial index  */
  serialFrom: Scalars["Long"];
  /** Serial prefix of the commodity */
  serialPrefix: Scalars["String"];
  /** End serial index */
  serialTo: Scalars["Long"];
  /** Tenant id */
  tenantId: Scalars["UUID"];
};

export type CommoditySerial = {
  __typename?: "CommoditySerial";
  /** Start serial index */
  serialFrom: Scalars["Long"];
  /** Serial Prefix */
  serialPrefix: Scalars["String"];
  /** End serial index */
  serialTo: Scalars["Long"];
};

export type CommodityTransaction = {
  __typename?: "CommodityTransaction";
  /** Serial ranges of commodity transaction */
  commoditySerial: CommoditySerial;
  /** Correlation Id matches to the parent id */
  correlationId: Scalars["UUID"];
  /** Generator description for this commodity transaction */
  generator: Generator;
  /** Transaction id */
  id: Scalars["UUID"];
  /** Transaction status */
  status: CommodityTransactionStatus;
  /** Status message in case of failure */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Volume covered by the transaction */
  volume: Scalars["Long"];
};

export enum CommodityTransactionStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Success = "SUCCESS",
}

export type Company = {
  __typename?: "Company";
  /** Indicates whether this company has passed external KYC checks */
  approvalState?: Maybe<ApprovalState>;
  businessName?: Maybe<Scalars["String"]>;
  businessType?: Maybe<Scalars["String"]>;
  /** Contains a list of company contacts, eg., controller, beneficial owner. */
  contacts?: Maybe<Array<CompanyContact>>;
  documents?: Maybe<Array<Document>>;
  employerIdentificationNumber?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  industryClassification?: Maybe<IndustryClassification>;
  physicalAddress?: Maybe<Address>;
};

export type CompanyContact = {
  __typename?: "CompanyContact";
  /** Indicates whether this contact has passed external KYC checks */
  approvalState?: Maybe<ApprovalState>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  documents?: Maybe<Array<Document>>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Document ID e.g. Driver license number, SSN */
  identityNumber?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  passportCountryCode?: Maybe<Scalars["String"]>;
  passportNumber?: Maybe<Scalars["String"]>;
  physicalAddress?: Maybe<Address>;
  type?: Maybe<ContactType>;
};

export type CompanyContactInput = {
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  /** First name of the company contact */
  firstName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  identityNumber?: InputMaybe<Scalars["String"]>;
  /** Job title of the company contact (e.g., Software Developer) */
  jobTitle?: InputMaybe<Scalars["String"]>;
  /** Last name of the company contact */
  lastName?: InputMaybe<Scalars["String"]>;
  passport?: InputMaybe<PassportInput>;
  physicalAddress?: InputMaybe<AddressInput>;
  /** The type of company contact, eg., controller, beneficial owner. */
  type?: InputMaybe<ContactType>;
};

/** Company filter for the associated account */
export type CompanyFilter = {
  /** Company business name for the account */
  businessName?: InputMaybe<Scalars["String"]>;
};

export type CompanyInput = {
  /** Name of the business or company as registered (e.g., ABC Books & Coffee) */
  businessName?: InputMaybe<Scalars["String"]>;
  businessType?: InputMaybe<Scalars["String"]>;
  controller?: InputMaybe<CompanyContactInput>;
  employerIdentificationNumber?: InputMaybe<Scalars["String"]>;
  industryClassificationUid?: InputMaybe<Scalars["UUID"]>;
  physicalAddress?: InputMaybe<AddressInput>;
};

/** Input for completing a retirement transaction */
export type CompleteRetirementTransactionInput = {
  /** Id of commodity transaction to be completed */
  commodityTransactionId: Scalars["UUID"];
};

export type CompleteRetirementTransactionResult = {
  __typename?: "CompleteRetirementTransactionResult";
  /** Id of the transaction which was completed */
  transactionId: Scalars["UUID"];
};

/** The possible contact types for a company. */
export enum ContactType {
  BeneficialOwner = "BENEFICIAL_OWNER",
  Controller = "CONTROLLER",
}

export enum CounterOrderDirection {
  /** Countered orders owned by self */
  Incoming = "INCOMING",
  /** Countered orders owned by counter party */
  Outgoing = "OUTGOING",
}

/** This is input for querying countered orders */
export type CounteredOrdersInput = {
  /** Specifies if the orders belongs to self or counter party */
  direction: CounterOrderDirection;
  /** Pagination input */
  paginationInput: PaginationInput;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<SortOrderInput>>;
};

/** Response for counter offer orders */
export type CounteredOrdersResponse = {
  __typename?: "CounteredOrdersResponse";
  /** Pagination info */
  offsetInfo: PageDetail;
  /** Orders that have received counter offers */
  orders: Array<Order>;
};

/** Input that provides the detail to create an ADMIN of a system */
export type CreateAdminAccountInput = {
  /** Email of an ADMIN */
  email: Scalars["String"];
  /** First Name of an ADMIN */
  firstName: Scalars["String"];
  /** Last Name of an ADMIN */
  lastName: Scalars["String"];
};

/** Response to return after the ADMIN is created. */
export type CreateAdminAccountResponse = {
  __typename?: "CreateAdminAccountResponse";
  adminAccount: AdminAccount;
};

export type CreateNewTenantInput = {
  features?: InputMaybe<Array<TenantFeature>>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

/** Input for creating an offer against an order */
export type CreateOfferInput = {
  /** Account placing the offer */
  fromAccountId: Scalars["UUID"];
  /** Populated if the offer is against an order */
  offerNumber?: InputMaybe<Scalars["String"]>;
  /** The order number to place the offer against */
  orderNumber: Scalars["String"];
  /** position of the offer i.e. BID/ASK */
  position: OfferPosition;
  /** Account receiving the offer */
  toAccountId: Scalars["UUID"];
  /** unitPrice proposed for offer */
  unitPrice: Scalars["Long"];
  /** volume proposed for offer */
  volume: Scalars["Long"];
};

export type CreatePaymentInput = {
  /** Amount to be transferred */
  amount: MoneyInput;
  /** Correlation ID for the payment. For settlements, this would be a settlement ID that all related payments, including fees, is tied to. */
  correlationId?: InputMaybe<Scalars["String"]>;
  /** Type of payment provider */
  providerType?: InputMaybe<PaymentProviderType>;
  /**
   * Source trading account ID that will send the payment amount.
   * Rebate payments don't need to specify this ID, as it will automatically debit the assigned master account.
   */
  sourceAccountId?: InputMaybe<Scalars["UUID"]>;
  /**
   * Target trading account ID that will receive the payment amount.
   * Fee payments don't need to specify this ID, as it will automatically credit the assigned master account.
   */
  targetAccountId?: InputMaybe<Scalars["UUID"]>;
  /** Type of the payment */
  type: PaymentType;
};

export type CreatePaymentResponse = {
  __typename?: "CreatePaymentResponse";
  payment: Payment;
};

/** Input for creating a trade contract */
export type CreateTradeContractInput = {
  /** REC attribute of a Trade contract */
  recAttributeInput?: InputMaybe<RecAttributeInput>;
  /** Trade contract details */
  tradeContractInput: TradeContractInput;
};

export type CreateUserError = {
  __typename?: "CreateUserError";
  message: Scalars["String"];
  username: Scalars["String"];
};

/**
 * The three-letter currency codes that represent supported world currencies.
 * These include standard ISO 4217 codes, legacy codes, and non-standard codes.
 */
export enum CurrencyCode {
  /** Indian Rupees */
  Inr = "INR",
  /** United States Dollars (USD). */
  Usd = "USD",
}

/** Details required for currency conversion */
export type CurrencyConversionRate = {
  __typename?: "CurrencyConversionRate";
  /** The conversion rate for the currency conversion */
  conversionRate: Scalars["String"];
  /** The source currency for the conversion */
  sourceCurrencyCode: CurrencyCode;
  /** The target currency for the conversion */
  targetCurrencyCode: CurrencyCode;
};

/** Input for a currency conversion query. */
export type CurrencyConversionRateFilter = {
  /** The source currency for the conversion */
  sourceCurrencyCode: CurrencyCode;
  /** The target currency for the conversion */
  targetCurrencyCode: CurrencyCode;
};

export type DateRange = {
  __typename?: "DateRange";
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

/** Date range */
export type DateRangeFilter = {
  fromDate: Scalars["Date"];
  toDate: Scalars["Date"];
};

export enum DefaultEnergyUnit {
  GWh = "GWh",
  MWh = "MWh",
  Wh = "Wh",
  KWh = "kWh",
}

/** Details to dispatch commodityGroup(s) by volume */
export type DispatchByVolumeDetailInput = {
  /** holding id of the requested dispatch */
  holdingId: Scalars["UUID"];
  /** volume requested for the dispatch */
  volume: Scalars["Long"];
};

/** Represents information about an uploaded document. */
export type Document = {
  __typename?: "Document";
  /** Indicates whether this document has passed external KYC checks */
  approvalState?: Maybe<ApprovalState>;
  /** Further information intended for the developer to diagnose issues with document uploads */
  errorMessage?: Maybe<Scalars["String"]>;
  /** The name of the file */
  fileName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Media type of the document (e.g., image/png) */
  mediaType?: Maybe<Scalars["String"]>;
  /** The object reference name that the storage provider refers to (e.g., GCS) */
  objectName?: Maybe<Scalars["String"]>;
  /**
   * Signed URL of the document which is typically valid for 10 minutes. Used for downloading the document.
   * If the document content is sensitive in nature, it will be encrypted
   */
  signedUrl?: Maybe<Scalars["String"]>;
  /** Size of the document in bytes */
  size?: Maybe<Scalars["Int"]>;
  /** Upload status of the document */
  status?: Maybe<DocumentStatus>;
  /** Type of the document (e.g., ID, LICENSE) */
  type: DocumentType;
};

export type DocumentInputV2 = {
  fileName: Scalars["String"];
  id: Scalars["UUID"];
  mediaType: Scalars["String"];
  size: Scalars["PositiveInt"];
  type: DocumentType;
};

export type DocumentLocation = {
  __typename?: "DocumentLocation";
  /** Document ID */
  id: Scalars["UUID"];
  path?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum DocumentStatus {
  PendingUpload = "PENDING_UPLOAD",
  Uploaded = "UPLOADED",
  UploadFailed = "UPLOAD_FAILED",
}

export enum DocumentType {
  IdCard = "ID_CARD",
  License = "LICENSE",
  Other = "OTHER",
  Passport = "PASSPORT",
}

export type EditCcAccountInput = {
  /** Account id whose member to edit */
  accountId: Scalars["UUID"];
  /** Input for editing bank details */
  banks: Array<BankInput>;
  /** Input for editing member details */
  members: MemberInput;
  /** Input for editing user details */
  user: AuthorizedUserInput;
};

/** Input for editing carbon credits */
export type EditCarbonCreditInput = {
  /** Carbon credit details */
  carbonCredit: CarbonCreditInput;
  /** Carbon credit audit id */
  carbonCreditAuditId: Scalars["UUID"];
};

export type EditCarbonCreditResponse = {
  __typename?: "EditCarbonCreditResponse";
  carbonCreditAudit: CarbonCreditAudit;
};

/** Input for Editing Trading Account User Type (i.e Roles) */
export type EditTradingAccountUserTypeInput = {
  /** Id of the Trading Account User whose Type is being edited. */
  tradingAccountUserId: Scalars["UUID"];
  /** User type to be assigned */
  userType: TradingAccountUserType;
};

export type EditTradingAccountUserTypeResponse = {
  __typename?: "EditTradingAccountUserTypeResponse";
  tradingAccountUser?: Maybe<TradingAccountUser>;
};

export enum ElectricityUom {
  Kwh = "KWH",
  Wh = "WH",
}

export type EmissionsIntensityTuple = {
  __typename?: "EmissionsIntensityTuple";
  key: Scalars["String"];
  value: Scalars["Float"];
};

export type EmissionsIntensityTupleMutationInput = {
  key: Scalars["String"];
  value: Scalars["Float"];
};

export type EnergyTransaction = {
  __typename?: "EnergyTransaction";
  amount: Scalars["BigDecimal"];
  amountExTax: Scalars["BigDecimal"];
  applicationHostId: Scalars["UUID"];
  blockchainTxId?: Maybe<Scalars["String"]>;
  co2Grid?: Maybe<Scalars["BigDecimal"]>;
  co2Saved?: Maybe<Scalars["BigDecimal"]>;
  counterPartyMeterId: Scalars["UUID"];
  counterPartyUserId: Scalars["UUID"];
  energyTraded: Scalars["BigDecimal"];
  generatorType: GeneratorType;
  gridRate?: Maybe<Scalars["BigDecimal"]>;
  id: Scalars["UUID"];
  isInitiator: Scalars["Boolean"];
  meterId: Scalars["UUID"];
  meterType: MeterType;
  rateId?: Maybe<Scalars["UUID"]>;
  ruleType: RuleType;
  settlementId: Scalars["UUID"];
  settlementPeriodIndex: Scalars["Int"];
  taxRate: Scalars["BigDecimal"];
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
  transactionCategory: TransactionCategory;
  transactionEndDateTime: Scalars["DateTime"];
  transactionStartDateTime: Scalars["DateTime"];
  transactionType: TransactionType;
  unitPrice: Scalars["BigDecimal"];
  userId: Scalars["UUID"];
};

export enum EnergyUnit {
  Gwh = "Gwh",
  Mwh = "Mwh",
  Wh = "Wh",
  KWh = "kWh",
  MW = "mW",
  UW = "uW",
}

export type EnrolledFactor = {
  __typename?: "EnrolledFactor";
  id: Scalars["String"];
  provider: FactorProvider;
  qrCodeHref?: Maybe<Scalars["String"]>;
  sharedSecret?: Maybe<Scalars["String"]>;
  status: FactorStatus;
  type: FactorType;
};

export enum EntityType {
  Company = "COMPANY",
  CompanyContact = "COMPANY_CONTACT",
  Document = "DOCUMENT",
}

/** The specific information regarding the commodityGroup(s) that the admin intends to export back to the MRETS platform. */
export type ExportCommoditiesBySerialInput = {
  assetType: AssetType;
  /** The commodityGroup to export */
  commodityGroupUid?: InputMaybe<Scalars["UUID"]>;
  registryCode: RegistryCode;
  /** The lowest value of the serial index to export from the commodityGroup(s) */
  serialIndexFrom: Scalars["Long"];
  /** The highest value of the serial index to export from the commodityGroup(s) */
  serialIndexTo: Scalars["Long"];
  /** The serial prefix of the serial number to export */
  serialPrefix: Scalars["String"];
  tradingAccountUid: Scalars["UUID"];
};

/** Result of request to export commodities by serial information */
export type ExportCommoditiesBySerialResult = {
  __typename?: "ExportCommoditiesBySerialResult";
  /** Indicates whether the export request was successful */
  status: Scalars["Boolean"];
};

/** This is the input to export the commodities by specifying volumes and holdings corresponding to respective commodities */
export type ExportCommoditiesByVolumeInput = {
  commodityDetails: Array<DispatchByVolumeDetailInput>;
  registryCode?: InputMaybe<RegistryCode>;
};

/** Represents one request for export of commodities */
export type ExportRequest = CommodityActionRequest & {
  __typename?: "ExportRequest";
  /** accountId of the commodity holder */
  accountId: Scalars["UUID"];
  /** Asset type */
  assetType: AssetType;
  /** Completed date */
  completedOn?: Maybe<Scalars["DateTime"]>;
  /** Unique export number assigned */
  exportNumber: Scalars["String"];
  /** Export request details */
  exportRequestDetails?: Maybe<Array<ExportRequestDetail>>;
  id: Scalars["UUID"];
  /** Initiator of export request */
  initiatedBy: ActionInitiator;
  /** Registry code */
  registryCode: RegistryCode;
  /** Requested date */
  requestedOn: Scalars["DateTime"];
  /** Status of the export request */
  status: ExportRequestStatus;
  /** Status message in case of failure */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Tenant of export entity */
  tenantId: Scalars["UUID"];
  /** Summation of volume of each sub requests (attributes set / commodities) */
  totalVolume: Scalars["Long"];
  /** Export Request Type (Serial based or Volume based) */
  type: ExportRequestType;
};

export type ExportRequestDetail = CommodityActionRequestDetail & {
  __typename?: "ExportRequestDetail";
  /** Attributes of requested commodity */
  attributes: AssetAttributes;
  /** Transaction details for each attribute set */
  commodityTransactions?: Maybe<Array<CommodityTransaction>>;
  /** Commodity Action Request Detail id */
  id: Scalars["UUID"];
  /** Status of related export request */
  status: ExportRequestStatus;
  /** Status message in case of failure */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Volume requested for retirement */
  volume: Scalars["Long"];
};

/** Export history filter */
export type ExportRequestFilter = {
  /** export requests for account */
  accountId?: InputMaybe<Scalars["UUID"]>;
  /** Export Number (like) */
  exportNumber?: InputMaybe<Scalars["String"]>;
  registryCode?: InputMaybe<RegistryCode>;
  /** Date range filter for requested date (between including endpoints) */
  requestedDateRange?: InputMaybe<DateRangeFilter>;
  /** Statuses of the export request (in) */
  statuses?: InputMaybe<Array<ExportRequestStatus>>;
};

/** This is input for export history query */
export type ExportRequestInput = {
  /** Pagination input */
  offSetPaginationInfo: RegistryServiceOffsetPaginationInput;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<RegistryServiceSortOrderInput>>;
  /** Filter for export request */
  where?: InputMaybe<ExportRequestFilter>;
};

/** Status of export request */
export enum ExportRequestStatus {
  /** Indicates that the export request is validated and commodity is in process of exporting */
  Exporting = "EXPORTING",
  /** Indicates failure of the export */
  Failed = "FAILED",
  /** Indicates completion of the partial request */
  PartialSuccess = "PARTIAL_SUCCESS",
  Pending = "PENDING",
  /** Indicates completion of the whole request */
  Success = "SUCCESS",
}

/** This enum denotes the type of Export Request made */
export enum ExportRequestType {
  /** Export is carried out on the basis of serial ranges */
  Serial = "SERIAL",
  /** Export is carried out on the basis of volume corresponding holding */
  Volume = "VOLUME",
}

export type ExportRequestsResponse = {
  __typename?: "ExportRequestsResponse";
  /** List of export requests */
  exportRequests: Array<ExportRequest>;
  /** Offset info */
  offsetInfo: RegistryServiceOffsetInfo;
};

export type Factor = {
  factorProvider?: InputMaybe<FactorProvider>;
  factorType: FactorType;
};

/** All supported MFA providers */
export enum FactorProvider {
  Google = "GOOGLE",
  Okta = "OKTA",
}

export enum FactorStatus {
  Active = "ACTIVE",
  Enrolled = "ENROLLED",
  Expired = "EXPIRED",
  Inactive = "INACTIVE",
  NotSetup = "NOT_SETUP",
  PendingActivation = "PENDING_ACTIVATION",
}

/** All supported MFA types */
export enum FactorType {
  TokenSoftwareTotp = "TOKEN_SOFTWARE_TOTP",
}

export enum FactorVerifiedStatus {
  Cancelled = "CANCELLED",
  Challenge = "CHALLENGE",
  Error = "ERROR",
  Failed = "FAILED",
  PasscodeReplayed = "PASSCODE_REPLAYED",
  Rejected = "REJECTED",
  Success = "SUCCESS",
  Timeout = "TIMEOUT",
  TimeWindowExceeded = "TIME_WINDOW_EXCEEDED",
  Waiting = "WAITING",
}

/** The base attributes of a funding source. */
export type FundingSource = {
  /** Metadata associated to the funding source. This is typically sensitive account information from a third-party provider */
  associatedMetadata?: Maybe<Scalars["JSON"]>;
  /** Currency code of this funding source */
  currencyCode?: Maybe<CurrencyCode>;
  /** Parseable error code for UI branching logic and translations */
  errorCode?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Status of this funding source */
  status: FundingSourceStatus;
  /** Human readable status message to aid developers in debugging */
  statusMessage?: Maybe<Scalars["String"]>;
};

/** The possible states of a linked payment method's funding source. */
export enum FundingSourceStatus {
  /** Funding source has been successfully added but is not yet verified */
  Added = "ADDED",
  /** Funding source has entered an error state. See the status message for more information */
  Error = "ERROR",
  /** Funding source has been removed and is no longer able to be used */
  Removed = "REMOVED",
  /** Funding source has been verified and can now be used in conjunction with a payment method */
  Verified = "VERIFIED",
  /** Funding source is undergoing a third party verification process */
  Verifying = "VERIFYING",
  /** Funding source requires customer action. Used for returning flows like micro-deposits or re-authentication */
  WaitingForCustomerAction = "WAITING_FOR_CUSTOMER_ACTION",
}

/** Generator associated with the project attribute of a Renewable Energy Certificate order */
export type Generator = {
  __typename?: "Generator";
  /** Date generator began producing renewable energy */
  commencementDate?: Maybe<Scalars["Date"]>;
  correlationId?: Maybe<Scalars["String"]>;
  /** Effective date of Crs listed facility */
  crsEffectiveDate?: Maybe<Scalars["Date"]>;
  /** Expiry date of Crs listed facility */
  crsExpiryDate?: Maybe<Scalars["Date"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Generator name */
  name: Scalars["String"];
};

export type GeneratorFilter = {
  idIn?: InputMaybe<Array<Scalars["UUID"]>>;
  postcode?: InputMaybe<Array<Scalars["String"]>>;
  registryId: Scalars["UUID"];
  state?: InputMaybe<Array<Scalars["String"]>>;
  suburb?: InputMaybe<Array<Scalars["String"]>>;
};

export enum GeneratorType {
  Battery = "BATTERY",
  Biomass = "BIOMASS",
  FossilFuel = "FOSSIL_FUEL",
  Grid = "GRID",
  Hydro = "HYDRO",
  Nuclear = "NUCLEAR",
  SolarPv = "SOLAR_PV",
  Unknown = "UNKNOWN",
  Wind = "WIND",
}

/**
 * A representation of a collection of commodities that have the same attributes held by a trading account.
 * To be used to show the 'inventory' of a trading account, i.e., what commodities the account holds.
 */
export type Holding = {
  __typename?: "Holding";
  /** The account ID associated with this holding */
  accountId?: Maybe<Scalars["UUID"]>;
  /** The asset attributes associated with the holding */
  attributes: AssetAttributes;
  /** The balance of the holding that is immediately available to be sold or transferred */
  availableBalance: Scalars["Int"];
  /** The total balance of the holding, inclusive of any pending transactions */
  currentBalance: Scalars["Int"];
  id: Scalars["UUID"];
  /** The project or generator associated with the holding */
  project: Scalars["String"];
};

export type HoldingFilter = {
  ids?: InputMaybe<Array<Scalars["UUID"]>>;
  recAttributes?: InputMaybe<RecAssetAttributeFilter>;
};

export type IcxAccountBalance = {
  __typename?: "IcxAccountBalance";
  /**
   * The maximum amount that is available for placing a bid for the account.
   * Basically this value is the difference between the bank balance and the
   * total amount of all bids.
   */
  availableBalance: Scalars["Long"];
  /**
   * The bank balance of an account in the lowest currency denomination,
   * e.g., for USD, it would be cents. For INR, it would be paisa.
   */
  bankBalance: Scalars["Long"];
  /** The id of an account */
  id: Scalars["UUID"];
  /**
   * The total amount of all bids in the lowest currency denomination
   * placed by the account
   */
  totalBidAmount: Scalars["Long"];
};

/** Input for importing carbon credits by admin for the given user */
export type ImportCarbonCreditByAdminInput = {
  /** Account of the user for whom carbon is being imported  */
  accountId: Scalars["UUID"];
  /** Carbon credit details */
  carbonCredit: CarbonCreditInput;
};

export type ImportCarbonCreditByAdminResponse = {
  __typename?: "ImportCarbonCreditByAdminResponse";
  carbonCreditAudit: CarbonCreditAudit;
};

/** Input for importing carbon credits */
export type ImportCarbonCreditInput = {
  /** Carbon credit details */
  carbonCredit: CarbonCreditInput;
};

export type ImportCarbonCreditResponse = {
  __typename?: "ImportCarbonCreditResponse";
  carbonCreditAudit: CarbonCreditAudit;
};

export type InboxMessage = {
  __typename?: "InboxMessage";
  body?: Maybe<Scalars["String"]>;
  dateCreated: Scalars["DateTime"];
  dateRead?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  recipient: MessageContact;
  sender?: Maybe<MessageContact>;
  status: MessageStatus;
  subject?: Maybe<Scalars["String"]>;
};

export type IndustryClassification = {
  __typename?: "IndustryClassification";
  businessClassificationName: Scalars["String"];
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export enum InvoiceInterval {
  Bimonthly = "BIMONTHLY",
  Fortnightly = "FORTNIGHTLY",
  HalfYearly = "HALF_YEARLY",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export type LinkPaymentMethodInput = {
  paymentMethodCode: PaymentMethodCode;
};

export type LinkPaymentMethodResponse = {
  __typename?: "LinkPaymentMethodResponse";
  linkedPaymentMethod?: Maybe<LinkedPaymentMethod>;
};

export type LinkRegistryInput = {
  accountType: AccountType;
  registryCode: RegistryCode;
  registryId: Scalars["UUID"];
  registryOrgLinkId?: InputMaybe<Scalars["String"]>;
  termsAndConditionsAccepted?: InputMaybe<Scalars["Boolean"]>;
};

/** A linked payment method on an account. */
export type LinkedPaymentMethod = {
  __typename?: "LinkedPaymentMethod";
  /** The funding source linked to this payment method */
  fundingSource?: Maybe<FundingSource>;
  id: Scalars["UUID"];
  /** Token used to initialise modals in the UI for the linked payment method */
  initialisingToken?: Maybe<Scalars["String"]>;
  /** The payment method that this link refers to */
  paymentMethod: PaymentMethod;
  /** Status of this payment method link */
  status: PaymentMethodLinkStatus;
  /** Human readable status message to aid developers in debugging */
  statusMessage?: Maybe<Scalars["String"]>;
};

export type LinkedRegistry = {
  __typename?: "LinkedRegistry";
  accountType?: Maybe<AccountType>;
  id: Scalars["UUID"];
  registryCode?: Maybe<RegistryCode>;
  registryId: Scalars["UUID"];
  registryOrgLinkId?: Maybe<Scalars["String"]>;
  status: RegistryLinkStatus;
};

export type Localisation = {
  __typename?: "Localisation";
  country: Scalars["String"];
  countryCode: Scalars["String"];
  currencyCode: Scalars["String"];
  currencyConversionRate: Scalars["PositiveInt"];
  currencySymbol: Scalars["String"];
  id: Scalars["UUID"];
  timeZone: Scalars["String"];
};

export type ManagedCommodityFilter = {
  /** Account Id */
  accountId?: InputMaybe<Scalars["UUID"]>;
  /** Asset type of the commodity */
  assetType: AssetType;
  /** Vintage date of the commodity */
  generatedOn?: InputMaybe<Scalars["String"]>;
  /** Serial prefix of the commodity */
  serialPrefix?: InputMaybe<Scalars["String"]>;
};

/** Input for paginated commodities */
export type ManagedCommodityInput = {
  /** Pagination input */
  offSetPaginationInfo?: InputMaybe<RegistryServiceOffsetPaginationInput>;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<RegistryServiceSortOrderInput>>;
  /** Filter for managed commodities */
  where: ManagedCommodityFilter;
};

export type ManagedCommodityResponse = {
  __typename?: "ManagedCommodityResponse";
  /** List of Commodities */
  commodities: Array<CommodityGroup>;
  /** Offset info */
  offsetInfo: RegistryServiceOffsetInfo;
};

/** Market order data */
export type MarketOrderData = {
  __typename?: "MarketOrderData";
  /** Total ask orders */
  totalAsks: Scalars["Long"];
  /** Total bid orders */
  totalBids: Scalars["Long"];
};

/** Filter criteria for Market Order Query */
export type MarketOrderFilter = {
  assetType: AssetType;
  ccAttributes?: InputMaybe<CcOrderAttributeFilter>;
  idIn?: InputMaybe<Array<Scalars["UUID"]>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderFilterOrder>>>;
  position?: InputMaybe<OrderPosition>;
  recAttributes?: InputMaybe<RecOrderAttributeFilter>;
  unitPrice?: InputMaybe<PriceFilter>;
};

export type MarketOrderResponse = {
  __typename?: "MarketOrderResponse";
  /** Market order data */
  data: MarketOrderData;
  /** Offset info */
  offsetInfo: OrderBookOffsetInfo;
  /** List of orders */
  orders: Array<Order>;
};

/** Input for market orders */
export type MarketOrdersInput = {
  /** Pagination input */
  offSetPaginationInfo?: InputMaybe<OrderBookOffsetPaginationInput>;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<OrderBookSortOrderInput>>;
  /** Filter for market orders */
  where: MarketOrderFilter;
};

export type MatchedTradeContract = {
  __typename?: "MatchedTradeContract";
  /** Ask trade contract detail */
  askTradeContract: TradeContract;
  /** Bid trade contract detail */
  bidTradeContract: TradeContract;
  /** Buyer's fee for trade contract */
  buyerFee: Scalars["Long"];
  /** Date on which matched trade contract was created */
  createdDate: Scalars["DateTime"];
  id: Scalars["UUID"];
  /** User friendly reference number for the matched trade contract */
  matchedTradeContractNumber: Scalars["String"];
  /** Seller's fee for trade contract */
  sellerFee: Scalars["Long"];
  /** Current state of matched trade contract */
  status: MatchedTradeContractStatus;
  /** Message explaining status of the matched trade contract */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Settlement date for the matched trade contract */
  tradeSettlementDate: Scalars["Date"];
  /** Unit Price for the matched trade contract */
  unitPrice: Scalars["Long"];
  /** Volume of asset involved in trade contract */
  volume: Scalars["Long"];
};

/** Filter criteria for Matched Trade contract Query */
export type MatchedTradeContractFilter = {
  numberIn?: InputMaybe<Array<Scalars["String"]>>;
  status?: InputMaybe<MatchedTradeContractStatus>;
};

/** Input for matched trade contract  query */
export type MatchedTradeContractInput = {
  /** Pagination input */
  paginationInput: PaginationInput;
  /** Sort orders */
  sortOrderInputs?: InputMaybe<Array<SortOrderInput>>;
  /** Filter for matched trade contract query */
  where: MatchedTradeContractFilter;
};

export type MatchedTradeContractResponse = {
  __typename?: "MatchedTradeContractResponse";
  /** Matched trade Contracts */
  matchedTradeContracts: Array<MatchedTradeContract>;
  /** Pagination info */
  offsetInfo: PageDetail;
};

export enum MatchedTradeContractStatus {
  /** The contract is accepted by buyer/seller */
  Accepted = "ACCEPTED",
  /** The contract completed processing */
  Completed = "COMPLETED",
  /** The contract failed processing */
  Failed = "FAILED",
  /** The contract is being processed */
  Pending = "PENDING",
  /** The contract is being settled */
  PendingComplete = "PENDING_COMPLETE",
  /** The contract is accepted by buyer/seller */
  Rejected = "REJECTED",
}

export type Member = {
  __typename?: "Member";
  /** Full principle place of business of a Member */
  businessAddress: Address;
  /** Client Code, if a Member type is Client */
  clientCode?: Maybe<Scalars["String"]>;
  /** Address where GSTIN is registered */
  gstRegistrationAddress?: Maybe<Address>;
  /** Unique fifteen-digit Goods & Service Tax Identification Number of a Member */
  gstin?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Full Invoice Address of a Member  */
  invoiceAddress: Address;
  /** Contact Number used for Invoicing, Can be same or different from Authorized Contact Number */
  invoiceContactNumber: Scalars["String"];
  /** Email address used for Invoicing, Can be same or different from Authorized Email */
  invoiceEmail: Scalars["String"];
  /** Membership Id of a Member */
  membershipId: Scalars["String"];
  /** Type of a Member in TraceCC */
  membershipType: MembershipType;
  /** Member Name */
  name: Scalars["String"];
  /** Ten-digit unique alphanumeric Permanent Account Number issued by the Income Tax Department */
  panNumber?: Maybe<Scalars["String"]>;
  /** Full Registered address of a Member */
  registeredAddress: Address;
  /** Registries of a Member */
  registries: Array<MemberRegistry>;
  /** Member Status */
  status: MemberStatus;
  /** Ten-digit alpha-numeric Tax Deduction and Collection Account Number */
  tanNumber?: Maybe<Scalars["String"]>;
};

/** Member filter for the associated account */
export type MemberFilter = {
  /** Client Code of the member */
  clientCode?: InputMaybe<Scalars["String"]>;
  /** Id of the member */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Membership id of the member */
  membershipId?: InputMaybe<Scalars["String"]>;
  /** Membership types of the member */
  membershipTypes?: InputMaybe<Array<MembershipType>>;
  /** Member name */
  name?: InputMaybe<Scalars["String"]>;
};

export type MemberInput = {
  /** Full principle place of business of a Member */
  businessAddress: AddressInput;
  /** Client Code, if a Member trade for themselves or for their client */
  clientCode?: InputMaybe<Scalars["String"]>;
  /** Address where GSTIN is registered */
  gstRegistrationAddress?: InputMaybe<AddressInput>;
  /** Unique fifteen-digit Goods & Service Tax Identification Number of a Member */
  gstin?: InputMaybe<Scalars["String"]>;
  /** Member Unique Id */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Full Invoice Address of a Member  */
  invoiceAddress: AddressInput;
  /** Contact Number used for Invoicing, Can be same or different from Authorized Contact Number */
  invoiceContactNumber: Scalars["String"];
  /** Email address used for Invoicing, Can be same or different from Authorized Email */
  invoiceEmail: Scalars["String"];
  /** Membership Id of a Member */
  membershipId: Scalars["String"];
  /** Type of a Member */
  membershipType: MembershipType;
  /** Name of a member */
  name: Scalars["String"];
  /** Ten-digit unique alphanumeric Permanent Account Number issued by the Income Tax Department */
  panNumber?: InputMaybe<Scalars["String"]>;
  /** Full Registered address of a Member */
  registeredAddress: AddressInput;
  /** Registries of a Member */
  registries?: InputMaybe<Array<MemberRegistryInput>>;
  /** Member status */
  status?: InputMaybe<MemberStatus>;
  /** Ten-digit alpha-numeric Tax Deduction and Collection Account Number */
  tanNumber?: InputMaybe<Scalars["String"]>;
};

export type MemberRegistry = {
  __typename?: "MemberRegistry";
  /** Registry account Name of a User */
  accountName: Scalars["String"];
  /** Registry account Number */
  accountNumber: Scalars["String"];
  id: Scalars["UUID"];
  /** Registry Name */
  registryName: Scalars["String"];
  /** Member Registry Status */
  status: MemberRegistryStatus;
};

export type MemberRegistryInput = {
  /** Registry account Name */
  accountName: Scalars["String"];
  /** Registry account Number */
  accountNumber: Scalars["String"];
  /** Member Registry unique Id */
  id?: InputMaybe<Scalars["UUID"]>;
  registryName: Scalars["String"];
  /** Status of Member Registry */
  status?: InputMaybe<MemberRegistryStatus>;
};

export enum MemberRegistryStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum MemberStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum MembershipType {
  /** Member who trade with the help of TRADER */
  Client = "CLIENT",
  /** Members who trade for themselves */
  Proprietor = "PROPRIETOR",
  /** Members who trade on behalf of clients */
  Trader = "TRADER",
}

export type MessageContact = {
  __typename?: "MessageContact";
  contactId?: Maybe<Scalars["UUID"]>;
  contactName?: Maybe<Scalars["String"]>;
};

export type MessageFilters = {
  status?: InputMaybe<MessageStatus>;
};

export enum MessageStatus {
  Read = "READ",
  Unread = "UNREAD",
}

/** This is input for messages */
export type MessagesInput = {
  /** Filters to get the message */
  filters: MessageFilters;
  /** Pagination input */
  paginationInput: PaginationInput;
  /** Sort orders */
  sortOrderInputs?: InputMaybe<Array<SortOrderInput>>;
};

/** Response for messages query */
export type MessagesResponse = {
  __typename?: "MessagesResponse";
  /** Messages */
  messages: Array<InboxMessage>;
  /** Pagination info */
  offsetInfo: PageDetail;
};

export type Meter = {
  __typename?: "Meter";
  activeRanges?: Maybe<Array<Maybe<MeterActiveRange>>>;
  address?: Maybe<MeterAddress>;
  applicationHostId?: Maybe<Scalars["UUID"]>;
  applicationHostName?: Maybe<Scalars["String"]>;
  assetCapacityInWatts?: Maybe<Scalars["Int"]>;
  assetGenerationType?: Maybe<AssetGenerationType>;
  assetSubType?: Maybe<AssetSubType>;
  assetType?: Maybe<MeterAssetType>;
  description?: Maybe<Scalars["String"]>;
  energyUnit?: Maybe<EnergyUnit>;
  expectedReadingsIntervalInSecs?: Maybe<Scalars["Int"]>;
  isKeyMeter?: Maybe<Scalars["Boolean"]>;
  maxReadingValue?: Maybe<Scalars["String"]>;
  meterDisplayName?: Maybe<Scalars["String"]>;
  meterExternalRef?: Maybe<Scalars["String"]>;
  meterId?: Maybe<Scalars["UUID"]>;
  minReadingValue?: Maybe<Scalars["String"]>;
  ownerships?: Maybe<Array<Maybe<Ownership>>>;
  tenantId?: Maybe<Scalars["UUID"]>;
  tenantName?: Maybe<Scalars["String"]>;
  tradingGroupId?: Maybe<Scalars["UUID"]>;
  tradingGroupName?: Maybe<Scalars["String"]>;
};

export type MeterActiveRange = {
  __typename?: "MeterActiveRange";
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type MeterActiveRangeInput = {
  from?: InputMaybe<Scalars["DateTime"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type MeterAddress = {
  __typename?: "MeterAddress";
  addressLine?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
};

export type MeterAddressInput = {
  addressLine?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  suburb?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export enum MeterAssetType {
  Consumer = "CONSUMER",
  Grid = "GRID",
  Producer = "PRODUCER",
  Prosumer = "PROSUMER",
  ProsumerBess = "PROSUMER_BESS",
}

export type MeterInput = {
  activeRanges?: InputMaybe<Array<InputMaybe<MeterActiveRangeInput>>>;
  address?: InputMaybe<MeterAddressInput>;
  applicationHostId?: InputMaybe<Scalars["UUID"]>;
  applicationHostName?: InputMaybe<Scalars["String"]>;
  assetCapacityInWatts?: InputMaybe<Scalars["Int"]>;
  assetGenerationType?: InputMaybe<AssetGenerationType>;
  assetSubType?: InputMaybe<AssetSubType>;
  assetType?: InputMaybe<MeterAssetType>;
  description?: InputMaybe<Scalars["String"]>;
  energyUnit?: InputMaybe<EnergyUnit>;
  expectedReadingsIntervalInSecs?: InputMaybe<Scalars["Int"]>;
  isKeyMeter?: InputMaybe<Scalars["Boolean"]>;
  maxReadingValue?: InputMaybe<Scalars["String"]>;
  meterDisplayName?: InputMaybe<Scalars["String"]>;
  meterExternalRef?: InputMaybe<Scalars["String"]>;
  meterId: Scalars["UUID"];
  minReadingValue?: InputMaybe<Scalars["String"]>;
  ownerships?: InputMaybe<Array<InputMaybe<OwnershipInput>>>;
  tenantId?: InputMaybe<Scalars["UUID"]>;
  tenantName?: InputMaybe<Scalars["String"]>;
  tradingGroupId?: InputMaybe<Scalars["UUID"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
};

export type MeterRef = {
  __typename?: "MeterRef";
  applicationHostId?: Maybe<Scalars["UUID"]>;
  applicationHostName?: Maybe<Scalars["String"]>;
  meterId?: Maybe<Scalars["UUID"]>;
  tenantId?: Maybe<Scalars["UUID"]>;
  tenantName?: Maybe<Scalars["String"]>;
  tradingGroupId?: Maybe<Scalars["UUID"]>;
  tradingGroupName?: Maybe<Scalars["String"]>;
};

export enum MeterType {
  Bess = "BESS",
  Grid = "GRID",
  Inverter = "INVERTER",
  Load = "LOAD",
  Master = "MASTER",
}

/** A monetary value with currency. */
export type Money = {
  __typename?: "Money";
  /** The total amount of money, represented as the lowest denomination for that currency code (e.g., cents or yen) */
  amount: Scalars["String"];
  /** The three-letter currency code for this amount */
  currencyCode: CurrencyCode;
};

export type MoneyInput = {
  /** The total amount of money, represented as the lowest denomination for that currency code (e.g., cents or yen) */
  amount: Scalars["String"];
  /** The three-letter currency code for this amount */
  currencyCode: CurrencyCode;
};

export type Mutation = {
  __typename?: "Mutation";
  /**
   * Marks the TraceX terms, conditions and other policies as accepted for an individual user.
   * If the accepting user is additional on an already active trading account, they will be activated for trade.
   */
  acceptPolicies: AcceptPoliciesResponse;
  activateFactor: FactorStatus;
  /** Mutation to add Client under Account */
  addClient: Account;
  addCompanyContact: CompanyContact;
  /** Adds a funding source to a linked payment method. */
  addFundingSource?: Maybe<AddFundingSourceResponse>;
  /** Add an amount, in the lowest currency denomination, to top up the bank balance of an account. */
  addIcxAccountBalance: IcxAccountBalance;
  /** Add project Id to the project table */
  addProjectId: AddProjectIdResponse;
  /** Adds a new trading account user to the trading account */
  addTradingAccountUser: AddTradingAccountUserResponse;
  /** Assign assets to trade contract */
  assignAssetsToTradeContract?: Maybe<Array<TradeContractAsset>>;
  /** Begin uploads for internal KYC document. */
  beginDocumentUploadV2?: Maybe<DocumentLocation>;
  cancelOrder?: Maybe<Order>;
  /** Cancel an existing trading halt for a given tenant */
  cancelTradingHalt: TradingHalt;
  changePassword?: Maybe<Scalars["String"]>;
  completeRetirementTransaction: CompleteRetirementTransactionResult;
  /** Mutation to create admin on the System */
  createAdminAccount: CreateAdminAccountResponse;
  createApplicationHost: ApplicationHost;
  createBillingAccount?: Maybe<BillingAccountRef>;
  createCCOrder: Order;
  /** Creates Instant Buy Order for provided sellOrderId */
  createInstantCCBuyOrder: Order;
  createMeter?: Maybe<MeterRef>;
  createNewTenant: TenantV2;
  /** Creates OTC Order with provided input */
  createOTCOrder: OtcOrderResponse;
  /** Mutation to create offers against an order/offer */
  createOffer: Offer;
  /** Creates a new payment. Typically used for handling one-off rebate payments or refunds. */
  createPayment?: Maybe<CreatePaymentResponse>;
  createRecOrder?: Maybe<Order>;
  createSettlement?: Maybe<Settlement>;
  createTenant?: Maybe<Tenant>;
  /** Mutation to create trade contract */
  createTradeContract: TradeContract;
  createTradingGroup: TradingGroup;
  /** Create a new trading halt for a given tenant */
  createTradingHalt: TradingHalt;
  createUpdateTariffTemplate?: Maybe<Scalars["Boolean"]>;
  createUpdateUserPrice?: Maybe<Scalars["Boolean"]>;
  createUser: User;
  createUsers: UserResult;
  deactivateTradingGroup?: Maybe<Scalars["Boolean"]>;
  deactivateUser?: Maybe<Scalars["Boolean"]>;
  deleteCompanyContact: Scalars["UUID"];
  deleteTariffTemplate?: Maybe<Scalars["Boolean"]>;
  /** Mutation to edit account and member details by admin */
  editCCAccountDetails: Account;
  /** Edit rejected carbon credit */
  editCarbonCredit: EditCarbonCreditResponse;
  /** Mutation to edit Client under Account */
  editClientDetails: Account;
  /** Edits the Trading Account User Type, i.e Promoting to Admin and Revoking Admin Access */
  editTradingAccountUserType: EditTradingAccountUserTypeResponse;
  editUserPassword: Scalars["String"];
  endDocumentUpload?: Maybe<Scalars["Boolean"]>;
  enrollFactor: EnrolledFactor;
  /** Mutation to export commodities by specifying the serial range. To be updated in https://pltasks.atlassian.net/browse/TX-3529 */
  exportCommoditiesBySerial: ExportCommoditiesBySerialResult;
  /** Mutation to export multiple commodities by specifying the respective holding and volume */
  exportCommoditiesByVolume: ExportRequest;
  generalResendUserActivationEmail?: Maybe<Scalars["Boolean"]>;
  /** Import a new carbon credit */
  importCarbonCredit: ImportCarbonCreditResponse;
  /** Import a new carbon credit by admin for the given user */
  importCarbonCreditByAdmin: ImportCarbonCreditByAdminResponse;
  /** Links a payment method to an account. Needs to be called prior to adding a funding source. */
  linkPaymentMethod?: Maybe<LinkPaymentMethodResponse>;
  linkRegistry: LinkedRegistry;
  markAsRead?: Maybe<InboxMessage>;
  /** Mutation to onboard a user by admin */
  onboardUserByAdmin: Account;
  onboardUserMeter?: Maybe<Scalars["Boolean"]>;
  reactivateTradingGroup?: Maybe<Scalars["Boolean"]>;
  /** Mutation to reject the application by admin */
  rejectApplication: RejectApplicationResponse;
  /** Reject entire retirement request */
  rejectRetirementRequest: RejectRetirementRequestResult;
  /** Mutation to remove the uploaded document of the customer */
  removeDocument: RemoveDocumentResponse;
  /** Removes the current funding source from a linked payment method. */
  removeFundingSource?: Maybe<RemoveFundingSourceResponse>;
  /** Removes the trading account user from the trading Account */
  removeTradingAccountUser: RemoveTradingAccountUserResponse;
  resendUserActivationEmail?: Maybe<Scalars["Boolean"]>;
  resetFactor: Scalars["Boolean"];
  resetPassword?: Maybe<Scalars["Boolean"]>;
  /** Mutation to resubmit the customer details by admin to payment prover */
  resubmitApplication: ResubmitApplicationResponse;
  /** Mutation to resubmit the application details by Admin */
  resubmitByAdmin: ResubmitByAdminResponse;
  retireCommodities: RetireCommoditiesResult;
  /** Review the imported the carbon credit by an admin */
  reviewCarbonCredit: ReviewCarbonCreditResponse;
  /** Mutation to review offers submitted to the user */
  reviewOffer: ReviewOfferResponse;
  /** Mutation to review trade contract */
  reviewTradeContract?: Maybe<TradeContract>;
  /** Sends an account activation link to the provided email. */
  sendActivationEmail?: Maybe<Scalars["Boolean"]>;
  /** Set the currency conversion rate based on the provided input. */
  setCurrencyConversionRate: CurrencyConversionRate;
  /**
   * Update and set a new bank balance in the lowest currency denomination of an account.
   * This will override the existing balance.
   */
  setIcxAccountBalance: IcxAccountBalance;
  /** Performs the final submit of all previously entered application data and marks the account as ready for administrator verification */
  submitApplication: Account;
  /** Unverifies and marks a user account as inactive for trading. */
  unverifyAccount: Account;
  updateAccountDetails: Account;
  /** Mutation to update approval state of verification entity */
  updateApprovalState: UpdateApprovalStateResponse;
  /** Update Clearing Record's status */
  updateClearingRecordStatus: UpdateClearingRecordStatusResponse;
  /** Updates an account's company details. Can only be used during on-boarding. */
  updateCompany?: Maybe<Company>;
  updateCompanyContact: CompanyContact;
  /** Mutation to update matched trade contract status by admin */
  updateMatchedTradeContractStatus: UpdateMatchedTradeContractResponse;
  updateMeter?: Maybe<MeterRef>;
  updateOnboardingStep: User;
  updateOwnAccount: User;
  updateTenant: TenantV2;
  updateTradingGroup: TradingGroup;
  /** Update an existing trading halt for a given tenant */
  updateTradingHalt: TradingHalt;
  updateUser: User;
  /** Verifies and marks a user account as ready for trading. */
  verifyAccount: Account;
  verifyFactor: FactorVerifiedStatus;
};

export type MutationAcceptPoliciesArgs = {
  input: AcceptPoliciesInput;
};

export type MutationActivateFactorArgs = {
  activate: ActivateFactor;
};

export type MutationAddClientArgs = {
  input: ClientInput;
};

export type MutationAddCompanyContactArgs = {
  companyContact: CompanyContactInput;
};

export type MutationAddFundingSourceArgs = {
  input: AddFundingSourceInput;
};

export type MutationAddIcxAccountBalanceArgs = {
  amount: Scalars["Long"];
  id: Scalars["UUID"];
};

export type MutationAddProjectIdArgs = {
  input: AddProjectIdInput;
};

export type MutationAddTradingAccountUserArgs = {
  input: AddTradingAccountUserInput;
};

export type MutationAssignAssetsToTradeContractArgs = {
  input?: InputMaybe<AssetAssignmentInput>;
};

export type MutationBeginDocumentUploadV2Args = {
  document: DocumentInputV2;
};

export type MutationCancelOrderArgs = {
  id: Scalars["UUID"];
};

export type MutationCancelTradingHaltArgs = {
  id: Scalars["UUID"];
};

export type MutationChangePasswordArgs = {
  passwords: PasswordsInput;
};

export type MutationCompleteRetirementTransactionArgs = {
  completeRetirementDetailInput: CompleteRetirementTransactionInput;
};

export type MutationCreateAdminAccountArgs = {
  input: CreateAdminAccountInput;
};

export type MutationCreateApplicationHostArgs = {
  applicationHost: ApplicationHostInput;
};

export type MutationCreateBillingAccountArgs = {
  billingAccount: BillingAccountInput;
};

export type MutationCreateCcOrderArgs = {
  attributes: CcOrderAttributeInput;
  order: OrderInput;
};

export type MutationCreateInstantCcBuyOrderArgs = {
  attributes: CcOrderAttributeInput;
  order: OrderInput;
  sellOrderId: Scalars["UUID"];
};

export type MutationCreateMeterArgs = {
  meter: MeterInput;
};

export type MutationCreateNewTenantArgs = {
  tenant: CreateNewTenantInput;
};

export type MutationCreateOtcOrderArgs = {
  order: OtcOrderInput;
};

export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};

export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};

export type MutationCreateRecOrderArgs = {
  attributes: RecOrderAttributeInput;
  order: OrderInput;
};

export type MutationCreateSettlementArgs = {
  settlement: SettlementInput;
};

export type MutationCreateTenantArgs = {
  tenant: TenantInput;
};

export type MutationCreateTradeContractArgs = {
  input: CreateTradeContractInput;
};

export type MutationCreateTradingGroupArgs = {
  tradingGroup: TradingGroupInput;
};

export type MutationCreateTradingHaltArgs = {
  tradingHalt: TradingHaltInput;
};

export type MutationCreateUpdateTariffTemplateArgs = {
  tariffTemplateInput: TariffTemplateInput;
};

export type MutationCreateUpdateUserPriceArgs = {
  userPrice: UserTariffInput;
};

export type MutationCreateUserArgs = {
  user: UserInput;
};

export type MutationCreateUsersArgs = {
  users: Array<UserInput>;
};

export type MutationDeactivateTradingGroupArgs = {
  applicationHostId: Scalars["UUID"];
  deactivateOn?: InputMaybe<Scalars["DateTime"]>;
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
};

export type MutationDeactivateUserArgs = {
  deactivateOn?: InputMaybe<Scalars["DateTime"]>;
  identityProviderRef: Scalars["String"];
};

export type MutationDeleteCompanyContactArgs = {
  id: Scalars["UUID"];
};

export type MutationDeleteTariffTemplateArgs = {
  applicationHostId: Scalars["UUID"];
  id: Scalars["UUID"];
  tenantId: Scalars["UUID"];
};

export type MutationEditCcAccountDetailsArgs = {
  input: EditCcAccountInput;
};

export type MutationEditCarbonCreditArgs = {
  input: EditCarbonCreditInput;
};

export type MutationEditClientDetailsArgs = {
  input: ClientInput;
};

export type MutationEditTradingAccountUserTypeArgs = {
  input: EditTradingAccountUserTypeInput;
};

export type MutationEditUserPasswordArgs = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationEndDocumentUploadArgs = {
  documentId: Scalars["UUID"];
};

export type MutationEnrollFactorArgs = {
  enroll: Factor;
};

export type MutationExportCommoditiesBySerialArgs = {
  exportCommoditiesBySerialInput: ExportCommoditiesBySerialInput;
};

export type MutationExportCommoditiesByVolumeArgs = {
  exportCommoditiesByVolumeInput: ExportCommoditiesByVolumeInput;
};

export type MutationGeneralResendUserActivationEmailArgs = {
  userId: Scalars["UUID"];
};

export type MutationImportCarbonCreditArgs = {
  input: ImportCarbonCreditInput;
};

export type MutationImportCarbonCreditByAdminArgs = {
  input: ImportCarbonCreditByAdminInput;
};

export type MutationLinkPaymentMethodArgs = {
  input: LinkPaymentMethodInput;
};

export type MutationLinkRegistryArgs = {
  registryLink: LinkRegistryInput;
};

export type MutationMarkAsReadArgs = {
  id: Scalars["String"];
};

export type MutationOnboardUserByAdminArgs = {
  input: OnboardUserByAdminInput;
};

export type MutationOnboardUserMeterArgs = {
  meterExternalRef: Scalars["String"];
  userId: Scalars["UUID"];
};

export type MutationReactivateTradingGroupArgs = {
  applicationHostId: Scalars["UUID"];
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
};

export type MutationRejectApplicationArgs = {
  input: RejectApplicationInput;
};

export type MutationRejectRetirementRequestArgs = {
  rejectRetirementRequestInput: RejectRetirementRequestInput;
};

export type MutationRemoveDocumentArgs = {
  input: RemoveDocumentInput;
};

export type MutationRemoveFundingSourceArgs = {
  input: RemoveFundingSourceInput;
};

export type MutationRemoveTradingAccountUserArgs = {
  input: RemoveTradingAccountUserInput;
};

export type MutationResendUserActivationEmailArgs = {
  userId: Scalars["UUID"];
};

export type MutationResetFactorArgs = {
  reset: Factor;
};

export type MutationResetPasswordArgs = {
  username: Scalars["String"];
};

export type MutationResubmitByAdminArgs = {
  input: ResubmitByAdminInput;
};

export type MutationRetireCommoditiesArgs = {
  retireCommoditiesInput: RetireCommoditiesInput;
};

export type MutationReviewCarbonCreditArgs = {
  input: ReviewCarbonCreditInput;
};

export type MutationReviewOfferArgs = {
  input: ReviewOfferInput;
};

export type MutationReviewTradeContractArgs = {
  input: ReviewTradeContractInput;
};

export type MutationSendActivationEmailArgs = {
  email: Scalars["String"];
};

export type MutationSetCurrencyConversionRateArgs = {
  input: SetCurrencyConversionRateInput;
};

export type MutationSetIcxAccountBalanceArgs = {
  bankBalance: Scalars["Long"];
  id: Scalars["UUID"];
};

export type MutationUnverifyAccountArgs = {
  accountId: Scalars["UUID"];
};

export type MutationUpdateAccountDetailsArgs = {
  accountDetails: AccountDetailsInput;
};

export type MutationUpdateApprovalStateArgs = {
  input: UpdateApprovalStateInput;
};

export type MutationUpdateClearingRecordStatusArgs = {
  input?: InputMaybe<UpdateClearingRecordStatusInput>;
};

export type MutationUpdateCompanyArgs = {
  company: CompanyInput;
};

export type MutationUpdateCompanyContactArgs = {
  companyContact: CompanyContactInput;
};

export type MutationUpdateMatchedTradeContractStatusArgs = {
  input: UpdateMatchedTradeContractInput;
};

export type MutationUpdateMeterArgs = {
  meter: MeterInput;
};

export type MutationUpdateOnboardingStepArgs = {
  step?: InputMaybe<Scalars["Int"]>;
};

export type MutationUpdateOwnAccountArgs = {
  user: UpdateOwnUserInput;
  userId: Scalars["UUID"];
};

export type MutationUpdateTenantArgs = {
  tenant: UpdateTenantInput;
};

export type MutationUpdateTradingGroupArgs = {
  applicationHostId: Scalars["UUID"];
  tenantId: Scalars["UUID"];
  tradingGroup: UpdateTradingGroupInput;
  tradingGroupId: Scalars["UUID"];
};

export type MutationUpdateTradingHaltArgs = {
  id: Scalars["UUID"];
  tradingHalt: TradingHaltInput;
};

export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
  userId: Scalars["UUID"];
};

export type MutationVerifyAccountArgs = {
  accountId: Scalars["UUID"];
};

export type MutationVerifyFactorArgs = {
  verify: VerifyFactor;
};

/**
 * DEPRECATED! Will be removed at a future release.
 *
 * Use TradingGroupComparisonSummary as the replacement.
 */
export type NetworkSummary = {
  __typename?: "NetworkSummary";
  /**
   * These 3 properties are not appropriately named and should be
   * consumerTotalAmountBAU (without LEM), consumerTotalAmount (with LEM),
   * and consumerAverageSavings. Similarly for the rest.
   */
  consumerReductionBAU: Scalars["BigDecimal"];
  consumerReductionBill: Scalars["BigDecimal"];
  consumerReductionLEM: Scalars["BigDecimal"];
  hourlyGridExportBAU?: Maybe<Array<Scalars["BigDecimal"]>>;
  hourlyGridExportLEM?: Maybe<Array<Scalars["BigDecimal"]>>;
  /**
   * These should not be hourly but instead should be according
   * to the settlement period.
   */
  hourlyGridImportBAU?: Maybe<Array<Scalars["BigDecimal"]>>;
  hourlyGridImportLEM?: Maybe<Array<Scalars["BigDecimal"]>>;
  prosumerBESSReductionBill: Scalars["BigDecimal"];
  prosumerBessReductionBAU: Scalars["BigDecimal"];
  prosumerBessReductionLEM: Scalars["BigDecimal"];
  prosumerReductionBAU: Scalars["BigDecimal"];
  prosumerReductionBill: Scalars["BigDecimal"];
  prosumerReductionLEM: Scalars["BigDecimal"];
  timeOfUseUsages?: Maybe<Array<TimeOfUseUsage>>;
  totalGridExportBAU: Scalars["BigDecimal"];
  totalGridExportLEM: Scalars["BigDecimal"];
  totalGridImportBAU: Scalars["BigDecimal"];
  totalGridImportLEM: Scalars["BigDecimal"];
};

export type NotificationConfig = {
  __typename?: "NotificationConfig";
  makeTheCompilerShutUp?: Maybe<Scalars["String"]>;
};

export type NotificationConfigMutationInput = {
  makeTheCompilerShutUp?: InputMaybe<Scalars["String"]>;
};

/** Inputs required for createing OTC specific order */
export type OtcOrderInput = {
  /** Attributes to create Buy Order */
  buyOrderAttributes: CcOrderAttributeInput;
  /** Buyer Account ID for the order */
  buyerAccountId: Scalars["UUID"];
  /** Order Expiry Date and Time for custom Order Expiry Type */
  expiryDate?: InputMaybe<Scalars["DateTime"]>;
  /** Order Expiry Type */
  orderExpiry?: InputMaybe<OrderExpiry>;
  /** Unit Price for the order */
  price: MoneyInput;
  /** Attributes to create Sell Order */
  sellOrderAttributes: CcOrderAttributeInput;
  /** Seller Account ID of the holdings */
  sellerAccountId: Scalars["UUID"];
  /** Volume of holdings */
  volume: Scalars["Int"];
};

/** Response for successful OTC mutation */
export type OtcOrderResponse = {
  __typename?: "OTCOrderResponse";
  /** Id for matched order */
  matchedOrderId: Scalars["UUID"];
};

/** Represents an offerEntity */
export type Offer = {
  __typename?: "Offer";
  /** Specifies when the offer was countered */
  counteredOn?: Maybe<Scalars["DateTime"]>;
  /** Timestamp of creation */
  createdOn: Scalars["DateTime"];
  /** Account that placed the offer */
  fromAccountId: Scalars["UUID"];
  /** Offer group the offer belongs to */
  offerGroupNumber: Scalars["String"];
  /** Unique identifier/reference code of the offer */
  offerNumber: Scalars["String"];
  /** Specifies when the offer was requested */
  requestedOn?: Maybe<Scalars["DateTime"]>;
  /** Current state of the offer */
  status: OfferStatus;
  /** Message explaining status of the offer */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Tenant of the entity */
  tenantId: Scalars["UUID"];
  /** Account that received the offer */
  toAccountId: Scalars["UUID"];
  /** Proposed unit price */
  unitPrice: Scalars["Long"];
  /** Proposed volume */
  volume: Scalars["Long"];
};

/** Action that can be taken by self or counter party on a REQUESTED offer */
export enum OfferAction {
  /** CounterParty -> Accept the offer and execute it */
  Accept = "ACCEPT",
  /** CounterParty -> Reject the offer */
  Reject = "REJECT",
  /** Self -> Withdraw the offer */
  Withdraw = "WITHDRAW",
}

/** Filter to query offer */
export type OfferFilter = {
  /** The offer group number the offer belongs to */
  offerGroupNumber: Scalars["String"];
};

/**
 * Represents a chain or 'group' of offers placed by a user. Initiated when a user first places
 * a counter against an order. Contains all the offers that are created back and forth between
 * the parties. Ends when the final offer reaches a terminal state
 */
export type OfferGroup = {
  __typename?: "OfferGroup";
  /** Account that placed the offer group */
  accountId: Scalars["UUID"];
  /** Timestamp of creation */
  createdOn: Scalars["DateTime"];
  /** Order issued that originated from the offer */
  issuedOrderNumber?: Maybe<Scalars["String"]>;
  /** Unique identifier and reference code for the offer group */
  offerGroupNumber: Scalars["String"];
  /** Order the offer was placed against */
  sourceOrderNumber: Scalars["String"];
  /** Status of the counter offer process for the offer Group */
  status: OfferGroupStatus;
  /** Tenant the offer group belongs to */
  tenantId: Scalars["UUID"];
};

/** Filter to query offerGroup */
export type OfferGroupFilter = {
  /** The order number the offer group belongs to */
  orderNumber: Scalars["String"];
};

export enum OfferGroupStatus {
  /** The final offer was accepted */
  Accepted = "ACCEPTED",
  /** The final offer was accepted and successfully matched with the order */
  Executed = "EXECUTED",
  /** The final offer was accepted, but failed during order execution */
  ExecutionFailed = "EXECUTION_FAILED",
  /** The final offer failed due to changes in the open market */
  Failed = "FAILED",
  /** Offers/counters are ongoing between the two parties */
  InProgress = "IN_PROGRESS",
  /** The final offer was rejected */
  Rejected = "REJECTED",
  /** The final offer was withdrawn */
  Withdrawn = "WITHDRAWN",
}

/** Represents the position of an offer in the order's context */
export enum OfferPosition {
  /** When placed against a buy order */
  Ask = "ASK",
  /** When placed against a sell order */
  Bid = "BID",
}

/** Represents states an offer can transition to */
export enum OfferStatus {
  /** The offer was accepted by the counter party and has been dispatched for execution */
  Accepted = "ACCEPTED",
  /** The offer was countered by the counter party resulting in creation of a new offer */
  Countered = "COUNTERED",
  /** The offer was accepted and successfully matched with the order */
  Executed = "EXECUTED",
  /** The offer was accepted, but failed during order execution */
  ExecutionFailed = "EXECUTION_FAILED",
  /**
   * The offer failed due to actions such as order match in the open market or the order withdrawal
   * i.e. it was not directly acted upon by self or the counter party to cause termination
   */
  Failed = "FAILED",
  /** The offer is being processed for request */
  Pending = "PENDING",
  /**
   * The offer was rejected either by the counter party or as a result of another offer being
   * accepted
   */
  Rejected = "REJECTED",
  /** The offer has been processed and has been sent to the counter party */
  Requested = "REQUESTED",
  /** The offer was withdrawn by the party who placed it */
  Withdrawn = "WITHDRAWN",
}

/** Extra Pagination information returned. */
export type OffsetPaginationInfo = {
  __typename?: "OffsetPaginationInfo";
  /** The total records that matches the criteria */
  count?: Maybe<Scalars["Int"]>;
  /** The amount of record that a page can have */
  limit?: Maybe<Scalars["Int"]>;
  /** The current page */
  page?: Maybe<Scalars["Int"]>;
  /** The total pages of the record that matches the filter */
  totalPages?: Maybe<Scalars["Int"]>;
};

export type OnboardUserByAdminInput = {
  /** Input of a Bank details of a Member */
  banks: Array<BankInput>;
  /** Input for Member details */
  members: MemberInput;
  /** Input for Authorized User Details */
  user: AuthorizedUserInput;
};

export enum Operation {
  Charge = "CHARGE",
  Discharge = "DISCHARGE",
}

/** Order placed in the open market */
export type Order = {
  __typename?: "Order";
  account?: Maybe<Account>;
  /** @deprecated use account.id */
  accountId: Scalars["UUID"];
  /** Whether this order allows counter offers */
  allowCounterOffers: Scalars["Boolean"];
  assetType: AssetType;
  attributes: OrderAttributes;
  /** Specifies whether the user fetching this order can place the counter offer */
  canCounter?: Maybe<Scalars["Boolean"]>;
  cancelledVolume?: Maybe<Scalars["Int"]>;
  completedOn?: Maybe<Scalars["DateTime"]>;
  createdOn?: Maybe<Scalars["DateTime"]>;
  currentVolume: Scalars["Int"];
  expiryDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["UUID"];
  initialVolume: Scalars["Int"];
  orderExpiry?: Maybe<OrderExpiry>;
  /** Unique identifier of the order */
  orderNumber: Scalars["String"];
  position: OrderPosition;
  price: Money;
  status: OrderStatus;
  statusMessage?: Maybe<Scalars["String"]>;
  tenantId: Scalars["UUID"];
  type: OrderType;
  updatedOn?: Maybe<Scalars["DateTime"]>;
};

export type OrderAttributes = CcOrderAttributes | RecOrderAttributes;

/** Page info */
export type OrderBookOffsetInfo = {
  __typename?: "OrderBookOffsetInfo";
  /** Current offset limit */
  limit?: Maybe<Scalars["Int"]>;
  /** Current offset */
  offset?: Maybe<Scalars["Int"]>;
  /** Total offsets */
  total?: Maybe<Scalars["Int"]>;
};

/** Offset pagination input */
export type OrderBookOffsetPaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** Sort order input */
export type OrderBookSortOrderInput = {
  field: Scalars["String"];
  sortOrder: OrderByDirection;
};

/** Specifies the direction of sorting in a query */
export enum OrderByDirection {
  /** Ascending Order */
  Asc = "ASC",
  /** Descending Order */
  Desc = "DESC",
}

export type OrderConnection = {
  __typename?: "OrderConnection";
  data: OrderConnectionData;
  edges?: Maybe<Array<OrderEdge>>;
  pageInfo: PaginationInfo;
};

export type OrderConnectionData = {
  __typename?: "OrderConnectionData";
  totalAssetCount: Scalars["Int"];
  totalOrderCount: Scalars["Int"];
};

export type OrderEdge = {
  __typename?: "OrderEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Order>;
};

export enum OrderExpiry {
  Custom = "CUSTOM",
  Gtc = "GTC",
  Gtd = "GTD",
}

export type OrderFilterOrder = {
  createdOn?: InputMaybe<OrderByDirection>;
  currentVolume?: InputMaybe<OrderByDirection>;
  unitPrice?: InputMaybe<OrderByDirection>;
};

/** Filter criteria for Order History Query */
export type OrderHistoryFilter = {
  assetType: AssetType;
  ccAttributes?: InputMaybe<CcOrderAttributeFilter>;
  idIn?: InputMaybe<Array<Scalars["UUID"]>>;
  position?: InputMaybe<OrderPosition>;
  recAttributes?: InputMaybe<RecOrderAttributeFilter>;
  statuses?: InputMaybe<Array<InputMaybe<OrderStatus>>>;
};

/** Input for order history */
export type OrderHistoryInput = {
  /** Pagination input */
  offSetPaginationInfo?: InputMaybe<OrderBookOffsetPaginationInput>;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<OrderBookSortOrderInput>>;
  /** Filter for order history */
  where: OrderHistoryFilter;
};

/** Order history response */
export type OrderHistoryResponse = {
  __typename?: "OrderHistoryResponse";
  /** Offset info */
  offsetInfo: OrderBookOffsetInfo;
  /** List of orders */
  orders: Array<Order>;
};

export type OrderInput = {
  /** Specifies whether this order should accept counter offers */
  allowCounterOffers?: InputMaybe<Scalars["Boolean"]>;
  expiryDate?: InputMaybe<Scalars["DateTime"]>;
  orderExpiry?: InputMaybe<OrderExpiry>;
  position: OrderPosition;
  price: MoneyInput;
  registryCode?: InputMaybe<RegistryCode>;
  type: OrderType;
  volume: Scalars["Int"];
};

export enum OrderPosition {
  Ask = "ASK",
  Bid = "BID",
}

export enum OrderStatus {
  Complete = "COMPLETE",
  Failed = "FAILED",
  Locking = "LOCKING",
  Open = "OPEN",
  Partial = "PARTIAL",
  PartialFailure = "PARTIAL_FAILURE",
  PartialWithdrawing = "PARTIAL_WITHDRAWING",
  PartialWithdrawn = "PARTIAL_WITHDRAWN",
  Pending = "PENDING",
  PendingComplete = "PENDING_COMPLETE",
  PendingPartial = "PENDING_PARTIAL",
  PendingPartialWithdrawing = "PENDING_PARTIAL_WITHDRAWING",
  Withdrawing = "WITHDRAWING",
  Withdrawn = "WITHDRAWN",
}

export enum OrderType {
  /** All or None (AON) - Execute all commodities or none */
  Aon = "AON",
  /** Order is a result of execution of counter offer */
  CounterOffer = "COUNTER_OFFER",
  /** Fill or Kill (FOK) - Execute the entire order immediately or cancel it */
  Fok = "FOK",
  /** Order is a result of forward trade */
  Forward = "FORWARD",
  /** Immediate or Cancel (IOC) - Execute what you can immediately and cancel the rest */
  Ioc = "IOC",
  /** Orders placed by members on the platform using buy/sell buttons */
  Limit = "LIMIT",
  /** Order at market price */
  Market = "MARKET",
  /** Order created by admin on behalf of the members */
  Otc = "OTC",
}

/**
 * DEPRECATED! Will be removed at a future release.
 *
 * Use TradingGroupTradeSummary as the replacement.
 */
export type OverviewSummary = {
  __typename?: "OverviewSummary";
  averageSavingsBAU: Scalars["BigDecimal"];
  averageSavingsLEM: Scalars["BigDecimal"];
  carbonEmissionSaving: Scalars["Int"];
  numberOfCommunityBatteries: Scalars["Int"];
  numberOfCorporateIndustryBessProsumers: Scalars["Int"];
  numberOfCorporateIndustryConsumers: Scalars["Int"];
  numberOfCorporateIndustryProsumers: Scalars["Int"];
  numberOfRetailBessProsumers: Scalars["Int"];
  numberOfRetailConsumers: Scalars["Int"];
  numberOfRetailProsumers: Scalars["Int"];
  p2pPurchasePrice: Scalars["BigDecimal"];
  p2pSalePrice: Scalars["BigDecimal"];
  p2pVolumePurchased: Scalars["BigDecimal"];
  p2pVolumeSold: Scalars["BigDecimal"];
  selfConsumptionPercentage: Scalars["BigDecimal"];
  selfSufficiencyPercentage: Scalars["BigDecimal"];
};

export type Ownership = {
  __typename?: "Ownership";
  accountNumber?: Maybe<Scalars["String"]>;
  applicationHostId?: Maybe<Scalars["UUID"]>;
  applicationHostName?: Maybe<Scalars["String"]>;
  from?: Maybe<Scalars["DateTime"]>;
  tenantId?: Maybe<Scalars["UUID"]>;
  tenantName?: Maybe<Scalars["String"]>;
  to?: Maybe<Scalars["DateTime"]>;
  tradingGroupId?: Maybe<Scalars["UUID"]>;
  tradingGroupName?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["UUID"]>;
};

export type OwnershipDateRangeInput = {
  from?: InputMaybe<Scalars["DateTime"]>;
  meterUid?: InputMaybe<Scalars["String"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
};

export type OwnershipInput = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  applicationHostId?: InputMaybe<Scalars["UUID"]>;
  applicationHostName?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["DateTime"]>;
  tenantId?: InputMaybe<Scalars["UUID"]>;
  tenantName?: InputMaybe<Scalars["String"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
  tradingGroupId?: InputMaybe<Scalars["UUID"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** Page Details for paginated requests */
export type PageDetail = {
  __typename?: "PageDetail";
  /** Current offset limit */
  limit?: Maybe<Scalars["Int"]>;
  /** Current offset */
  offset?: Maybe<Scalars["Int"]>;
  /** Total Elements */
  total?: Maybe<Scalars["Int"]>;
};

/** Input for paginated accounts */
export type PaginatedAccountsInput = {
  /** Pagination input */
  offSetPaginationInfo?: InputMaybe<AccountServiceOffsetPaginationInput>;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<AccountServiceSortOrderInput>>;
  /** Filter for accounts */
  where?: InputMaybe<AccountFilter>;
};

/** Paginated Account response */
export type PaginatedAccountsResponse = {
  __typename?: "PaginatedAccountsResponse";
  /** List of orders */
  accounts?: Maybe<Array<Account>>;
  /** Offset info */
  offsetInfo: AccountServiceOffsetInfo;
};

/** Input for paginated holdings */
export type PaginatedHoldingsInput = {
  /** Pagination input */
  offSetPaginationInfo?: InputMaybe<RegistryServiceOffsetPaginationInput>;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<RegistryServiceSortOrderInput>>;
  /** Filter for holdings */
  where?: InputMaybe<HoldingFilter>;
};

export type PaginatedHoldingsResponse = {
  __typename?: "PaginatedHoldingsResponse";
  /** List of holdings */
  holdings?: Maybe<Array<Holding>>;
  /** Offset info */
  offsetInfo: RegistryServiceOffsetInfo;
};

/**
 * Pagination information for handling cursor based forwards and backwards pagination.
 * See spec: https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PaginationInfo = {
  __typename?: "PaginationInfo";
  /** Cursor corresponding to the last node in the edges array */
  endCursor?: Maybe<Scalars["String"]>;
  /** Indicates if there are more edges following the provided cursor */
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  /** Indicates if there are more edges prior to the provided cursor */
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  /** Cursor corresponding to the first node in the edges array */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Offset pagination input */
export type PaginationInput = {
  /** Number of items to return, defaults to 20 */
  limit?: InputMaybe<Scalars["Int"]>;
  /** Number of items to skip, defaults to 0 */
  offset?: InputMaybe<Scalars["Int"]>;
};

/**
 * A trade summary of a settlement for an end-user/participant
 * of a trading group.
 */
export type ParticipantTradeSummary = {
  __typename?: "ParticipantTradeSummary";
  averageSavings: Scalars["BigDecimal"];
  endDateTime: Scalars["DateTime"];
  gridBuyPrice: Scalars["BigDecimal"];
  gridEnergyBought: Scalars["BigDecimal"];
  gridEnergySold: Scalars["BigDecimal"];
  gridSellPrice: Scalars["BigDecimal"];
  numCommunityBatteries: Scalars["Int"];
  numCorporateIndustryBessProsumers: Scalars["Int"];
  numCorporateIndustryConsumers: Scalars["Int"];
  numCorporateIndustryProsumers: Scalars["Int"];
  numRetailBessProsumers: Scalars["Int"];
  numRetailConsumers: Scalars["Int"];
  numRetailProsumers: Scalars["Int"];
  p2pBuyPrice: Scalars["BigDecimal"];
  p2pEnergyBought: Scalars["BigDecimal"];
  p2pEnergySold: Scalars["BigDecimal"];
  p2pSellPrice: Scalars["BigDecimal"];
  selfConsumptionPercentage: Scalars["BigDecimal"];
  selfSufficiencyPercentage: Scalars["BigDecimal"];
  settlementId: Scalars["UUID"];
  startDateTime: Scalars["DateTime"];
  topBuyers?: Maybe<Array<TopTraderInfo>>;
  topSellers?: Maybe<Array<TopTraderInfo>>;
  totalSavings: Scalars["BigDecimal"];
};

export type ParticipantTradeSummaryFilter = {
  applicationHostId: Scalars["UUID"];
  settlementId?: InputMaybe<Scalars["UUID"]>;
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

export enum PartyType {
  Buyer = "BUYER",
  Seller = "SELLER",
}

export type PassportInput = {
  countryCode?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
};

export type PasswordsInput = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

/** Represents the details and status of a payment */
export type Payment = {
  __typename?: "Payment";
  /** Amount to be transferred */
  amount: Money;
  /** Correlation ID for the payment. For settlements, this would be a settlement ID that all related payments, including fees, is tied to. */
  correlationId: Scalars["String"];
  id: Scalars["UUID"];
  /** Timestamp of when the payment information was first received by the payment provider */
  initiatedOn?: Maybe<Scalars["DateTime"]>;
  /** Timestamp of when the payment was marked as processed/completed */
  processedOn?: Maybe<Scalars["DateTime"]>;
  /** Payment provider type determines the payments channel to be used for the transaction */
  providerType: PaymentProviderType;
  /**
   * Source trading account ID that will send the payment amount.
   * Rebate payments don't need to specify this ID, as it will automatically debit the assigned master account.
   */
  sourceAccountId?: Maybe<Scalars["UUID"]>;
  /** Payment status */
  status: PaymentStatus;
  /** Detailed information around why a payment has entered a particular state. Useful for error related information. */
  statusMessage?: Maybe<Scalars["String"]>;
  /**
   * Target trading account ID that will receive the payment amount.
   * Fee payments don't need to specify this ID, as it will automatically credit the assigned master account.
   */
  targetAccountId?: Maybe<Scalars["UUID"]>;
  /** Type of the payment */
  type: PaymentType;
};

/** The method that allows a user to send and receive funds within TraceX */
export type PaymentMethod = {
  __typename?: "PaymentMethod";
  /** Code of the payment method */
  code: PaymentMethodCode;
  description: Scalars["String"];
  displayName: Scalars["String"];
  id: Scalars["UUID"];
  paymentProvider: PaymentProviderV2;
};

/**
 * Supported payment method codes. A payment method code is a combined representation of
 * the payment provider and the underlying payment method.
 */
export enum PaymentMethodCode {
  DwollaPlaid = "DWOLLA_PLAID",
  Icx = "ICX",
}

/** The possible states for an account's linked payment method. */
export enum PaymentMethodLinkStatus {
  /** Active payment method which is currently being used to process any payment requests */
  Active = "ACTIVE",
  /** Payment method link has failed. See the status message for more information */
  Error = "ERROR",
  /** The initial link attempt failed but is safe to retry. See the status message for more information */
  LinkRetry = "LINK_RETRY",
  /** Initialised payment method which is not yet capable of processing payments */
  NotReady = "NOT_READY",
  /** Payment method which is capable of processing payments, but is not yet activated */
  Ready = "READY",
  /** Historical payment method. Cannot be used for processing payments */
  Removed = "REMOVED",
}

/** Support payment providers */
export enum PaymentProviderType {
  DwollaPlaid = "DWOLLA_PLAID",
}

/** The entity that provides the payment methods in which a user can buy/sell commodities on TraceX */
export type PaymentProviderV2 = {
  __typename?: "PaymentProviderV2";
  description: Scalars["String"];
  displayName: Scalars["String"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  paymentMethods: Array<PaymentMethod>;
};

/** Represents the status of a payment */
export enum PaymentStatus {
  /** Signifies that the payment was cancelled during processing */
  Cancelled = "CANCELLED",
  /** Payment has successfully been created but has not yet been received by the provider */
  Created = "CREATED",
  /** Signifies that the payment failed due to an issue with the source account */
  FailedSource = "FAILED_SOURCE",
  /** Signifies that the payment failed due to an issue with the target account */
  FailedTarget = "FAILED_TARGET",
  /** Signifies that the payment failed due to an unspecified reason */
  FailedUnknown = "FAILED_UNKNOWN",
  /** Payment has successfully been completed, and the target account should have been credited */
  Processed = "PROCESSED",
  /** Payment has successfully been received by the provider and is being processed */
  Received = "RECEIVED",
}

/** Supported payment types */
export enum PaymentType {
  /**
   * Payment type for reimbursing customers a portion of already charged fees.
   * Payments of this type don't need to specify a source account.
   */
  Rebate = "REBATE",
}

export type PriceFilter = {
  gte?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
};

export type ProjectIdResponse = {
  __typename?: "ProjectIdResponse";
  /** ProjectId matching the search string */
  projectId: Scalars["String"];
};

export type ProjectIdsFilter = {
  /** String to search Project Id */
  projectId?: InputMaybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  account?: Maybe<Account>;
  /** Query used to fetch list of account registries by admin. For Admin dashboard */
  accountRegistries: AccountRegistryResponse;
  accounts: Array<Account>;
  applicationHost: ApplicationHost;
  /**
   * The spec of offset-based pagination is based on https://www.apollographql.com/docs/react/pagination/offset-based
   * Instead of list of items, we wrap the return to be a type for extra pagination info
   */
  applicationHosts: ApplicationHostsResult;
  assetByAssetCode: Asset;
  assets?: Maybe<Array<Asset>>;
  businessClassifications: Array<BusinessClassification>;
  /** Query to get all the carbon credits */
  carbonCredits: Array<CarbonCreditAudit>;
  /** Query to get all the carbon credits for admin */
  carbonCreditsForAdmin: Array<CarbonCreditAudit>;
  ccHoldings: Array<Holding>;
  ccHoldingsForAdmin: Array<Holding>;
  /** Fetch list of Clearing Records */
  clearingRecords: ClearingRecordsResponse;
  /** Query to fetch all countered orders for the user */
  counteredOrders: CounteredOrdersResponse;
  /** Query used to retrieve the currency conversion rate based on the provided input. */
  currencyConversionRate?: Maybe<CurrencyConversionRate>;
  /**
   * Query can used to check whether there is a trading halt at the moment.
   * Returns null if there are none.
   */
  currentTradingHalt?: Maybe<TradingHalt>;
  /**
   * DEPRECATED! Use tradingGroupTradeOverview query.
   *
   * Get a trading group summary showing a comparison between the usage
   * and dollar amounts with and without LEM.
   */
  dashboardNetworkSummary?: Maybe<NetworkSummary>;
  /**
   * DEPRECATED! Use tradingGroupTradeOverview query.
   *
   * Get a participant summary for a settlement. If a settlement id is not
   * provided, the latest settlement summary will be returned
   *
   * See the equivalent settlementSummary to get trading group
   * settlement summary for admin, operators and corporate staff.
   */
  dashboardOverview?: Maybe<OverviewSummary>;
  /** Query export request by uid */
  exportRequestById: ExportRequest;
  exportRequests: ExportRequestsResponse;
  getBillingAccount?: Maybe<BillingAccount>;
  getBillingAccounts?: Maybe<Array<BillingAccount>>;
  getCommands: Array<Maybe<Command>>;
  getMeter?: Maybe<Meter>;
  getMeters?: Maybe<Array<Maybe<Meter>>>;
  getUserMeter?: Maybe<Meter>;
  /** Fetch the bank balance, in the lowest currency denomination, of an account for an Admin */
  icxAccountBalance: IcxAccountBalance;
  /**
   * Get a list of the last 30 settlement info such as the settlement id and start and end
   * date times. Can be used for creating a drop-list list of available settlements.
   */
  lastSettlementInfos?: Maybe<Array<TradeSettlementInfo>>;
  managedCommodities: ManagedCommodityResponse;
  /** Query used to get orders for Market Order table. */
  marketOrdersV2: MarketOrderResponse;
  /** Query to fetch matched trade contracts for Admin dashboard */
  matchedTradeContracts: MatchedTradeContractResponse;
  messages: MessagesResponse;
  /** Query to fetch offerGroups */
  offerGroups?: Maybe<Array<OfferGroup>>;
  /** Query to fetch offers */
  offers?: Maybe<Array<Offer>>;
  /** Query used to get orders for Order History Table. Only returns orders that are tied to the authenticated user making the call. */
  orderHistoryV2: OrderHistoryResponse;
  paginatedAccounts: PaginatedAccountsResponse;
  paginatedHoldings: PaginatedHoldingsResponse;
  /**
   * Get a participant trade summary for a settlement. If a settlement id is not
   * provided, the latest settlement summary will be returned.
   */
  participantTradeSummary?: Maybe<ParticipantTradeSummary>;
  /** Query to get all projectIds in the system */
  projectIds: Array<ProjectIdResponse>;
  registries?: Maybe<Array<Registry>>;
  /** Query Retirement by uid */
  retirementById: Retirement;
  /** Query for an account to to retrieve their own retirement history */
  retirementHistory: RetirementHistoryResponse;
  /** Query for an admin to to retrieve all the account's retirement history */
  retirementHistoryAdmin: RetirementHistoryAdminResponse;
  searchTenants: SearchTenantsResult;
  /**
   * DEPRECATED! Use tradingGroupTradeOverview query.
   *
   * Get a trading group summary for a settlement. If a settlementId
   * is not provided, the latest settlement data will be returned.
   */
  settlementSummary?: Maybe<SettlementSummary>;
  settlements?: Maybe<Array<Settlement>>;
  /** Query used in generating the transaction summary data for an individual finalised order. */
  spotTradeTransactionSummary: SpotTradeTransactionSummary;
  tariffTemplates?: Maybe<Array<Maybe<TariffTemplate>>>;
  tenantInfo: TenantV2;
  tenants: Array<Tenant>;
  /** Fetches assets assigned to the trade contract */
  tradeContractAssetsByTradeContractId?: Maybe<Array<TradeContractAsset>>;
  /** Query to fetch all the trade contracts for history table  */
  tradeContractHistory: TradeContractResponse;
  /** Query to fetch open trade contracts for market Table */
  tradeContractMarketQuery: TradeContractResponse;
  /** Query used in generating the transaction summary data for an individual finalised trade contract. */
  tradeContractTransactionSummary: TradeContractTransactionSummary;
  /** Query used to generate trade report for the given time period */
  tradeReport: TradeReportResponse;
  tradingAccountUsers: Array<TradingAccountUser>;
  tradingGroup: TradingGroup;
  /**
   * Get a trading group summary showing a comparison of the usages and dollar amounts
   * with and without LEM. If a settlement id is not provided in the filter, the latest
   * settlement summary will be returned.
   */
  tradingGroupTradeComparison?: Maybe<TradingGroupComparisonSummary>;
  /**
   * Get a trading group trade summary for a settlement. If a settlement id is not
   * provided in the filter, the latest settlement summary will be returned.
   */
  tradingGroupTradeSummary?: Maybe<TradingGroupTradeSummary>;
  tradingGroups: Array<Maybe<TradingGroup>>;
  /** Query used for generating the trading summary data for the given time period */
  tradingSummary?: Maybe<TradingSummary>;
  /** Get the details of a trade transaction given an id of the transaction. */
  transaction?: Maybe<EnergyTransaction>;
  /** Query used to get transaction fee for the given account */
  transactionFee: TransactionFee;
  /** Query used in generating the transaction summary data for an individual finalised order. */
  transactionSummary: TransactionSummary;
  /** Get a list of trade transactions. */
  transactions?: Maybe<Array<Maybe<EnergyTransaction>>>;
  /**
   * Query used to return a list of upcoming trading halts if any.
   * Returns an empty list if there are none.
   */
  upcomingTradingHalts?: Maybe<Array<Maybe<TradingHalt>>>;
  user: User;
  userAccess?: Maybe<UserAccess>;
  /** Fetch the bank balance, in the lowest currency denomination, of an account for an User */
  userIcxAccountBalance: IcxAccountBalance;
  userProfile: User;
  userTariffs?: Maybe<Array<Maybe<UserTariff>>>;
  /** Get a list of trade transactions for a end-user/participant. */
  userTransactions?: Maybe<Array<Maybe<EnergyTransaction>>>;
  users: UserConnection;
};

export type QueryAccountRegistriesArgs = {
  input: AccountRegistryInput;
};

export type QueryAccountsArgs = {
  where?: InputMaybe<AccountFilter>;
};

export type QueryApplicationHostArgs = {
  id: Scalars["UUID"];
  tenantId: Scalars["UUID"];
};

export type QueryApplicationHostsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ApplicationHostFilter>;
};

export type QueryAssetByAssetCodeArgs = {
  assetCode: AssetType;
  registryCode?: InputMaybe<RegistryCode>;
};

export type QueryAssetsArgs = {
  where?: InputMaybe<AssetFilter>;
};

export type QueryCarbonCreditsArgs = {
  where?: InputMaybe<CarbonCreditFilter>;
};

export type QueryCarbonCreditsForAdminArgs = {
  where?: InputMaybe<CarbonCreditFilter>;
};

export type QueryCcHoldingsArgs = {
  where?: InputMaybe<CcHoldingFilter>;
};

export type QueryCcHoldingsForAdminArgs = {
  where?: InputMaybe<CcHoldingFilter>;
};

export type QueryClearingRecordsArgs = {
  where?: InputMaybe<ClearingRecordsFilterInput>;
};

export type QueryCounteredOrdersArgs = {
  input: CounteredOrdersInput;
};

export type QueryCurrencyConversionRateArgs = {
  where: CurrencyConversionRateFilter;
};

export type QueryDashboardNetworkSummaryArgs = {
  filter: SettlementSummaryFilter;
};

export type QueryDashboardOverviewArgs = {
  settlementId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryExportRequestByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryExportRequestsArgs = {
  input: ExportRequestInput;
};

export type QueryGetBillingAccountArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  applicationHostName?: InputMaybe<Scalars["String"]>;
  tenantName?: InputMaybe<Scalars["String"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
};

export type QueryGetBillingAccountsArgs = {
  applicationHostName?: InputMaybe<Scalars["String"]>;
  tenantName?: InputMaybe<Scalars["String"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
};

export type QueryGetCommandsArgs = {
  where?: InputMaybe<CommandFilter>;
};

export type QueryGetMeterArgs = {
  applicationHostName?: InputMaybe<Scalars["String"]>;
  meterExternalRef?: InputMaybe<Scalars["String"]>;
  tenantName?: InputMaybe<Scalars["String"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
};

export type QueryGetMetersArgs = {
  applicationHostName?: InputMaybe<Scalars["String"]>;
  tenantName?: InputMaybe<Scalars["String"]>;
  tradingGroupName?: InputMaybe<Scalars["String"]>;
};

export type QueryGetUserMeterArgs = {
  userId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryIcxAccountBalanceArgs = {
  id: Scalars["UUID"];
};

export type QueryManagedCommoditiesArgs = {
  input: ManagedCommodityInput;
};

export type QueryMarketOrdersV2Args = {
  input?: InputMaybe<MarketOrdersInput>;
};

export type QueryMatchedTradeContractsArgs = {
  input: MatchedTradeContractInput;
};

export type QueryMessagesArgs = {
  input: MessagesInput;
};

export type QueryOfferGroupsArgs = {
  filter: OfferGroupFilter;
};

export type QueryOffersArgs = {
  filter: OfferFilter;
};

export type QueryOrderHistoryV2Args = {
  input?: InputMaybe<OrderHistoryInput>;
};

export type QueryPaginatedAccountsArgs = {
  input?: InputMaybe<PaginatedAccountsInput>;
};

export type QueryPaginatedHoldingsArgs = {
  input?: InputMaybe<PaginatedHoldingsInput>;
};

export type QueryParticipantTradeSummaryArgs = {
  settlementId?: InputMaybe<Scalars["UUID"]>;
};

export type QueryProjectIdsArgs = {
  where?: InputMaybe<ProjectIdsFilter>;
};

export type QueryRegistriesArgs = {
  where?: InputMaybe<RegistryFilter>;
};

export type QueryRetirementByIdArgs = {
  id: Scalars["UUID"];
  registryCode?: InputMaybe<RegistryCode>;
};

export type QueryRetirementHistoryArgs = {
  input: RetirementHistoryInput;
};

export type QueryRetirementHistoryAdminArgs = {
  input: RetirementHistoryAdminInput;
};

export type QuerySearchTenantsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchTenantsInput?: InputMaybe<SearchTenantsInput>;
};

export type QuerySettlementSummaryArgs = {
  filter: SettlementSummaryFilter;
};

export type QuerySettlementsArgs = {
  where?: InputMaybe<SettlementFilter>;
};

export type QuerySpotTradeTransactionSummaryArgs = {
  orderId: Scalars["UUID"];
};

export type QueryTariffTemplatesArgs = {
  where?: InputMaybe<TariffTemplatesFilter>;
};

export type QueryTenantInfoArgs = {
  id: Scalars["UUID"];
};

export type QueryTradeContractAssetsByTradeContractIdArgs = {
  tradeContractId: Scalars["UUID"];
};

export type QueryTradeContractHistoryArgs = {
  input: TradeContractHistoryInput;
};

export type QueryTradeContractMarketQueryArgs = {
  input: TradeContractMarketQueryInput;
};

export type QueryTradeContractTransactionSummaryArgs = {
  tradeContractId: Scalars["UUID"];
};

export type QueryTradeReportArgs = {
  where: TradeReportInput;
};

export type QueryTradingGroupArgs = {
  applicationHostId: Scalars["UUID"];
  id: Scalars["UUID"];
  tenantId: Scalars["UUID"];
};

export type QueryTradingGroupTradeComparisonArgs = {
  filter: TradingGroupTradeSummaryFilter;
};

export type QueryTradingGroupTradeSummaryArgs = {
  filter: TradingGroupTradeSummaryFilter;
};

export type QueryTradingGroupsArgs = {
  where?: InputMaybe<TradingGroupFilter>;
};

export type QueryTradingSummaryArgs = {
  where: TradingSummaryInput;
};

export type QueryTransactionArgs = {
  id: Scalars["UUID"];
};

export type QueryTransactionFeeArgs = {
  input: TransactionFeeInput;
};

export type QueryTransactionSummaryArgs = {
  orderId: Scalars["UUID"];
};

export type QueryTransactionsArgs = {
  where: TransactionFilter;
};

export type QueryUserArgs = {
  id: Scalars["UUID"];
};

export type QueryUserProfileArgs = {
  identityProviderRef: Scalars["String"];
};

export type QueryUserTariffsArgs = {
  where?: InputMaybe<UserTariffFilter>;
};

export type QueryUserTransactionsArgs = {
  transactionEndDateTime: Scalars["DateTime"];
  transactionStartDateTime: Scalars["DateTime"];
  userId: Scalars["UUID"];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserFilter>;
};

export type ReadingsConfig = {
  __typename?: "ReadingsConfig";
  defaultEnergyUnit?: Maybe<DefaultEnergyUnit>;
  defaultIntervalInSeconds?: Maybe<Scalars["Int"]>;
  filePathRegex: Scalars["String"];
  rawReadingsConfig?: Maybe<Scalars["String"]>;
  rawReadingsOverrideConfig?: Maybe<Scalars["String"]>;
  readingsFormatType: ReadingsFormatType;
  readingsType: ReadingsType;
  regexFilePostfix: Scalars["String"];
  regexFilePrefix: Scalars["String"];
  regexFolderList: Array<Scalars["String"]>;
};

export type ReadingsConfigMutationInput = {
  defaultEnergyUnit?: InputMaybe<DefaultEnergyUnit>;
  defaultIntervalInSeconds?: InputMaybe<Scalars["Int"]>;
  rawReadingsConfig?: InputMaybe<Scalars["String"]>;
  rawReadingsOverrideConfig?: InputMaybe<Scalars["String"]>;
  readingsFormatType: ReadingsFormatType;
  readingsType: ReadingsType;
  regexFilePostfix: Scalars["String"];
  regexFilePrefix: Scalars["String"];
  regexFolderList: Array<Scalars["String"]>;
};

export enum ReadingsFormatType {
  Json = "json",
  Nem12 = "nem12",
  SingleMeterPerLine = "single_meter_per_line",
  Standard = "standard",
}

export enum ReadingsType {
  Cumulative = "cumulative",
  Interval = "interval",
}

/** Attributes relevant to a Renewable Energy Certificate asset class. */
export type RecAssetAttributeFilter = {
  /** List of certification schemes the REC is certified by (e.g., CRS listed) */
  certifications?: InputMaybe<Array<Scalars["String"]>>;
  /** The start date of the project that this REC was generate from. Valid: 2024-01-31 Invalid: 2024-1-31 */
  commencementDate?: InputMaybe<Scalars["String"]>;
  /** List of eligibilities for the REC i.e, which schemes is the REC eligible to retire against */
  eligibilities?: InputMaybe<Array<Scalars["String"]>>;
  /** List of fuel sources used to generate the REC */
  fuelSources?: InputMaybe<Array<Scalars["String"]>>;
  /** List of locations of where the REC was generated */
  locations?: InputMaybe<Array<Scalars["String"]>>;
  /** List of projects / generator that produced the REC */
  projects?: InputMaybe<Array<Scalars["String"]>>;
  /** List of years of generation for the REC */
  vintages?: InputMaybe<Array<Scalars["String"]>>;
};

/** Attributes relevant to a Renewable Energy Certificate asset class. */
export type RecAssetAttributes = {
  __typename?: "RecAssetAttributes";
  /** List of certification schemes the REC is certified by (e.g., CRS listed) */
  certifications?: Maybe<Array<Scalars["String"]>>;
  /** The start date of the project that this REC was generate from. */
  commencementDate?: Maybe<Scalars["String"]>;
  /** List of eligibilities for the REC i.e, which schemes is the REC eligible to retire against */
  eligibilities?: Maybe<Array<Scalars["String"]>>;
  /** List of fuel sources used to generate the REC */
  fuelSources?: Maybe<Array<Scalars["String"]>>;
  /** Location of where the REC was generated */
  location: Scalars["String"];
  /** Name of the project / generator that produced the REC */
  project?: Maybe<Scalars["String"]>;
  /** Year of generation for the REC */
  vintage: Scalars["Int"];
  /** Half of year of generation for the REC */
  vintageHalf?: Maybe<VintageHalf>;
};

export type RecAttributeFilter = {
  certifications?: InputMaybe<Array<Scalars["String"]>>;
  /** Commencement date greater than or equal to filter */
  commencementDateGte?: InputMaybe<Scalars["Date"]>;
  eligibilities?: InputMaybe<Array<Scalars["String"]>>;
  fuelSources?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  projects?: InputMaybe<Array<Scalars["String"]>>;
  vintages?: InputMaybe<Array<Scalars["String"]>>;
};

export type RecAttributeInput = {
  certifications?: InputMaybe<Array<Scalars["String"]>>;
  /** The start date of the project that this REC was generate from. Valid: 2024-01-31 Invalid: 2024-1-31 */
  commencementDate?: InputMaybe<Scalars["Date"]>;
  eligibilities?: InputMaybe<Array<Scalars["String"]>>;
  fuelSources?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  projects?: InputMaybe<Array<Scalars["String"]>>;
  vintages?: InputMaybe<Array<Scalars["String"]>>;
};

export type RecAttributes = {
  __typename?: "RecAttributes";
  certifications?: Maybe<Array<Scalars["String"]>>;
  commencementDate?: Maybe<Scalars["Date"]>;
  eligibilities?: Maybe<Array<Scalars["String"]>>;
  fuelSources?: Maybe<Array<Scalars["String"]>>;
  locations?: Maybe<Array<Scalars["String"]>>;
  projects?: Maybe<Array<Scalars["String"]>>;
  vintages?: Maybe<Array<Scalars["String"]>>;
};

export type RecOrderAttributeFilter = {
  certifications?: InputMaybe<Array<Scalars["String"]>>;
  /** The start date of the project that this REC was generate from. Valid: 2024-01-31 Invalid: 2024-1-31 */
  commencementDate?: InputMaybe<Scalars["String"]>;
  eligibilities?: InputMaybe<Array<Scalars["String"]>>;
  fuelSources?: InputMaybe<Array<Scalars["String"]>>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  /** @deprecated deprecated in favor of projects */
  project?: InputMaybe<Scalars["String"]>;
  projects?: InputMaybe<Array<Scalars["String"]>>;
  /** @deprecated deprecated in favor of vintages */
  vintage?: InputMaybe<Scalars["Int"]>;
  /**
   * Determines which half of the calendar year the RECs are filtered by
   * @deprecated deprecated in favor of vintages
   */
  vintageHalf?: InputMaybe<VintageHalf>;
  vintages?: InputMaybe<Array<Scalars["String"]>>;
};

export type RecOrderAttributeInput = {
  certifications?: InputMaybe<Array<Scalars["String"]>>;
  /** The start date of the project that this REC was generate from. Valid: 2024-01-31 Invalid: 2024-1-31 */
  commencementDate?: InputMaybe<Scalars["String"]>;
  eligibilities?: InputMaybe<Array<Scalars["String"]>>;
  fuelSources?: InputMaybe<Array<Scalars["String"]>>;
  location?: InputMaybe<Scalars["String"]>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  project?: InputMaybe<Scalars["String"]>;
  projects?: InputMaybe<Array<Scalars["String"]>>;
  vintage?: InputMaybe<Scalars["Int"]>;
  /** Half of year of generation for the REC */
  vintageHalf?: InputMaybe<VintageHalf>;
  vintages?: InputMaybe<Array<Scalars["String"]>>;
};

export type RecOrderAttributes = {
  __typename?: "RecOrderAttributes";
  certifications?: Maybe<Array<Scalars["String"]>>;
  /** The start date of the project that this REC was generate from. */
  commencementDate?: Maybe<Scalars["String"]>;
  eligibilities?: Maybe<Array<Scalars["String"]>>;
  fuelSources?: Maybe<Array<Scalars["String"]>>;
  /** Generator associated with the project attribute of a Renewable Energy Certificate order */
  generator?: Maybe<Generator>;
  location?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<Scalars["String"]>>;
  project?: Maybe<Scalars["String"]>;
  projects?: Maybe<Array<Scalars["String"]>>;
  vintage?: Maybe<Scalars["Int"]>;
  /** Half of year of generation for the REC */
  vintageHalf?: Maybe<VintageHalf>;
  vintages?: Maybe<Array<Scalars["String"]>>;
};

export type Registry = {
  __typename?: "Registry";
  code: Scalars["String"];
  fullName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  shortName: Scalars["String"];
};

export enum RegistryCode {
  Ercot = "ERCOT",
  Icx = "ICX",
  Mrets = "MRETS",
}

export type RegistryFilter = {
  codeIn?: InputMaybe<Array<Scalars["String"]>>;
  idIn?: InputMaybe<Array<Scalars["UUID"]>>;
};

export enum RegistryLinkStatus {
  Pending = "PENDING",
  Registered = "REGISTERED",
  RegistrationFailed = "REGISTRATION_FAILED",
}

/** Supported registries */
export enum RegistryName {
  GoldStandard = "GOLD_STANDARD",
  Verra = "VERRA",
}

/** Page info */
export type RegistryServiceOffsetInfo = {
  __typename?: "RegistryServiceOffsetInfo";
  /** Current offset limit */
  limit?: Maybe<Scalars["Int"]>;
  /** Current offset */
  offset?: Maybe<Scalars["Int"]>;
  /** Total offsets */
  total?: Maybe<Scalars["Int"]>;
};

/** Offset pagination input */
export type RegistryServiceOffsetPaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** Sort order input */
export type RegistryServiceSortOrderInput = {
  field: Scalars["String"];
  sortOrder: OrderByDirection;
};

export type RejectApplicationInput = {
  accountId: Scalars["UUID"];
};

export type RejectApplicationResponse = {
  __typename?: "RejectApplicationResponse";
  account: Account;
};

export type RejectRetirementRequestInput = {
  rejectReason: Scalars["String"];
  retirementId: Scalars["UUID"];
};

export type RejectRetirementRequestResult = {
  __typename?: "RejectRetirementRequestResult";
  retirement: Retirement;
};

/** Remove document input */
export type RemoveDocumentInput = {
  /** Id of the document being removed */
  documentId: Scalars["UUID"];
};

export type RemoveDocumentResponse = {
  __typename?: "RemoveDocumentResponse";
  document: Document;
};

export type RemoveFundingSourceInput = {
  /** The linked payment method code which has the funding source to be removed */
  paymentMethodCode: PaymentMethodCode;
};

export type RemoveFundingSourceResponse = {
  __typename?: "RemoveFundingSourceResponse";
  linkedPaymentMethod?: Maybe<LinkedPaymentMethod>;
};

/** Input for removing the trading account User */
export type RemoveTradingAccountUserInput = {
  /** Id of the user being removed */
  tradingAccountUserId?: InputMaybe<Scalars["UUID"]>;
};

export type RemoveTradingAccountUserResponse = {
  __typename?: "RemoveTradingAccountUserResponse";
  tradingAccountUser?: Maybe<TradingAccountUser>;
};

export type ResubmitApplicationResponse = {
  __typename?: "ResubmitApplicationResponse";
  account: Account;
};

export type ResubmitByAdminInput = {
  accountId: Scalars["UUID"];
};

export type ResubmitByAdminResponse = {
  __typename?: "ResubmitByAdminResponse";
  account: Account;
};

/** Retirement request input detail */
export type RetireCommoditiesInput = {
  /** Retire commodity details */
  commodityDetails: Array<DispatchByVolumeDetailInput>;
  registryCode?: InputMaybe<RegistryCode>;
  /** Retirement category */
  retirementCategory: RetirementCategory;
  /**
   * Retirement details
   *
   * json value for green-E
   * {"reason":"","compliancePeriod":"","notes":""}
   *
   * json value for beneficial ownership
   * {"reason":"",""notes":""}
   */
  retirementDetails: Scalars["JSON"];
  /** Type of retirement */
  retirementType: Scalars["String"];
};

export type RetireCommoditiesResult = {
  __typename?: "RetireCommoditiesResult";
  retirementId: Scalars["UUID"];
};

export type RetireCommodityDetail = CommodityActionRequestDetail & {
  __typename?: "RetireCommodityDetail";
  /** Attributes of requested commodity */
  attributes: AssetAttributes;
  /** Transaction details for each attribute set */
  commodityTransactions?: Maybe<Array<CommodityTransaction>>;
  /** Commodity Action Request Detail id */
  id: Scalars["UUID"];
  /** Status of the retire commodity detail */
  status: RetirementStatus;
  /** Status message in case of failure */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Volume requested for retirement */
  volume: Scalars["Long"];
};

/** Retirement request information */
export type Retirement = CommodityActionRequest & {
  __typename?: "Retirement";
  /** accountId of the commodity holder */
  accountId: Scalars["UUID"];
  /** Asset type */
  assetType: AssetType;
  /** Retirement commodity details */
  commodityDetails: Array<RetireCommodityDetail>;
  /** Completed date */
  completedOn?: Maybe<Scalars["DateTime"]>;
  id: Scalars["UUID"];
  /** initiator of the retirement */
  initiatedBy: ActionInitiator;
  /** Requested date */
  requestedOn: Scalars["DateTime"];
  /** Retirement category */
  retirementCategory: RetirementCategory;
  /** Retirement request details */
  retirementDetails: Scalars["JSON"];
  /** Unique number assigned to retirement request */
  retirementNumber: Scalars["String"];
  /** Type of retirement */
  retirementType: Scalars["String"];
  /** Status of the retirement request */
  status: RetirementStatus;
  /** Status message in case of failure */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Tenant of the action entity */
  tenantId: Scalars["UUID"];
  /** Summation of volume of each sub requests (attributes set / commodities) */
  totalVolume: Scalars["Long"];
};

export enum RetirementCategory {
  Compliance = "COMPLIANCE",
  Voluntary = "VOLUNTARY",
}

/** Retirement history filter for admin */
export type RetirementHistoryAdminFilter = {
  /** Start date range */
  startDateRange?: InputMaybe<DateRangeFilter>;
  /** Status of the retirement request */
  statuses?: InputMaybe<Array<RetirementStatus>>;
};

export type RetirementHistoryAdminInput = {
  /** Pagination input */
  offSetPaginationInfo: RegistryServiceOffsetPaginationInput;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<RegistryServiceSortOrderInput>>;
  /** Filter for retirement history */
  where?: InputMaybe<RetirementHistoryAdminFilter>;
};

export type RetirementHistoryAdminResponse = {
  __typename?: "RetirementHistoryAdminResponse";
  /** Offset info */
  offsetInfo: RegistryServiceOffsetInfo;
  /** List of retirement requests */
  retirements: Array<Retirement>;
};

/** Retirement history filter */
export type RetirementHistoryFilter = {
  registryCode?: InputMaybe<RegistryCode>;
  /** Start date range */
  startDateRange?: InputMaybe<DateRangeFilter>;
  /** Status of the retirement request */
  statuses?: InputMaybe<Array<RetirementStatus>>;
};

export type RetirementHistoryInput = {
  /** Pagination input */
  offSetPaginationInfo: RegistryServiceOffsetPaginationInput;
  /** Sort order input */
  sortOrderInputs?: InputMaybe<Array<RegistryServiceSortOrderInput>>;
  /** Filter for retirement history */
  where?: InputMaybe<RetirementHistoryFilter>;
};

export type RetirementHistoryResponse = {
  __typename?: "RetirementHistoryResponse";
  /** Offset info */
  offsetInfo: RegistryServiceOffsetInfo;
  /** List of retirement requests */
  retirements: Array<Retirement>;
};

export enum RetirementStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  Retired = "RETIRED",
}

/** Input for reviewing carbon credits by admin, Admin can either reject of approve the carbon credit detail */
export type ReviewCarbonCreditInput = {
  /** Carbon credit audit id */
  carbonCreditAuditId: Scalars["UUID"];
  /** Remarks for the review action */
  remarks?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Review status */
  status: CarbonCreditAuditStatus;
};

export type ReviewCarbonCreditResponse = {
  __typename?: "ReviewCarbonCreditResponse";
  carbonCreditAudit: CarbonCreditAudit;
};

/** Input to take action against a REQUESTED offer */
export type ReviewOfferInput = {
  /** Action to take */
  action: OfferAction;
  /** offer to take action against */
  offerNumber: Scalars["String"];
};

/** Response for action takes against an offer */
export type ReviewOfferResponse = {
  __typename?: "ReviewOfferResponse";
  /** Any relevant message for the process */
  message: Scalars["String"];
  /** Specifies whether an action is being processed */
  processing: Scalars["Boolean"];
};

/** Input to take action against the Trade contract */
export type ReviewTradeContractInput = {
  /** Action to take */
  action: TradeContractAction;
  /** Trade contact id */
  id: Scalars["UUID"];
};

export enum RuleType {
  Adjustment = "ADJUSTMENT",
  Aggregator = "AGGREGATOR",
  Allocation = "ALLOCATION",
  CatchUp = "CATCH_UP",
  Correction = "CORRECTION",
  DynamicP2P = "DYNAMIC_P2P",
  Grid = "GRID",
  MicroGrid = "MICRO_GRID",
  P2P = "P2P",
  Ppa = "PPA",
  Preferential = "PREFERENTIAL",
  SelfTrade = "SELF_TRADE",
  Vpp = "VPP",
}

export type SearchTenantsInput = {
  nameKeyword?: InputMaybe<Scalars["String"]>;
  tenantIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type SearchTenantsResult = {
  __typename?: "SearchTenantsResult";
  pageInfo?: Maybe<OffsetPaginationInfo>;
  tenants: Array<TenantV2>;
};

/** Input for a currency conversion mutation. */
export type SetCurrencyConversionRateInput = {
  /** The conversion rate for the currency conversion */
  conversionRate: Scalars["String"];
  /** The source currency for the conversion */
  sourceCurrencyCode: CurrencyCode;
  /** The target currency for the conversion */
  targetCurrencyCode: CurrencyCode;
};

export type Settlement = {
  __typename?: "Settlement";
  applicationHostId: Scalars["UUID"];
  failureReason?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  intervalPeriod: Scalars["Int"];
  settlementCompletedOn?: Maybe<Scalars["DateTime"]>;
  settlementCreatedOn?: Maybe<Scalars["DateTime"]>;
  settlementEndDateTime: Scalars["DateTime"];
  settlementStartDateTime: Scalars["DateTime"];
  status: SettlementStatus;
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
};

export type SettlementFilter = {
  applicationHostId?: InputMaybe<Scalars["UUID"]>;
  settlementId?: InputMaybe<Array<Scalars["UUID"]>>;
  status?: InputMaybe<Array<SettlementStatus>>;
  tenantId?: InputMaybe<Scalars["UUID"]>;
  tradingGroupId?: InputMaybe<Scalars["UUID"]>;
};

export type SettlementInput = {
  applicationHostId: Scalars["UUID"];
  intervalPeriod: Scalars["Int"];
  settlementEndDateTime: Scalars["DateTime"];
  settlementStartDateTime: Scalars["DateTime"];
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
};

export type SettlementMeterInfo = {
  __typename?: "SettlementMeterInfo";
  amount?: Maybe<Scalars["BigDecimal"]>;
  description?: Maybe<Scalars["String"]>;
};

export type SettlementPeriodTrade = {
  __typename?: "SettlementPeriodTrade";
  bessProsumerAverageSavings: Scalars["BigDecimal"];
  bessProsumerTotalAmount: Scalars["BigDecimal"];
  bessProsumerTotalAmountBAU: Scalars["BigDecimal"];
  bessProsumerTotalSavings: Scalars["BigDecimal"];
  consumerAverageSavings: Scalars["BigDecimal"];
  consumerTotalAmount: Scalars["BigDecimal"];
  consumerTotalAmountBAU: Scalars["BigDecimal"];
  consumerTotalSavings: Scalars["BigDecimal"];
  endDateTime: Scalars["DateTime"];
  gridExport: Scalars["BigDecimal"];
  gridExportBAU: Scalars["BigDecimal"];
  gridImport: Scalars["BigDecimal"];
  gridImportBAU: Scalars["BigDecimal"];
  prosumerAverageSavings: Scalars["BigDecimal"];
  prosumerTotalAmount: Scalars["BigDecimal"];
  prosumerTotalAmountBAU: Scalars["BigDecimal"];
  prosumerTotalSavings: Scalars["BigDecimal"];
  settlementPeriod: Scalars["Int"];
  startDateTime: Scalars["DateTime"];
};

export enum SettlementStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Dispatched = "DISPATCHED",
  DispatchedWithErrors = "DISPATCHED_WITH_ERRORS",
  Error = "ERROR",
  Forecasting = "FORECASTING",
  ForecastCompleted = "FORECAST_COMPLETED",
  ForecastCompletedWithErrors = "FORECAST_COMPLETED_WITH_ERRORS",
  New = "NEW",
  Pricing = "PRICING",
  ReadyToTrade = "READY_TO_TRADE",
  Traded = "TRADED",
  Trading = "TRADING",
}

/**
 * DEPRECATED! Will be removed at a future release.
 *
 * Use TradingGroupTradeSummary as the replacement.
 */
export type SettlementSummary = {
  __typename?: "SettlementSummary";
  averageSavings: Scalars["BigDecimal"];
  gridPurchasePrice: Scalars["BigDecimal"];
  gridSalePrice: Scalars["BigDecimal"];
  gridVolumePurchased: Scalars["BigDecimal"];
  gridVolumeSold: Scalars["BigDecimal"];
  numberOfCommunityBatteries: Scalars["Int"];
  numberOfCorporateIndustryBessProsumers: Scalars["Int"];
  numberOfCorporateIndustryConsumers: Scalars["Int"];
  numberOfCorporateIndustryProsumers: Scalars["Int"];
  numberOfRetailBessProsumers: Scalars["Int"];
  numberOfRetailConsumers: Scalars["Int"];
  numberOfRetailProsumers: Scalars["Int"];
  p2pPurchasePrice: Scalars["BigDecimal"];
  p2pSalePrice: Scalars["BigDecimal"];
  p2pVolumePurchased: Scalars["BigDecimal"];
  p2pVolumeSold: Scalars["BigDecimal"];
  selfConsumptionPercentage: Scalars["BigDecimal"];
  selfSufficiencyPercentage: Scalars["BigDecimal"];
  topBuyers?: Maybe<Array<SettlementMeterInfo>>;
  topSellers?: Maybe<Array<SettlementMeterInfo>>;
  totalSavings: Scalars["BigDecimal"];
};

export type SettlementSummaryFilter = {
  applicationHostId: Scalars["UUID"];
  settlementId?: InputMaybe<Scalars["UUID"]>;
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

/** Sort order input */
export type SortOrderInput = {
  /** Name of the field to sort by */
  field: Scalars["String"];
  /** The direction to sort the field by */
  sortOrder: OrderByDirection;
};

/** Represents an order in a terminal fulfilled state along with details about its 'fills'. */
export type SpotTradeTransactionSummary = {
  __typename?: "SpotTradeTransactionSummary";
  /** Original order information placed by the Trader */
  order?: Maybe<Order>;
  /** Represents a match and subsequent settlement between two orders. */
  tradeTransactions: Array<TradeTransaction>;
};

export type StringSearchFilter = {
  contain?: InputMaybe<Scalars["String"]>;
  startWith?: InputMaybe<Scalars["String"]>;
};

export type Tariff = {
  __typename?: "Tariff";
  buyRate?: Maybe<Scalars["BigDecimal"]>;
  dayFrom?: Maybe<Scalars["PositiveInt"]>;
  dayTo?: Maybe<Scalars["PositiveInt"]>;
  sellRate?: Maybe<Scalars["BigDecimal"]>;
  timePeriods?: Maybe<Array<Maybe<TimePeriod>>>;
};

export type TariffInput = {
  buyRate?: InputMaybe<Scalars["BigDecimal"]>;
  dayFrom?: InputMaybe<Scalars["PositiveInt"]>;
  dayTo?: InputMaybe<Scalars["PositiveInt"]>;
  sellRate?: InputMaybe<Scalars["BigDecimal"]>;
  timePeriods?: InputMaybe<Array<InputMaybe<TimePeriodInput>>>;
};

export type TariffTemplate = {
  __typename?: "TariffTemplate";
  applicationHostId?: Maybe<Scalars["UUID"]>;
  id?: Maybe<Scalars["UUID"]>;
  tariffs?: Maybe<Array<Maybe<TariffTemplateTariff>>>;
  tenantId?: Maybe<Scalars["UUID"]>;
  timeZone?: Maybe<Scalars["String"]>;
  tradingGroupId?: Maybe<Scalars["UUID"]>;
  validFrom?: Maybe<Scalars["DateTime"]>;
  validTo?: Maybe<Scalars["DateTime"]>;
};

export type TariffTemplateInput = {
  applicationHostId: Scalars["UUID"];
  id: Scalars["UUID"];
  tariffs?: InputMaybe<Array<InputMaybe<TariffTemplateTariffInput>>>;
  tenantId: Scalars["UUID"];
  timeZone?: InputMaybe<Scalars["String"]>;
  tradingGroupId: Scalars["UUID"];
  validFrom?: InputMaybe<Scalars["DateTime"]>;
  validTo?: InputMaybe<Scalars["DateTime"]>;
};

export type TariffTemplateTariff = {
  __typename?: "TariffTemplateTariff";
  buyRate?: Maybe<Scalars["BigDecimal"]>;
  dayFrom?: Maybe<Scalars["PositiveInt"]>;
  dayTo?: Maybe<Scalars["PositiveInt"]>;
  displayName?: Maybe<Scalars["String"]>;
  sellRate?: Maybe<Scalars["BigDecimal"]>;
  timePeriods?: Maybe<Array<Maybe<TimePeriod>>>;
  type?: Maybe<TariffType>;
};

export type TariffTemplateTariffInput = {
  buyRate?: InputMaybe<Scalars["BigDecimal"]>;
  dayFrom?: InputMaybe<Scalars["PositiveInt"]>;
  dayTo?: InputMaybe<Scalars["PositiveInt"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  sellRate?: InputMaybe<Scalars["BigDecimal"]>;
  timePeriods?: InputMaybe<Array<InputMaybe<TimePeriodInput>>>;
  type?: InputMaybe<TariffType>;
};

export type TariffTemplatesFilter = {
  applicationHostId?: InputMaybe<Scalars["UUID"]>;
  tenantId?: InputMaybe<Scalars["UUID"]>;
  tradingGroupId?: InputMaybe<Scalars["UUID"]>;
};

export enum TariffType {
  OffPeak = "OFF_PEAK",
  Peak = "PEAK",
  Shoulder = "SHOULDER",
}

export type Tenant = {
  __typename?: "Tenant";
  displayName?: Maybe<Scalars["String"]>;
  electricityUom?: Maybe<ElectricityUom>;
  endDateTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["UUID"];
  localisation?: Maybe<Localisation>;
  name?: Maybe<Scalars["String"]>;
  /** The payment providers and payment methods supported by the Tenant */
  paymentProviders?: Maybe<Array<PaymentProviderV2>>;
  startDateTime?: Maybe<Scalars["DateTime"]>;
  tenantCode?: Maybe<Scalars["String"]>;
};

export enum TenantFeature {
  Lem = "LEM",
  Tracex = "TRACEX",
  Transactive = "TRANSACTIVE",
}

export type TenantInput = {
  displayName?: InputMaybe<Scalars["String"]>;
  electricityUom: ElectricityUom;
  endDateTime?: InputMaybe<Scalars["DateTime"]>;
  localisationId: Scalars["UUID"];
  name: Scalars["String"];
  startDateTime: Scalars["DateTime"];
  tenantCode?: InputMaybe<Scalars["String"]>;
};

export type TenantV2 = {
  __typename?: "TenantV2";
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["DateTime"]>;
  features?: Maybe<Array<TenantFeature>>;
  id: Scalars["UUID"];
  /** Currently, the value of this will be UUID String user, or the Auditor Type i.e. 'PLATFORM' */
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["DateTime"]>;
  /** For localization. Example: en_au, en_us */
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type TimeOfUseUsage = {
  __typename?: "TimeOfUseUsage";
  name?: Maybe<TimeOfUseUsageType>;
  usage?: Maybe<Scalars["Int"]>;
};

export enum TimeOfUseUsageType {
  OffPeak = "OFF_PEAK",
  Peak = "PEAK",
  Shoulder = "SHOULDER",
}

export type TimePeriod = {
  __typename?: "TimePeriod";
  timeFrom?: Maybe<Scalars["LocalTime"]>;
  timeTo?: Maybe<Scalars["LocalTime"]>;
};

export type TimePeriodInput = {
  timeFrom?: InputMaybe<Scalars["LocalTime"]>;
  timeTo?: InputMaybe<Scalars["LocalTime"]>;
};

export type TopTraderInfo = {
  __typename?: "TopTraderInfo";
  amount: Scalars["BigDecimal"];
  description: Scalars["String"];
};

export type TradeAttributes = RecAttributes;

export type TradeContract = {
  __typename?: "TradeContract";
  /** Account that placed the trade contract */
  account: Account;
  /** Type of the asset */
  assetType: AssetType;
  /** Attributes of the trade */
  attributes: TradeAttributes;
  /** Account that accepted the trade contract */
  counterAccount?: Maybe<Account>;
  /** Date on which trade was created */
  createdDate: Scalars["DateTime"];
  /** Currency code of the unit price */
  currencyCode: CurrencyCode;
  id: Scalars["UUID"];
  /** Posting of the trade */
  position: TradePosition;
  /** Code of registry the trade contract is related to */
  registryCode: RegistryCode;
  /** Current state of trade contract */
  status: TradeContractStatus;
  /** Message explaining status of the trade contract */
  statusMessage?: Maybe<Scalars["String"]>;
  /** Tenant of the entity */
  tenantId: Scalars["UUID"];
  /** User friendly reference number for the trade contract */
  tradeContractNumber: Scalars["String"];
  /** Date on which trade will be settled */
  tradeSettlementDate: Scalars["Date"];
  /** Type of the trade */
  tradeType: TradeType;
  /** Unit Price for the trade contract */
  unitPrice: Scalars["Long"];
  updatedOn?: Maybe<Scalars["DateTime"]>;
  /** Volume of the trade contract */
  volume: Scalars["Int"];
};

/** Action that can be taken by self or counter party on a Trade contract  */
export enum TradeContractAction {
  /** CounterParty -> Accept the trade contract */
  Accept = "ACCEPT",
  /** Self -> Withdraw the trade contract */
  Withdraw = "WITHDRAW",
}

export type TradeContractAsset = {
  __typename?: "TradeContractAsset";
  /** Type of the asset */
  assetType: AssetType;
  /** Attributes of the asset */
  attributes: TradeAttributes;
  id: Scalars["UUID"];
  /** Id of the match of the trade contracts */
  matchedContractId: Scalars["UUID"];
  /** Current state of asset */
  status: TradeContractAssetStatus;
  statusMessage?: Maybe<Scalars["String"]>;
  tenantId: Scalars["UUID"];
  /** Id of the trade contract the asset belongs to */
  tradeContractId: Scalars["UUID"];
  /** Volume of asset */
  volume: Scalars["Int"];
};

export enum TradeContractAssetStatus {
  Failed = "FAILED",
  Locked = "LOCKED",
  Locking = "LOCKING",
  LockFailed = "LOCK_FAILED",
  Pending = "PENDING",
  Transferred = "TRANSFERRED",
  Transferring = "TRANSFERRING",
}

/** Filter criteria for Trade contract history Query */
export type TradeContractHistoryFilter = {
  position?: InputMaybe<TradePosition>;
  recAttributes?: InputMaybe<RecAttributeFilter>;
  statuses?: InputMaybe<Array<InputMaybe<TradeContractStatus>>>;
  tradeSettlementDate?: InputMaybe<Scalars["Date"]>;
};

/** Input for trade contract history query */
export type TradeContractHistoryInput = {
  /** Pagination input */
  paginationInput: PaginationInput;
  /** Sort orders */
  sortOrderInputs?: InputMaybe<Array<SortOrderInput>>;
  /** Filter for history query */
  where: TradeContractHistoryFilter;
};

/** Trade contract Input */
export type TradeContractInput = {
  /** Type of the asset */
  assetType: AssetType;
  /** Currency code of the unit price */
  currencyCode: CurrencyCode;
  /** Posting of the trade */
  position: TradePosition;
  /** Code of registry the trade contract is related to */
  registryCode: RegistryCode;
  /** Date on which the trade will be settled */
  tradeSettlementDate: Scalars["Date"];
  /** Type of the trade */
  tradeType: TradeType;
  /** Unit Price for the trade contract */
  unitPrice: Scalars["Long"];
  /** Total volume to be traded */
  volume: Scalars["Int"];
};

/** Filter criteria for Trade contract market Query */
export type TradeContractMarketFilter = {
  position?: InputMaybe<TradePosition>;
  recAttributes?: InputMaybe<RecAttributeFilter>;
  tradeSettlementDate?: InputMaybe<Scalars["Date"]>;
};

/** Input for market query */
export type TradeContractMarketQueryInput = {
  /** Pagination input */
  paginationInput: PaginationInput;
  /** Sort orders */
  sortOrderInputs?: InputMaybe<Array<SortOrderInput>>;
  /** Filter for market query */
  where: TradeContractMarketFilter;
};

export type TradeContractResponse = {
  __typename?: "TradeContractResponse";
  /** Pagination info */
  offsetInfo: PageDetail;
  /** Trade Contracts */
  tradeContracts: Array<TradeContract>;
};

export enum TradeContractStatus {
  /** Asset is loaded for the ask trade contract */
  AssetsLoaded = "ASSETS_LOADED",
  /** The trade contract completed processing */
  Completed = "COMPLETED",
  /** The trade contract is accepted by admin */
  Contracted = "CONTRACTED",
  /** The trade contract failed processing */
  Failed = "FAILED",
  /** The trade contract is open for acceptance */
  Open = "OPEN",
  /** The trade contract is being processed */
  Pending = "PENDING",
  /** The trade contract is being settled */
  PendingComplete = "PENDING_COMPLETE",
  /** The trade contract is being reviewed by admin */
  PendingReview = "PENDING_REVIEW",
  /** The trade contract is rejected by admin */
  Rejected = "REJECTED",
  /** The trade contract is withdrawn from the market */
  Withdrawn = "WITHDRAWN",
}

/** Represents Trade contract in a terminal fulfilled state along with details about its 'fills'. */
export type TradeContractTransactionSummary = {
  __typename?: "TradeContractTransactionSummary";
  /** Original trade contract information placed by the Trader */
  tradeContract: TradeContract;
  /** Represents a match and subsequent settlement between two trade contracts. */
  tradeTransactions: Array<TradeTransaction>;
};

export enum TradePosition {
  Ask = "ASK",
  Bid = "BID",
}

/** Trade report details for each member account */
export type TradeReportDetail = {
  __typename?: "TradeReportDetail";
  /** Trading account detail */
  account: Account;
  /** Amount spent in payments */
  paidAmount: Money;
  /** Amount received in payments */
  receivedAmount: Money;
  /** Total Fees paid to seller */
  totalBuyerFees: Money;
  /** Total GST on buyer Fees */
  totalBuyerFeesGST: Money;
  /** Total Fees received by seller */
  totalSellerFees: Money;
  /** Total GST on Seller Fees */
  totalSellerFeesGST: Money;
  /** Total Trade GST Paid */
  totalTradeGSTPaid: Money;
  /** Total Trade GST Received */
  totalTradeGSTReceived: Money;
  /** CCs successfully traded in the market (ASK) */
  tradedAskVolume: Scalars["Long"];
  /** CCs successfully traded in the market (BID) */
  tradedBidVolume: Scalars["Long"];
};

/** Filter inputs to generate trade report for */
export type TradeReportInput = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
};

/** Trade Report response */
export type TradeReportResponse = {
  __typename?: "TradeReportResponse";
  /** End date for the trade report */
  endDate: Scalars["Date"];
  /** Start date for the trade report */
  startDate: Scalars["Date"];
  /** Total CCs successfully traded within the given period */
  totalTradedVolume: Scalars["Long"];
  /** trade report details for each member account */
  tradeReportDetails: Array<TradeReportDetail>;
};

export type TradeSettlementInfo = {
  __typename?: "TradeSettlementInfo";
  endDateTime: Scalars["DateTime"];
  settlementId: Scalars["UUID"];
  startDateTime: Scalars["DateTime"];
};

/**
 * Represents a match and subsequent settlement between two orders.
 * Used to generate the transaction summary.
 */
export type TradeTransaction = {
  __typename?: "TradeTransaction";
  /** Type of asset traded */
  assetType: AssetType;
  /** Date the trade was settled */
  completedDate: Scalars["DateTime"];
  currencyCode: CurrencyCode;
  /** Date of match between trades */
  matchedDate: Scalars["DateTime"];
  /** Type of the trade */
  position: TradePosition;
  transactionDetails: Array<TradeTransactionDetails>;
  transactionFee: Scalars["Long"];
  /** Addenda, additional transaction details to help customers match transactions with their bank records */
  transactionNumber?: Maybe<Scalars["String"]>;
  unitPrice: Scalars["Long"];
  /** Quantity of asset traded */
  volume: Scalars["Int"];
};

export type TradeTransactionDetails = {
  __typename?: "TradeTransactionDetails";
  /** Attributes of the traded assets */
  attributes: TradeAttributes;
  commodityGenerationSummary?: Maybe<Array<CommodityGenerationSummary>>;
  generator?: Maybe<Generator>;
  id?: Maybe<Scalars["UUID"]>;
  trackingSystem: Scalars["String"];
  /** Quantity of the traded assets */
  volume: Scalars["Int"];
};

export enum TradeType {
  Forward = "FORWARD",
  Offer = "OFFER",
  Spot = "SPOT",
}

/** The entity representing a trading account user in the system. */
export type TradingAccountUser = {
  __typename?: "TradingAccountUser";
  /** Email of the user */
  email: Scalars["String"];
  /** First name of the user */
  firstName?: Maybe<Scalars["String"]>;
  /** Id of the user */
  id: Scalars["UUID"];
  /** Boolean which determines if the user is account owner or not */
  isAccountOwner?: Maybe<Scalars["Boolean"]>;
  /** Last name of the user */
  lastName?: Maybe<Scalars["String"]>;
  /** Position of the user */
  position?: Maybe<Scalars["String"]>;
  /** Current status of the user */
  status: TradingAccountUserStatus;
  /** User type of the user */
  tradingAccountUserType: TradingAccountUserType;
};

/** All the possible status of a trading account user */
export enum TradingAccountUserStatus {
  /** User have accepted all the policies and is enabled to trade */
  Active = "ACTIVE",
  /** The user is still on-boarding and requires accepting policies  */
  Inactive = "INACTIVE",
  /** The user is now removed */
  Removed = "REMOVED",
}

/** All the possible type of the trading account user */
export enum TradingAccountUserType {
  /** Admin user type */
  Admin = "ADMIN",
  /** Normal user type */
  User = "USER",
}

export type TradingGroup = {
  __typename?: "TradingGroup";
  applicationHostId: Scalars["UUID"];
  applicationHostName: Scalars["String"];
  co2EmissionsIntensity: Array<EmissionsIntensityTuple>;
  dateRange?: Maybe<DateRange>;
  features?: Maybe<Array<TenantFeature>>;
  id: Scalars["UUID"];
  minNumMeters?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  notificationConfig?: Maybe<NotificationConfig>;
  readingsConfig: Array<ReadingsConfig>;
  runBalanceCheck?: Maybe<Scalars["Boolean"]>;
  settlementIntervalInSeconds?: Maybe<Scalars["Int"]>;
  tenantId: Scalars["UUID"];
  tenantName: Scalars["String"];
  timeZone?: Maybe<Scalars["String"]>;
  vendor: Scalars["String"];
  writeToBlockchain?: Maybe<Scalars["Boolean"]>;
};

/**
 * This shows a trade summary concentrating on the total
 * amount with and without LEM (Business-As-Usual) cases
 * in a settlement which is usually 24-hour.
 *
 * It also contains the data for each settlement period
 * to allow changes to be plotted over time for the entire
 * settlement.
 */
export type TradingGroupComparisonSummary = {
  __typename?: "TradingGroupComparisonSummary";
  bessProsumerAverageSavings: Scalars["BigDecimal"];
  bessProsumerTotalAmount: Scalars["BigDecimal"];
  bessProsumerTotalAmountBAU: Scalars["BigDecimal"];
  bessProsumerTotalSavings: Scalars["BigDecimal"];
  consumerAverageSavings: Scalars["BigDecimal"];
  consumerTotalAmount: Scalars["BigDecimal"];
  consumerTotalAmountBAU: Scalars["BigDecimal"];
  consumerTotalSavings: Scalars["BigDecimal"];
  prosumerAverageSavings: Scalars["BigDecimal"];
  prosumerTotalAmount: Scalars["BigDecimal"];
  prosumerTotalAmountBAU: Scalars["BigDecimal"];
  prosumerTotalSavings: Scalars["BigDecimal"];
  totalGridExport: Scalars["BigDecimal"];
  totalGridExportBAU: Scalars["BigDecimal"];
  totalGridImport: Scalars["BigDecimal"];
  totalGridImportBAU: Scalars["BigDecimal"];
  tradesByPeriod?: Maybe<Array<SettlementPeriodTrade>>;
};

export type TradingGroupFilter = {
  applicationHostName?: InputMaybe<Scalars["String"]>;
  names?: InputMaybe<StringSearchFilter>;
  tenantName?: InputMaybe<Scalars["String"]>;
};

export type TradingGroupInput = {
  applicationHostName: Scalars["String"];
  co2EmissionsIntensity?: InputMaybe<Array<EmissionsIntensityTupleMutationInput>>;
  features: Array<TenantFeature>;
  minNumMeters?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  notificationConfig?: InputMaybe<NotificationConfigMutationInput>;
  readingsConfig: Array<ReadingsConfigMutationInput>;
  runBalanceCheck?: InputMaybe<Scalars["Boolean"]>;
  settlementIntervalInSeconds: Scalars["Int"];
  tenantName: Scalars["String"];
  timeZone: Scalars["String"];
  vendor: Scalars["String"];
  writeToBlockchain?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * A trade summary of a settlement for a trading group
 * usually over a 24-hour interval.
 */
export type TradingGroupTradeSummary = {
  __typename?: "TradingGroupTradeSummary";
  averageSavings: Scalars["BigDecimal"];
  endDateTime: Scalars["DateTime"];
  gridBuyPrice: Scalars["BigDecimal"];
  gridEnergyBought: Scalars["BigDecimal"];
  gridEnergySold: Scalars["BigDecimal"];
  gridSellPrice: Scalars["BigDecimal"];
  numCommunityBatteries: Scalars["Int"];
  numCorporateIndustryBessProsumers: Scalars["Int"];
  numCorporateIndustryConsumers: Scalars["Int"];
  numCorporateIndustryProsumers: Scalars["Int"];
  numRetailBessProsumers: Scalars["Int"];
  numRetailConsumers: Scalars["Int"];
  numRetailProsumers: Scalars["Int"];
  p2pBuyPrice: Scalars["BigDecimal"];
  p2pEnergyBought: Scalars["BigDecimal"];
  p2pEnergySold: Scalars["BigDecimal"];
  p2pSellPrice: Scalars["BigDecimal"];
  selfConsumptionPercentage: Scalars["BigDecimal"];
  selfSufficiencyPercentage: Scalars["BigDecimal"];
  settlementId: Scalars["UUID"];
  startDateTime: Scalars["DateTime"];
  topBuyers?: Maybe<Array<TopTraderInfo>>;
  topSellers?: Maybe<Array<TopTraderInfo>>;
  totalSavings: Scalars["BigDecimal"];
};

export type TradingGroupTradeSummaryFilter = {
  applicationHostId: Scalars["UUID"];
  settlementId?: InputMaybe<Scalars["UUID"]>;
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
};

export type TradingHalt = {
  __typename?: "TradingHalt";
  endDateTime: Scalars["DateTime"];
  id: Scalars["UUID"];
  isApplicable: Scalars["Boolean"];
  isPlanned: Scalars["Boolean"];
  startDateTime: Scalars["DateTime"];
  tenantId: Scalars["UUID"];
};

export type TradingHaltInput = {
  /**
   * Optional end date time for the trading halt. Defaults to NOW + 4 hours if null
   * or if this is before or equal to start date time
   */
  endDateTime?: InputMaybe<Scalars["DateTime"]>;
  /** Optional to activate or deactivate the trading halt. Defaults to TRUE */
  isApplicable?: InputMaybe<Scalars["Boolean"]>;
  /** Optional flag to indicate whether this is a planned event or not. Defaults to FALSE */
  isPlanned?: InputMaybe<Scalars["Boolean"]>;
  /** Optional start date time for the trading halt. Defaults to NOW if null. */
  startDateTime?: InputMaybe<Scalars["DateTime"]>;
  tenantId: Scalars["UUID"];
};

/** Trading summary for the given period of time for calculating the Rebates */
export type TradingSummary = {
  __typename?: "TradingSummary";
  /** End date for the trading summary report */
  endDate: Scalars["Date"];
  /** Start date for the trading summary report */
  startDate: Scalars["Date"];
  /** Total RECs placed for sale within the given period */
  totalAskVolume: Scalars["Long"];
  /** Total RECs successfully traded within the given period */
  totalTradedVolume: Scalars["Long"];
  /** Trading summary details for each trading account */
  tradingSummaryDetails?: Maybe<Array<Maybe<TradingSummaryDetail>>>;
};

/** Trading summary details for each trading account */
export type TradingSummaryDetail = {
  __typename?: "TradingSummaryDetail";
  /** Trading account detail */
  account: Account;
  /** Amount spent in payments */
  paidAmount: Money;
  /** Amount received in payments */
  receivedAmount: Money;
  /** RECs provided to the market (ASK) */
  totalAskVolume: Scalars["Long"];
  /** RECs provided to the market (BID) */
  totalBidVolume: Scalars["Long"];
  /** Total transaction fees paid by the trading account (bid transaction fee + ask transaction fee) */
  totalFees: Money;
  /** RECs successfully traded in the market (ASK) */
  tradedAskVolume: Scalars["Long"];
  /** RECs successfully traded in the market (BID) */
  tradedBidVolume: Scalars["Long"];
};

export type TradingSummaryInput = {
  /** End date for the rebate report */
  endDate: Scalars["Date"];
  /** Start date for the rebate report */
  startDate: Scalars["Date"];
};

/**
 * Represents a match and subsequent settlement between two orders.
 * Used to generate the transaction summary.
 */
export type Transaction = {
  __typename?: "Transaction";
  buyersDetails: TransactionDetails;
  commodityGenerationSummary?: Maybe<Array<Maybe<CommodityGenerationSummary>>>;
  deliveryDate: Scalars["DateTime"];
  generator?: Maybe<Generator>;
  id: Scalars["UUID"];
  order: Order;
  /** @deprecated use generator.name */
  project: Scalars["String"];
  quantity: Scalars["Int"];
  sellersDetails: TransactionDetails;
  trackingSystem: Scalars["String"];
};

export enum TransactionCategory {
  Forward = "FORWARD",
  Settlement = "SETTLEMENT",
}

export type TransactionDetails = {
  __typename?: "TransactionDetails";
  executedPrice: Money;
  fee: Money;
  id: Scalars["UUID"];
  partyType: PartyType;
  totalContractPrice: Money;
};

export type TransactionFee = {
  __typename?: "TransactionFee";
  /** Asset type for transaction fee */
  assetType: AssetType;
  /** Transaction fee percentage for buyer */
  buyerTxnFeePercent: Scalars["Float"];
  /** Transaction fee id */
  id: Scalars["UUID"];
  /** Minimum transaction fee for buyer */
  minimumBuyerFee: Scalars["Long"];
  /** Minimum transaction fee for seller */
  minimumSellerFee: Scalars["Long"];
  /** Transaction fee percentage for seller */
  sellerTxnFeePercent: Scalars["Float"];
  /** Tax percentage for transaction  */
  taxPercentage?: Maybe<Scalars["Float"]>;
  /** Tenant id for transaction fee */
  tenantId: Scalars["UUID"];
};

/** Input for transaction fee */
export type TransactionFeeInput = {
  /** Type of asset */
  assetType: AssetType;
  registryCode?: InputMaybe<RegistryCode>;
};

export type TransactionFilter = {
  applicationHostId: Scalars["UUID"];
  generatorType?: InputMaybe<GeneratorType>;
  meterType?: InputMaybe<MeterType>;
  ruleType?: InputMaybe<RuleType>;
  tenantId: Scalars["UUID"];
  tradingGroupId: Scalars["UUID"];
  transactionCategory?: InputMaybe<TransactionCategory>;
  transactionEndDateTime?: InputMaybe<Scalars["DateTime"]>;
  transactionStartDateTime?: InputMaybe<Scalars["DateTime"]>;
  transactionType?: InputMaybe<TransactionType>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** Represents an order in a terminal fulfilled state along with details about its 'fills'. */
export type TransactionSummary = {
  __typename?: "TransactionSummary";
  order: Order;
  transactions: Array<Transaction>;
};

export enum TransactionType {
  Adjustment = "ADJUSTMENT",
  Credit = "CREDIT",
  Debit = "DEBIT",
  Refund = "REFUND",
}

export type UpdateApprovalStateInput = {
  /** Account whose approval status is to be updated */
  accountId: Scalars["UUID"];
  /** Approval state entity type */
  entityType: EntityType;
  /** Human readable error message to aid developers in debugging */
  errorMessage?: InputMaybe<Scalars["String"]>;
  /** Approval state id */
  id: Scalars["UUID"];
  /** Approval state status */
  status: ApprovalStatus;
};

export type UpdateApprovalStateResponse = {
  __typename?: "UpdateApprovalStateResponse";
  approvalState: ApprovalState;
};

/** Update Clearing Record's status */
export type UpdateClearingRecordStatusInput = {
  /** Clearing Record's id. */
  id: Scalars["UUID"];
  /** New status that we want Clearing Record to be in. */
  status: ClearingRecordStatus;
};

export type UpdateClearingRecordStatusResponse = {
  __typename?: "UpdateClearingRecordStatusResponse";
  clearingRecord: ClearingRecord;
};

/** Input for updating matched trade contract */
export type UpdateMatchedTradeContractInput = {
  /** Matched trade contract id */
  id: Scalars["UUID"];
  /** Status of matched trade contract */
  status: MatchedTradeContractStatus;
  /** Status message */
  statusMessage?: InputMaybe<Scalars["String"]>;
};

export type UpdateMatchedTradeContractResponse = {
  __typename?: "UpdateMatchedTradeContractResponse";
  /** The id of the matched trade contract that was updated */
  id: Scalars["UUID"];
  /** Whether the update is successful. False if there are errors */
  status: Scalars["Boolean"];
  /** A message on the outcome of the update. Can be an error message */
  statusMessage?: Maybe<Scalars["String"]>;
};

export type UpdateOwnUserInput = {
  acceptedTermsAndConditions?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<UserStatus>;
  title?: InputMaybe<Scalars["String"]>;
};

/** Mutation input data to update Tenant */
export type UpdateTenantInput = {
  /** For localization. Example values: en_AU, en_US, it_IT */
  location?: InputMaybe<Scalars["String"]>;
  /** Tenant name (unique) */
  name: Scalars["String"];
  /** Tenant id */
  tenantId: Scalars["UUID"];
};

export type UpdateTradingGroupInput = {
  co2EmissionsIntensity?: InputMaybe<Array<EmissionsIntensityTupleMutationInput>>;
  features?: InputMaybe<Array<TenantFeature>>;
  minNumMeters?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  notificationConfig?: InputMaybe<NotificationConfigMutationInput>;
  readingsConfig?: InputMaybe<Array<ReadingsConfigMutationInput>>;
  runBalanceCheck?: InputMaybe<Scalars["Boolean"]>;
  settlementIntervalInSeconds?: InputMaybe<Scalars["Int"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  vendor?: InputMaybe<Scalars["String"]>;
  writeToBlockchain?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateUserInput = {
  acceptedTermsAndConditions?: InputMaybe<Scalars["Boolean"]>;
  applicationHostId: Scalars["UUID"];
  applicationHostName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  roles?: InputMaybe<Array<InputMaybe<UserRole>>>;
  status?: InputMaybe<UserStatus>;
  tenantId: Scalars["UUID"];
  tenantName?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  tradingGroupId: Scalars["UUID"];
  tradingGroupName?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  acceptedTermsAndConditions?: Maybe<Scalars["Boolean"]>;
  applicationHostId?: Maybe<Scalars["UUID"]>;
  applicationHostName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["UUID"]>;
  identityProviderRef?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  roles?: Maybe<Array<UserRole>>;
  status: UserStatus;
  tenant?: Maybe<TenantV2>;
  tenantId?: Maybe<Scalars["UUID"]>;
  tenantName?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  tradingGroupId?: Maybe<Scalars["UUID"]>;
  tradingGroupName?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type UserAccess = {
  __typename?: "UserAccess";
  /** Indicates if user has accepted terms and conditions */
  acceptedTermsAndConditions?: Maybe<Scalars["Boolean"]>;
  /** Granted Authority of current authenticated user */
  grants?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** URL of the pages that current authenticated user has access to */
  urls?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo?: Maybe<PaginationInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type UserEdge = {
  __typename?: "UserEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<User>;
};

export type UserFilter = {
  applicationHostId?: InputMaybe<Scalars["UUID"]>;
  orderBy?: InputMaybe<UserFilterOrder>;
  tenantId?: InputMaybe<Scalars["UUID"]>;
  tradingGroupId?: InputMaybe<Scalars["UUID"]>;
  userStatusIn?: InputMaybe<Array<InputMaybe<UserStatus>>>;
  usernames?: InputMaybe<UsernameSearchFilter>;
};

export type UserFilterOrder = {
  username?: InputMaybe<SortDirection>;
};

export type UserInput = {
  acceptedTermsAndConditions?: InputMaybe<Scalars["Boolean"]>;
  applicationHostId: Scalars["UUID"];
  applicationHostName?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  identityProviderRef?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  roles: Array<UserRole>;
  status: UserStatus;
  tenantId: Scalars["UUID"];
  tenantName: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
  tradingGroupId: Scalars["UUID"];
  tradingGroupName: Scalars["String"];
  userId?: InputMaybe<Scalars["UUID"]>;
  username: Scalars["String"];
};

export type UserResult = {
  __typename?: "UserResult";
  userErrors?: Maybe<Array<Maybe<CreateUserError>>>;
  users: Array<Maybe<User>>;
};

export enum UserRole {
  AppHostAdmin = "AppHostAdmin",
  CorporateUser = "CorporateUser",
  Operator = "Operator",
  ParticipantUser = "ParticipantUser",
  PlatformAdmin = "PlatformAdmin",
  RetailerUser = "RetailerUser",
  TenantAdmin = "TenantAdmin",
}

export enum UserStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  New = "NEW",
  Unverified = "UNVERIFIED",
  Verified = "VERIFIED",
}

export type UserTariff = {
  __typename?: "UserTariff";
  applicationHostId?: Maybe<Scalars["UUID"]>;
  gridMeterId?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  isDefaultTariff?: Maybe<Scalars["Boolean"]>;
  meterIds?: Maybe<Array<Scalars["UUID"]>>;
  tariffs?: Maybe<Array<Tariff>>;
  tenantId?: Maybe<Scalars["UUID"]>;
  timeZone?: Maybe<Scalars["String"]>;
  tradingGroupId?: Maybe<Scalars["UUID"]>;
  userId: Scalars["UUID"];
  validFrom?: Maybe<Scalars["DateTime"]>;
  validTo?: Maybe<Scalars["DateTime"]>;
};

export type UserTariffFilter = {
  active?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

export type UserTariffInput = {
  applicationHostId?: InputMaybe<Scalars["UUID"]>;
  gridMeterId?: InputMaybe<Scalars["UUID"]>;
  tariffs?: InputMaybe<Array<TariffInput>>;
  tenantId?: InputMaybe<Scalars["UUID"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  tradingGroupId?: InputMaybe<Scalars["UUID"]>;
  userId: Scalars["UUID"];
};

export type UsernameSearchFilter = {
  contain?: InputMaybe<Scalars["String"]>;
  startWith?: InputMaybe<Scalars["String"]>;
};

export type VerifyFactor = {
  factor: Factor;
  verifyString: Scalars["String"];
};

/**
 * Represents the half of the year that the commodity was generated
 * Jan-Jun = FRONT_HALF , Jul-Dec = BACK_HALF
 */
export enum VintageHalf {
  BackHalf = "BACK_HALF",
  FrontHalf = "FRONT_HALF",
}

export type CommodityTransactionFieldsFragment = {
  __typename?: "CommodityTransaction";
  id: any;
  status: CommodityTransactionStatus;
  statusMessage?: string | null | undefined;
  volume: any;
  generator: {
    __typename?: "Generator";
    id: any;
    crsEffectiveDate?: string | null | undefined;
    crsExpiryDate?: string | null | undefined;
  };
  commoditySerial: { __typename?: "CommoditySerial"; serialPrefix: string; serialFrom: any; serialTo: any };
};

export type RecOrderAttributesFieldsFragment = {
  __typename?: "RecOrderAttributes";
  vintages?: Array<string> | null | undefined;
  locations?: Array<string> | null | undefined;
  fuelSources?: Array<string> | null | undefined;
  eligibilities?: Array<string> | null | undefined;
  projects?: Array<string> | null | undefined;
  certifications?: Array<string> | null | undefined;
  commencementDate?: string | null | undefined;
};

export type RecAssetAttributesFieldsFragment = {
  __typename?: "RecAssetAttributes";
  vintage: number;
  vintageHalf?: VintageHalf | null | undefined;
  location: string;
  fuelSources?: Array<string> | null | undefined;
  eligibilities?: Array<string> | null | undefined;
  project?: string | null | undefined;
  certifications?: Array<string> | null | undefined;
  commencementDate?: string | null | undefined;
};

export type RetireRequestDetailsFieldsFragment = {
  __typename?: "RetireCommodityDetail";
  id: any;
  volume: any;
  status: RetirementStatus;
  statusMessage?: string | null | undefined;
  attributes:
    | { __typename?: "CCAssetAttributes" }
    | {
        __typename?: "RecAssetAttributes";
        vintage: number;
        vintageHalf?: VintageHalf | null | undefined;
        location: string;
        fuelSources?: Array<string> | null | undefined;
        eligibilities?: Array<string> | null | undefined;
        project?: string | null | undefined;
        certifications?: Array<string> | null | undefined;
        commencementDate?: string | null | undefined;
      };
};

export type ExportRequestDetailsFieldsFragment = {
  __typename?: "ExportRequestDetail";
  id: any;
  volume: any;
  status: ExportRequestStatus;
  statusMessage?: string | null | undefined;
  attributes:
    | { __typename?: "CCAssetAttributes" }
    | {
        __typename?: "RecAssetAttributes";
        vintage: number;
        vintageHalf?: VintageHalf | null | undefined;
        location: string;
        fuelSources?: Array<string> | null | undefined;
        eligibilities?: Array<string> | null | undefined;
        project?: string | null | undefined;
        certifications?: Array<string> | null | undefined;
        commencementDate?: string | null | undefined;
      };
};

export type LinkedRegistryFieldsFragment = {
  __typename?: "LinkedRegistry";
  id: any;
  registryId: any;
  status: RegistryLinkStatus;
  accountType?: AccountType | null | undefined;
  registryCode?: RegistryCode | null | undefined;
  registryOrgLinkId?: string | null | undefined;
};

export type LinkedPaymentFieldsFragment = {
  __typename?: "LinkedPaymentMethod";
  id: any;
  status: PaymentMethodLinkStatus;
  statusMessage?: string | null | undefined;
  paymentMethod: {
    __typename?: "PaymentMethod";
    id: any;
    code: PaymentMethodCode;
    displayName: string;
    description: string;
    paymentProvider: {
      __typename?: "PaymentProviderV2";
      description: string;
      displayName: string;
      id: any;
      name: string;
    };
  };
  fundingSource?:
    | {
        __typename?: "BankAccountV2";
        id: any;
        currencyCode?: CurrencyCode | null | undefined;
        status: FundingSourceStatus;
        statusMessage?: string | null | undefined;
        errorCode?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ApprovalStateFieldsFragment = {
  __typename?: "ApprovalState";
  id: any;
  status: ApprovalStatus;
  errorCode?: string | null | undefined;
  errorMessage?: string | null | undefined;
};

export type AddressFieldsFragment = {
  __typename?: "Address";
  id: any;
  line1?: string | null | undefined;
  line2?: string | null | undefined;
  city?: string | null | undefined;
  state?: string | null | undefined;
  postalCode?: string | null | undefined;
  countryCode?: string | null | undefined;
};

export type DocumentFieldsFragment = {
  __typename?: "Document";
  id: any;
  fileName?: string | null | undefined;
  type: DocumentType;
  mediaType?: string | null | undefined;
  size?: number | null | undefined;
  status?: DocumentStatus | null | undefined;
  errorMessage?: string | null | undefined;
  signedUrl?: string | null | undefined;
  approvalState?:
    | {
        __typename?: "ApprovalState";
        id: any;
        status: ApprovalStatus;
        errorCode?: string | null | undefined;
        errorMessage?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CompanyFieldsFragment = {
  __typename?: "Company";
  id: any;
  businessName?: string | null | undefined;
  businessType?: string | null | undefined;
  employerIdentificationNumber?: string | null | undefined;
  industryClassification?:
    | { __typename?: "IndustryClassification"; id: any; name: string; businessClassificationName: string }
    | null
    | undefined;
  physicalAddress?:
    | {
        __typename?: "Address";
        id: any;
        line1?: string | null | undefined;
        line2?: string | null | undefined;
        city?: string | null | undefined;
        state?: string | null | undefined;
        postalCode?: string | null | undefined;
        countryCode?: string | null | undefined;
      }
    | null
    | undefined;
  approvalState?:
    | {
        __typename?: "ApprovalState";
        id: any;
        status: ApprovalStatus;
        errorCode?: string | null | undefined;
        errorMessage?: string | null | undefined;
      }
    | null
    | undefined;
  documents?:
    | Array<{
        __typename?: "Document";
        id: any;
        fileName?: string | null | undefined;
        type: DocumentType;
        mediaType?: string | null | undefined;
        size?: number | null | undefined;
        status?: DocumentStatus | null | undefined;
        errorMessage?: string | null | undefined;
        signedUrl?: string | null | undefined;
        approvalState?:
          | {
              __typename?: "ApprovalState";
              id: any;
              status: ApprovalStatus;
              errorCode?: string | null | undefined;
              errorMessage?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  contacts?:
    | Array<{
        __typename?: "CompanyContact";
        id: any;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        jobTitle?: string | null | undefined;
        dateOfBirth?: string | null | undefined;
        identityNumber?: string | null | undefined;
        passportNumber?: string | null | undefined;
        passportCountryCode?: string | null | undefined;
        type?: ContactType | null | undefined;
        physicalAddress?:
          | {
              __typename?: "Address";
              id: any;
              line1?: string | null | undefined;
              line2?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              postalCode?: string | null | undefined;
              countryCode?: string | null | undefined;
            }
          | null
          | undefined;
        approvalState?:
          | {
              __typename?: "ApprovalState";
              id: any;
              status: ApprovalStatus;
              errorCode?: string | null | undefined;
              errorMessage?: string | null | undefined;
            }
          | null
          | undefined;
        documents?:
          | Array<{
              __typename?: "Document";
              id: any;
              fileName?: string | null | undefined;
              type: DocumentType;
              mediaType?: string | null | undefined;
              size?: number | null | undefined;
              status?: DocumentStatus | null | undefined;
              errorMessage?: string | null | undefined;
              signedUrl?: string | null | undefined;
              approvalState?:
                | {
                    __typename?: "ApprovalState";
                    id: any;
                    status: ApprovalStatus;
                    errorCode?: string | null | undefined;
                    errorMessage?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type RecTradeAttributesFieldsFragment = {
  __typename?: "RecAttributes";
  vintages?: Array<string> | null | undefined;
  locations?: Array<string> | null | undefined;
  fuelSources?: Array<string> | null | undefined;
  eligibilities?: Array<string> | null | undefined;
  projects?: Array<string> | null | undefined;
  certifications?: Array<string> | null | undefined;
  commencementDate?: string | null | undefined;
};

export type ExportCommoditiesByVolumeMutationVariables = Exact<{
  exportCommoditiesByVolumeInput: ExportCommoditiesByVolumeInput;
}>;

export type ExportCommoditiesByVolumeMutation = {
  __typename?: "Mutation";
  exportCommoditiesByVolume: { __typename?: "ExportRequest"; id: any };
};

export type RetireCommoditiesMutationVariables = Exact<{
  retireCommoditiesInput: RetireCommoditiesInput;
}>;

export type RetireCommoditiesMutation = {
  __typename?: "Mutation";
  retireCommodities: { __typename?: "RetireCommoditiesResult"; retirementId: any };
};

export type RemoveDocumentMutationVariables = Exact<{
  input: RemoveDocumentInput;
}>;

export type RemoveDocumentMutation = {
  __typename?: "Mutation";
  removeDocument: { __typename?: "RemoveDocumentResponse"; document: { __typename?: "Document"; id: any } };
};

export type ResubmitApplicationMutationVariables = Exact<{ [key: string]: never }>;

export type ResubmitApplicationMutation = {
  __typename?: "Mutation";
  resubmitApplication: {
    __typename?: "ResubmitApplicationResponse";
    account: { __typename?: "Account"; id: any; status: AccountStatus };
  };
};

export type EditTradingAccountUserTypeMutationVariables = Exact<{
  input: EditTradingAccountUserTypeInput;
}>;

export type EditTradingAccountUserTypeMutation = {
  __typename?: "Mutation";
  editTradingAccountUserType: {
    __typename?: "EditTradingAccountUserTypeResponse";
    tradingAccountUser?: { __typename?: "TradingAccountUser"; id: any } | null | undefined;
  };
};

export type AddTradingAccountUserMutationVariables = Exact<{
  input: AddTradingAccountUserInput;
}>;

export type AddTradingAccountUserMutation = {
  __typename?: "Mutation";
  addTradingAccountUser: {
    __typename?: "AddTradingAccountUserResponse";
    tradingAccountUser?:
      | {
          __typename?: "TradingAccountUser";
          id: any;
          email: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          tradingAccountUserType: TradingAccountUserType;
          position?: string | null | undefined;
          status: TradingAccountUserStatus;
          isAccountOwner?: boolean | null | undefined;
        }
      | null
      | undefined;
  };
};

export type RemoveTradingAccountUserMutationVariables = Exact<{
  input: RemoveTradingAccountUserInput;
}>;

export type RemoveTradingAccountUserMutation = {
  __typename?: "Mutation";
  removeTradingAccountUser: {
    __typename?: "RemoveTradingAccountUserResponse";
    tradingAccountUser?:
      | {
          __typename?: "TradingAccountUser";
          id: any;
          email: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          tradingAccountUserType: TradingAccountUserType;
          position?: string | null | undefined;
          status: TradingAccountUserStatus;
          isAccountOwner?: boolean | null | undefined;
        }
      | null
      | undefined;
  };
};

export type AcceptPoliciesMutationVariables = Exact<{
  input: AcceptPoliciesInput;
}>;

export type AcceptPoliciesMutation = {
  __typename?: "Mutation";
  acceptPolicies: {
    __typename?: "AcceptPoliciesResponse";
    account?:
      | {
          __typename?: "Account";
          termsAcceptedDate?: string | null | undefined;
          rulesAcceptedDate?: string | null | undefined;
          privacyPolicyAcceptedDate?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type AddFundingSourceMutationVariables = Exact<{
  addFundingSourceInput: AddFundingSourceInput;
}>;

export type AddFundingSourceMutation = {
  __typename?: "Mutation";
  addFundingSource?:
    | {
        __typename?: "AddFundingSourceResponse";
        linkedPaymentMethod?:
          | {
              __typename?: "LinkedPaymentMethod";
              id: any;
              status: PaymentMethodLinkStatus;
              statusMessage?: string | null | undefined;
              initialisingToken?: string | null | undefined;
              paymentMethod: {
                __typename?: "PaymentMethod";
                id: any;
                code: PaymentMethodCode;
                displayName: string;
                description: string;
                paymentProvider: {
                  __typename?: "PaymentProviderV2";
                  description: string;
                  displayName: string;
                  id: any;
                  name: string;
                };
              };
              fundingSource?:
                | {
                    __typename?: "BankAccountV2";
                    accountName?: string | null | undefined;
                    accountNumberMasked?: string | null | undefined;
                    accountType?: BankAccountType | null | undefined;
                    id: any;
                    currencyCode?: CurrencyCode | null | undefined;
                    status: FundingSourceStatus;
                    statusMessage?: string | null | undefined;
                    errorCode?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RemoveFundingSourceMutationVariables = Exact<{
  removeFundingSourceInput: RemoveFundingSourceInput;
}>;

export type RemoveFundingSourceMutation = {
  __typename?: "Mutation";
  removeFundingSource?:
    | {
        __typename?: "RemoveFundingSourceResponse";
        linkedPaymentMethod?:
          | {
              __typename?: "LinkedPaymentMethod";
              id: any;
              status: PaymentMethodLinkStatus;
              statusMessage?: string | null | undefined;
              initialisingToken?: string | null | undefined;
              paymentMethod: {
                __typename?: "PaymentMethod";
                id: any;
                code: PaymentMethodCode;
                displayName: string;
                description: string;
                paymentProvider: {
                  __typename?: "PaymentProviderV2";
                  description: string;
                  displayName: string;
                  id: any;
                  name: string;
                };
              };
              fundingSource?:
                | {
                    __typename?: "BankAccountV2";
                    accountName?: string | null | undefined;
                    accountNumberMasked?: string | null | undefined;
                    accountType?: BankAccountType | null | undefined;
                    id: any;
                    currencyCode?: CurrencyCode | null | undefined;
                    status: FundingSourceStatus;
                    statusMessage?: string | null | undefined;
                    errorCode?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type LinkPaymentMethodMutationVariables = Exact<{
  input: LinkPaymentMethodInput;
}>;

export type LinkPaymentMethodMutation = {
  __typename?: "Mutation";
  linkPaymentMethod?:
    | {
        __typename?: "LinkPaymentMethodResponse";
        linkedPaymentMethod?:
          | {
              __typename?: "LinkedPaymentMethod";
              id: any;
              status: PaymentMethodLinkStatus;
              statusMessage?: string | null | undefined;
              initialisingToken?: string | null | undefined;
              paymentMethod: {
                __typename?: "PaymentMethod";
                id: any;
                code: PaymentMethodCode;
                displayName: string;
                description: string;
                paymentProvider: {
                  __typename?: "PaymentProviderV2";
                  id: any;
                  name: string;
                  displayName: string;
                  description: string;
                };
              };
              fundingSource?:
                | {
                    __typename?: "BankAccountV2";
                    id: any;
                    currencyCode?: CurrencyCode | null | undefined;
                    errorCode?: string | null | undefined;
                    status: FundingSourceStatus;
                    statusMessage?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateCompanyMutationVariables = Exact<{
  company: CompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: "Mutation";
  updateCompany?:
    | {
        __typename?: "Company";
        id: any;
        businessName?: string | null | undefined;
        businessType?: string | null | undefined;
        employerIdentificationNumber?: string | null | undefined;
        physicalAddress?:
          | {
              __typename?: "Address";
              id: any;
              line1?: string | null | undefined;
              line2?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
              postalCode?: string | null | undefined;
              countryCode?: string | null | undefined;
            }
          | null
          | undefined;
        contacts?:
          | Array<{
              __typename?: "CompanyContact";
              id: any;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              jobTitle?: string | null | undefined;
              dateOfBirth?: string | null | undefined;
              identityNumber?: string | null | undefined;
              passportNumber?: string | null | undefined;
              passportCountryCode?: string | null | undefined;
              type?: ContactType | null | undefined;
              physicalAddress?:
                | {
                    __typename?: "Address";
                    id: any;
                    line1?: string | null | undefined;
                    line2?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    postalCode?: string | null | undefined;
                    countryCode?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AddCompanyContactMutationVariables = Exact<{
  companyContact: CompanyContactInput;
}>;

export type AddCompanyContactMutation = {
  __typename?: "Mutation";
  addCompanyContact: {
    __typename?: "CompanyContact";
    id: any;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    jobTitle?: string | null | undefined;
    dateOfBirth?: string | null | undefined;
    identityNumber?: string | null | undefined;
    passportNumber?: string | null | undefined;
    passportCountryCode?: string | null | undefined;
    type?: ContactType | null | undefined;
    physicalAddress?:
      | {
          __typename?: "Address";
          id: any;
          line1?: string | null | undefined;
          line2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          postalCode?: string | null | undefined;
          countryCode?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateCompanyContactMutationVariables = Exact<{
  companyContact: CompanyContactInput;
}>;

export type UpdateCompanyContactMutation = {
  __typename?: "Mutation";
  updateCompanyContact: {
    __typename?: "CompanyContact";
    id: any;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    jobTitle?: string | null | undefined;
    dateOfBirth?: string | null | undefined;
    identityNumber?: string | null | undefined;
    passportNumber?: string | null | undefined;
    passportCountryCode?: string | null | undefined;
    type?: ContactType | null | undefined;
    physicalAddress?:
      | {
          __typename?: "Address";
          id: any;
          line1?: string | null | undefined;
          line2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          postalCode?: string | null | undefined;
          countryCode?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type DeleteCompanyContactMutationVariables = Exact<{
  deleteCompanyContactId: Scalars["UUID"];
}>;

export type DeleteCompanyContactMutation = { __typename?: "Mutation"; deleteCompanyContact: any };

export type UpdateAccountDetailsMutationVariables = Exact<{
  accountDetails: AccountDetailsInput;
}>;

export type UpdateAccountDetailsMutation = {
  __typename?: "Mutation";
  updateAccountDetails: { __typename?: "Account"; id: any };
};

export type LinkRegistryMutationVariables = Exact<{
  registryLink: LinkRegistryInput;
}>;

export type LinkRegistryMutation = {
  __typename?: "Mutation";
  linkRegistry: { __typename?: "LinkedRegistry"; id: any; status: RegistryLinkStatus };
};

export type BeginDocumentUploadV2MutationVariables = Exact<{
  document: DocumentInputV2;
}>;

export type BeginDocumentUploadV2Mutation = {
  __typename?: "Mutation";
  beginDocumentUploadV2?:
    | { __typename?: "DocumentLocation"; id: any; url?: string | null | undefined; path?: string | null | undefined }
    | null
    | undefined;
};

export type EndDocumentUploadMutationVariables = Exact<{
  documentId: Scalars["UUID"];
}>;

export type EndDocumentUploadMutation = { __typename?: "Mutation"; endDocumentUpload?: boolean | null | undefined };

export type SendActivationEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendActivationEmailMutation = { __typename?: "Mutation"; sendActivationEmail?: boolean | null | undefined };

export type SubmitApplicationMutationVariables = Exact<{ [key: string]: never }>;

export type SubmitApplicationMutation = {
  __typename?: "Mutation";
  submitApplication: { __typename?: "Account"; id: any; status: AccountStatus };
};

export type EditUserPasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type EditUserPasswordMutation = { __typename?: "Mutation"; editUserPassword: string };

export type EnrollMutationVariables = Exact<{
  enroll: Factor;
}>;

export type EnrollMutation = {
  __typename?: "Mutation";
  enrollFactor: {
    __typename?: "EnrolledFactor";
    id: string;
    status: FactorStatus;
    sharedSecret?: string | null | undefined;
    qrCodeHref?: string | null | undefined;
  };
};

export type ActivateMutationVariables = Exact<{
  activate: ActivateFactor;
}>;

export type ActivateMutation = { __typename?: "Mutation"; activateFactor: FactorStatus };

export type ResetFactorMutationVariables = Exact<{
  reset: Factor;
}>;

export type ResetFactorMutation = { __typename?: "Mutation"; resetFactor: boolean };

export type CreateRecOrderMutationVariables = Exact<{
  order: OrderInput;
  attributes: RecOrderAttributeInput;
}>;

export type CreateRecOrderMutation = {
  __typename?: "Mutation";
  createRecOrder?: { __typename?: "Order"; id: any } | null | undefined;
};

export type CreateTradeContractMutationVariables = Exact<{
  input: CreateTradeContractInput;
}>;

export type CreateTradeContractMutation = {
  __typename?: "Mutation";
  createTradeContract: { __typename?: "TradeContract"; id: any };
};

export type ReviewTradeContractMutationVariables = Exact<{
  input: ReviewTradeContractInput;
}>;

export type ReviewTradeContractMutation = {
  __typename?: "Mutation";
  reviewTradeContract?: { __typename?: "TradeContract"; id: any } | null | undefined;
};

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars["UUID"];
}>;

export type CancelOrderMutation = {
  __typename?: "Mutation";
  cancelOrder?:
    | {
        __typename?: "Order";
        id: any;
        initialVolume: number;
        status: OrderStatus;
        tenantId: any;
        accountId: any;
        assetType: AssetType;
        position: OrderPosition;
        type: OrderType;
        currentVolume: number;
        completedOn?: string | null | undefined;
        createdOn?: string | null | undefined;
        updatedOn?: string | null | undefined;
        price: { __typename?: "Money"; currencyCode: CurrencyCode; amount: string };
        attributes:
          | { __typename?: "CCOrderAttributes" }
          | {
              __typename?: "RecOrderAttributes";
              vintage?: number | null | undefined;
              location?: string | null | undefined;
              fuelSources?: Array<string> | null | undefined;
              eligibilities?: Array<string> | null | undefined;
              certifications?: Array<string> | null | undefined;
            };
      }
    | null
    | undefined;
};

export type MarkAsReadMutationVariables = Exact<{
  markAsReadId: Scalars["String"];
}>;

export type MarkAsReadMutation = {
  __typename?: "Mutation";
  markAsRead?: { __typename?: "InboxMessage"; id: string; status: MessageStatus } | null | undefined;
};

export type CreateOfferMutationVariables = Exact<{
  input: CreateOfferInput;
}>;

export type CreateOfferMutation = {
  __typename?: "Mutation";
  createOffer: { __typename?: "Offer"; status: OfferStatus; statusMessage?: string | null | undefined };
};

export type ReviewOfferMutationVariables = Exact<{
  input: ReviewOfferInput;
}>;

export type ReviewOfferMutation = {
  __typename?: "Mutation";
  reviewOffer: { __typename?: "ReviewOfferResponse"; processing: boolean; message: string };
};

export type AssignAssetsMutationVariables = Exact<{
  input?: Maybe<AssetAssignmentInput>;
}>;

export type AssignAssetsMutation = {
  __typename?: "Mutation";
  assignAssetsToTradeContract?:
    | Array<{
        __typename?: "TradeContractAsset";
        status: TradeContractAssetStatus;
        statusMessage?: string | null | undefined;
        id: any;
      }>
    | null
    | undefined;
};

export type ExportSummaryQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ExportSummaryQuery = {
  __typename?: "Query";
  exportRequestById: {
    __typename?: "ExportRequest";
    id: any;
    exportNumber: string;
    requestedOn: string;
    completedOn?: string | null | undefined;
    totalVolume: any;
    status: ExportRequestStatus;
    statusMessage?: string | null | undefined;
    exportRequestDetails?:
      | Array<{
          __typename?: "ExportRequestDetail";
          id: any;
          volume: any;
          status: ExportRequestStatus;
          statusMessage?: string | null | undefined;
          commodityTransactions?:
            | Array<{
                __typename?: "CommodityTransaction";
                id: any;
                status: CommodityTransactionStatus;
                statusMessage?: string | null | undefined;
                volume: any;
                generator: {
                  __typename?: "Generator";
                  id: any;
                  crsEffectiveDate?: string | null | undefined;
                  crsExpiryDate?: string | null | undefined;
                };
                commoditySerial: {
                  __typename?: "CommoditySerial";
                  serialPrefix: string;
                  serialFrom: any;
                  serialTo: any;
                };
              }>
            | null
            | undefined;
          attributes:
            | { __typename?: "CCAssetAttributes" }
            | {
                __typename?: "RecAssetAttributes";
                vintage: number;
                vintageHalf?: VintageHalf | null | undefined;
                location: string;
                fuelSources?: Array<string> | null | undefined;
                eligibilities?: Array<string> | null | undefined;
                project?: string | null | undefined;
                certifications?: Array<string> | null | undefined;
                commencementDate?: string | null | undefined;
              };
        }>
      | null
      | undefined;
  };
};

export type ExportDetailsQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ExportDetailsQuery = {
  __typename?: "Query";
  exportRequestById: {
    __typename?: "ExportRequest";
    id: any;
    exportNumber: string;
    requestedOn: string;
    status: ExportRequestStatus;
    statusMessage?: string | null | undefined;
    totalVolume: any;
    exportRequestDetails?:
      | Array<{
          __typename?: "ExportRequestDetail";
          id: any;
          volume: any;
          status: ExportRequestStatus;
          statusMessage?: string | null | undefined;
          attributes:
            | { __typename?: "CCAssetAttributes" }
            | {
                __typename?: "RecAssetAttributes";
                vintage: number;
                vintageHalf?: VintageHalf | null | undefined;
                location: string;
                fuelSources?: Array<string> | null | undefined;
                eligibilities?: Array<string> | null | undefined;
                project?: string | null | undefined;
                certifications?: Array<string> | null | undefined;
                commencementDate?: string | null | undefined;
              };
        }>
      | null
      | undefined;
  };
};

export type ExportRequestsQueryVariables = Exact<{
  input: ExportRequestInput;
}>;

export type ExportRequestsQuery = {
  __typename?: "Query";
  exportRequests: {
    __typename?: "ExportRequestsResponse";
    exportRequests: Array<{
      __typename?: "ExportRequest";
      id: any;
      statusMessage?: string | null | undefined;
      totalVolume: any;
      requestedOn: string;
      exportNumber: string;
      status: ExportRequestStatus;
      initiatedBy: ActionInitiator;
    }>;
    offsetInfo: {
      __typename?: "RegistryServiceOffsetInfo";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
  };
};

export type RetirementDetailsQueryVariables = Exact<{
  id: Scalars["UUID"];
  registryCode?: Maybe<RegistryCode>;
}>;

export type RetirementDetailsQuery = {
  __typename?: "Query";
  retirementById: {
    __typename?: "Retirement";
    id: any;
    retirementNumber: string;
    requestedOn: string;
    retirementCategory: RetirementCategory;
    retirementType: string;
    retirementDetails: any;
    status: RetirementStatus;
    statusMessage?: string | null | undefined;
    totalVolume: any;
    assetType: AssetType;
    commodityDetails: Array<{
      __typename?: "RetireCommodityDetail";
      id: any;
      volume: any;
      status: RetirementStatus;
      statusMessage?: string | null | undefined;
      attributes:
        | { __typename?: "CCAssetAttributes" }
        | {
            __typename?: "RecAssetAttributes";
            vintage: number;
            vintageHalf?: VintageHalf | null | undefined;
            location: string;
            fuelSources?: Array<string> | null | undefined;
            eligibilities?: Array<string> | null | undefined;
            project?: string | null | undefined;
            certifications?: Array<string> | null | undefined;
            commencementDate?: string | null | undefined;
          };
    }>;
  };
};

export type RetirementHistoryQueryVariables = Exact<{
  input: RetirementHistoryInput;
}>;

export type RetirementHistoryQuery = {
  __typename?: "Query";
  retirementHistory: {
    __typename?: "RetirementHistoryResponse";
    offsetInfo: {
      __typename?: "RegistryServiceOffsetInfo";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
    retirements: Array<{
      __typename?: "Retirement";
      id: any;
      retirementNumber: string;
      requestedOn: string;
      retirementCategory: RetirementCategory;
      retirementType: string;
      retirementDetails: any;
      status: RetirementStatus;
      statusMessage?: string | null | undefined;
      assetType: AssetType;
      totalVolume: any;
    }>;
  };
};

export type RetirementSummaryQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type RetirementSummaryQuery = {
  __typename?: "Query";
  retirementById: {
    __typename?: "Retirement";
    id: any;
    accountId: any;
    retirementNumber: string;
    requestedOn: string;
    completedOn?: string | null | undefined;
    retirementCategory: RetirementCategory;
    retirementType: string;
    retirementDetails: any;
    totalVolume: any;
    status: RetirementStatus;
    statusMessage?: string | null | undefined;
    assetType: AssetType;
    commodityDetails: Array<{
      __typename?: "RetireCommodityDetail";
      id: any;
      volume: any;
      status: RetirementStatus;
      statusMessage?: string | null | undefined;
      commodityTransactions?:
        | Array<{
            __typename?: "CommodityTransaction";
            id: any;
            status: CommodityTransactionStatus;
            statusMessage?: string | null | undefined;
            volume: any;
            generator: {
              __typename?: "Generator";
              id: any;
              crsEffectiveDate?: string | null | undefined;
              crsExpiryDate?: string | null | undefined;
            };
            commoditySerial: { __typename?: "CommoditySerial"; serialPrefix: string; serialFrom: any; serialTo: any };
          }>
        | null
        | undefined;
      attributes:
        | { __typename?: "CCAssetAttributes" }
        | {
            __typename?: "RecAssetAttributes";
            vintage: number;
            vintageHalf?: VintageHalf | null | undefined;
            location: string;
            fuelSources?: Array<string> | null | undefined;
            eligibilities?: Array<string> | null | undefined;
            project?: string | null | undefined;
            certifications?: Array<string> | null | undefined;
            commencementDate?: string | null | undefined;
          };
    }>;
  };
};

export type TransactionFeeQueryVariables = Exact<{
  input: TransactionFeeInput;
}>;

export type TransactionFeeQuery = {
  __typename?: "Query";
  transactionFee: {
    __typename?: "TransactionFee";
    id: any;
    assetType: AssetType;
    buyerTxnFeePercent: number;
    sellerTxnFeePercent: number;
    minimumBuyerFee: any;
    minimumSellerFee: any;
    taxPercentage?: number | null | undefined;
  };
};

export type PaginatedHoldingsQueryVariables = Exact<{
  input?: Maybe<PaginatedHoldingsInput>;
}>;

export type PaginatedHoldingsQuery = {
  __typename?: "Query";
  paginatedHoldings: {
    __typename?: "PaginatedHoldingsResponse";
    holdings?:
      | Array<{
          __typename?: "Holding";
          id: any;
          availableBalance: number;
          currentBalance: number;
          project: string;
          attributes:
            | { __typename?: "CCAssetAttributes" }
            | {
                __typename?: "RecAssetAttributes";
                vintage: number;
                vintageHalf?: VintageHalf | null | undefined;
                location: string;
                fuelSources?: Array<string> | null | undefined;
                eligibilities?: Array<string> | null | undefined;
                project?: string | null | undefined;
                certifications?: Array<string> | null | undefined;
                commencementDate?: string | null | undefined;
              };
        }>
      | null
      | undefined;
    offsetInfo: {
      __typename?: "RegistryServiceOffsetInfo";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
  };
};

export type MarketOrdersV2QueryVariables = Exact<{
  input?: Maybe<MarketOrdersInput>;
}>;

export type MarketOrdersV2Query = {
  __typename?: "Query";
  marketOrdersV2: {
    __typename?: "MarketOrderResponse";
    data: { __typename?: "MarketOrderData"; totalAsks: any; totalBids: any };
    offsetInfo: {
      __typename?: "OrderBookOffsetInfo";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
    orders: Array<{
      __typename?: "Order";
      id: any;
      tenantId: any;
      accountId: any;
      assetType: AssetType;
      position: OrderPosition;
      status: OrderStatus;
      initialVolume: number;
      currentVolume: number;
      completedOn?: string | null | undefined;
      createdOn?: string | null | undefined;
      orderNumber: string;
      allowCounterOffers: boolean;
      canCounter?: boolean | null | undefined;
      type: OrderType;
      updatedOn?: string | null | undefined;
      price: { __typename?: "Money"; currencyCode: CurrencyCode; amount: string };
      attributes:
        | { __typename?: "CCOrderAttributes" }
        | {
            __typename?: "RecOrderAttributes";
            vintages?: Array<string> | null | undefined;
            locations?: Array<string> | null | undefined;
            fuelSources?: Array<string> | null | undefined;
            eligibilities?: Array<string> | null | undefined;
            projects?: Array<string> | null | undefined;
            certifications?: Array<string> | null | undefined;
            commencementDate?: string | null | undefined;
          };
    }>;
  };
};

export type TradeContractsQueryVariables = Exact<{
  input: TradeContractMarketQueryInput;
}>;

export type TradeContractsQuery = {
  __typename?: "Query";
  tradeContractMarketQuery: {
    __typename?: "TradeContractResponse";
    tradeContracts: Array<{
      __typename?: "TradeContract";
      id: any;
      position: TradePosition;
      volume: number;
      unitPrice: any;
      currencyCode: CurrencyCode;
      tradeSettlementDate: string;
      createdDate: string;
      account: {
        __typename?: "Account";
        id: any;
        company?: { __typename?: "Company"; businessName?: string | null | undefined; id: any } | null | undefined;
      };
      attributes: {
        __typename?: "RecAttributes";
        vintages?: Array<string> | null | undefined;
        locations?: Array<string> | null | undefined;
        projects?: Array<string> | null | undefined;
        fuelSources?: Array<string> | null | undefined;
        eligibilities?: Array<string> | null | undefined;
        certifications?: Array<string> | null | undefined;
        commencementDate?: string | null | undefined;
      };
    }>;
    offsetInfo: {
      __typename?: "PageDetail";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
  };
};

export type OrderHistoryV2QueryVariables = Exact<{
  input?: Maybe<OrderHistoryInput>;
}>;

export type OrderHistoryV2Query = {
  __typename?: "Query";
  orderHistoryV2: {
    __typename?: "OrderHistoryResponse";
    orders: Array<{
      __typename?: "Order";
      id: any;
      tenantId: any;
      accountId: any;
      orderNumber: string;
      assetType: AssetType;
      position: OrderPosition;
      type: OrderType;
      status: OrderStatus;
      statusMessage?: string | null | undefined;
      initialVolume: number;
      currentVolume: number;
      cancelledVolume?: number | null | undefined;
      allowCounterOffers: boolean;
      completedOn?: string | null | undefined;
      createdOn?: string | null | undefined;
      updatedOn?: string | null | undefined;
      orderExpiry?: OrderExpiry | null | undefined;
      expiryDate?: string | null | undefined;
      price: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
      attributes:
        | { __typename?: "CCOrderAttributes" }
        | {
            __typename?: "RecOrderAttributes";
            vintages?: Array<string> | null | undefined;
            locations?: Array<string> | null | undefined;
            fuelSources?: Array<string> | null | undefined;
            eligibilities?: Array<string> | null | undefined;
            projects?: Array<string> | null | undefined;
            certifications?: Array<string> | null | undefined;
            commencementDate?: string | null | undefined;
          };
    }>;
    offsetInfo: {
      __typename?: "OrderBookOffsetInfo";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
  };
};

export type TradingAccountUsersQueryVariables = Exact<{ [key: string]: never }>;

export type TradingAccountUsersQuery = {
  __typename?: "Query";
  tradingAccountUsers: Array<{
    __typename?: "TradingAccountUser";
    id: any;
    email: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    tradingAccountUserType: TradingAccountUserType;
    position?: string | null | undefined;
    status: TradingAccountUserStatus;
    isAccountOwner?: boolean | null | undefined;
  }>;
};

export type FewAccountDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type FewAccountDetailsQuery = {
  __typename?: "Query";
  account?:
    | {
        __typename?: "Account";
        id: any;
        termsAcceptedDate?: string | null | undefined;
        rulesAcceptedDate?: string | null | undefined;
        privacyPolicyAcceptedDate?: string | null | undefined;
        status: AccountStatus;
        tenantId?: any | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
        type?: AccountType | null | undefined;
        linkedRegistries: Array<{
          __typename?: "LinkedRegistry";
          id: any;
          registryId: any;
          status: RegistryLinkStatus;
          accountType?: AccountType | null | undefined;
          registryCode?: RegistryCode | null | undefined;
          registryOrgLinkId?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type AccountQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQuery = {
  __typename?: "Query";
  account?:
    | {
        __typename?: "Account";
        id: any;
        tenantId?: any | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
        privacyPolicyAcceptedDate?: string | null | undefined;
        termsAcceptedDate?: string | null | undefined;
        rulesAcceptedDate?: string | null | undefined;
        nrmTermsAcceptedDate?: string | null | undefined;
        type?: AccountType | null | undefined;
        status: AccountStatus;
        linkedRegistries: Array<{
          __typename?: "LinkedRegistry";
          id: any;
          registryId: any;
          status: RegistryLinkStatus;
          accountType?: AccountType | null | undefined;
          registryCode?: RegistryCode | null | undefined;
          registryOrgLinkId?: string | null | undefined;
        }>;
        company?:
          | {
              __typename?: "Company";
              id: any;
              businessName?: string | null | undefined;
              businessType?: string | null | undefined;
              employerIdentificationNumber?: string | null | undefined;
              industryClassification?:
                | { __typename?: "IndustryClassification"; id: any; name: string; businessClassificationName: string }
                | null
                | undefined;
              physicalAddress?:
                | {
                    __typename?: "Address";
                    id: any;
                    line1?: string | null | undefined;
                    line2?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    postalCode?: string | null | undefined;
                    countryCode?: string | null | undefined;
                  }
                | null
                | undefined;
              approvalState?:
                | {
                    __typename?: "ApprovalState";
                    id: any;
                    status: ApprovalStatus;
                    errorCode?: string | null | undefined;
                    errorMessage?: string | null | undefined;
                  }
                | null
                | undefined;
              documents?:
                | Array<{
                    __typename?: "Document";
                    id: any;
                    fileName?: string | null | undefined;
                    type: DocumentType;
                    mediaType?: string | null | undefined;
                    size?: number | null | undefined;
                    status?: DocumentStatus | null | undefined;
                    errorMessage?: string | null | undefined;
                    signedUrl?: string | null | undefined;
                    approvalState?:
                      | {
                          __typename?: "ApprovalState";
                          id: any;
                          status: ApprovalStatus;
                          errorCode?: string | null | undefined;
                          errorMessage?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
              contacts?:
                | Array<{
                    __typename?: "CompanyContact";
                    id: any;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    jobTitle?: string | null | undefined;
                    dateOfBirth?: string | null | undefined;
                    identityNumber?: string | null | undefined;
                    passportNumber?: string | null | undefined;
                    passportCountryCode?: string | null | undefined;
                    type?: ContactType | null | undefined;
                    physicalAddress?:
                      | {
                          __typename?: "Address";
                          id: any;
                          line1?: string | null | undefined;
                          line2?: string | null | undefined;
                          city?: string | null | undefined;
                          state?: string | null | undefined;
                          postalCode?: string | null | undefined;
                          countryCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    approvalState?:
                      | {
                          __typename?: "ApprovalState";
                          id: any;
                          status: ApprovalStatus;
                          errorCode?: string | null | undefined;
                          errorMessage?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    documents?:
                      | Array<{
                          __typename?: "Document";
                          id: any;
                          fileName?: string | null | undefined;
                          type: DocumentType;
                          mediaType?: string | null | undefined;
                          size?: number | null | undefined;
                          status?: DocumentStatus | null | undefined;
                          errorMessage?: string | null | undefined;
                          signedUrl?: string | null | undefined;
                          approvalState?:
                            | {
                                __typename?: "ApprovalState";
                                id: any;
                                status: ApprovalStatus;
                                errorCode?: string | null | undefined;
                                errorMessage?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }>
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        linkedPaymentMethods: Array<{
          __typename?: "LinkedPaymentMethod";
          id: any;
          status: PaymentMethodLinkStatus;
          statusMessage?: string | null | undefined;
          paymentMethod: {
            __typename?: "PaymentMethod";
            id: any;
            code: PaymentMethodCode;
            displayName: string;
            description: string;
            paymentProvider: {
              __typename?: "PaymentProviderV2";
              description: string;
              displayName: string;
              id: any;
              name: string;
            };
          };
          fundingSource?:
            | {
                __typename?: "BankAccountV2";
                id: any;
                currencyCode?: CurrencyCode | null | undefined;
                status: FundingSourceStatus;
                statusMessage?: string | null | undefined;
                errorCode?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type TenantsQueryVariables = Exact<{ [key: string]: never }>;

export type TenantsQuery = {
  __typename?: "Query";
  tenants: Array<{
    __typename?: "Tenant";
    id: any;
    name?: string | null | undefined;
    displayName?: string | null | undefined;
    startDateTime?: string | null | undefined;
    endDateTime?: string | null | undefined;
    electricityUom?: ElectricityUom | null | undefined;
    localisation?:
      | {
          __typename?: "Localisation";
          id: any;
          country: string;
          countryCode: string;
          currencyCode: string;
          currencySymbol: string;
          currencyConversionRate: number;
        }
      | null
      | undefined;
    paymentProviders?:
      | Array<{
          __typename?: "PaymentProviderV2";
          id: any;
          name: string;
          displayName: string;
          description: string;
          paymentMethods: Array<{
            __typename?: "PaymentMethod";
            id: any;
            code: PaymentMethodCode;
            displayName: string;
            description: string;
            paymentProvider: {
              __typename?: "PaymentProviderV2";
              id: any;
              name: string;
              displayName: string;
              description: string;
            };
          }>;
        }>
      | null
      | undefined;
  }>;
};

export type FundingSourceInitialisingTokenQueryVariables = Exact<{ [key: string]: never }>;

export type FundingSourceInitialisingTokenQuery = {
  __typename?: "Query";
  account?:
    | {
        __typename?: "Account";
        id: any;
        linkedPaymentMethods: Array<{
          __typename?: "LinkedPaymentMethod";
          id: any;
          initialisingToken?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type AccountPaymentDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountPaymentDetailsQuery = {
  __typename?: "Query";
  account?:
    | {
        __typename?: "Account";
        id: any;
        tenantId?: any | null | undefined;
        status: AccountStatus;
        type?: AccountType | null | undefined;
        linkedPaymentMethods: Array<{
          __typename?: "LinkedPaymentMethod";
          id: any;
          status: PaymentMethodLinkStatus;
          statusMessage?: string | null | undefined;
          fundingSource?:
            | {
                __typename?: "BankAccountV2";
                accountName?: string | null | undefined;
                accountNumberMasked?: string | null | undefined;
                accountType?: BankAccountType | null | undefined;
                id: any;
                status: FundingSourceStatus;
                statusMessage?: string | null | undefined;
                currencyCode?: CurrencyCode | null | undefined;
                errorCode?: string | null | undefined;
              }
            | null
            | undefined;
          paymentMethod: {
            __typename?: "PaymentMethod";
            id: any;
            code: PaymentMethodCode;
            displayName: string;
            description: string;
            paymentProvider: {
              __typename?: "PaymentProviderV2";
              description: string;
              displayName: string;
              id: any;
              name: string;
            };
          };
        }>;
      }
    | null
    | undefined;
};

export type AccountEnrolledFactorQueryVariables = Exact<{ [key: string]: never }>;

export type AccountEnrolledFactorQuery = {
  __typename?: "Query";
  account?:
    | {
        __typename?: "Account";
        id: any;
        enrolledFactors: Array<{
          __typename?: "EnrolledFactor";
          id: string;
          status: FactorStatus;
          provider: FactorProvider;
          type: FactorType;
        }>;
      }
    | null
    | undefined;
};

export type AccountLinkedRegistriesQueryVariables = Exact<{ [key: string]: never }>;

export type AccountLinkedRegistriesQuery = {
  __typename?: "Query";
  account?:
    | {
        __typename?: "Account";
        id: any;
        type?: AccountType | null | undefined;
        linkedRegistries: Array<{
          __typename?: "LinkedRegistry";
          id: any;
          registryCode?: RegistryCode | null | undefined;
          accountType?: AccountType | null | undefined;
          registryOrgLinkId?: string | null | undefined;
          registryId: any;
          status: RegistryLinkStatus;
        }>;
      }
    | null
    | undefined;
};

export type AccountTenantIdQueryVariables = Exact<{ [key: string]: never }>;

export type AccountTenantIdQuery = {
  __typename?: "Query";
  account?: { __typename?: "Account"; id: any; tenantId?: any | null | undefined } | null | undefined;
};

export type BusinessClassificationsQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessClassificationsQuery = {
  __typename?: "Query";
  businessClassifications: Array<{
    __typename?: "BusinessClassification";
    id: any;
    name: string;
    industryClassifications: Array<{
      __typename?: "IndustryClassification";
      id: any;
      name: string;
      businessClassificationName: string;
    }>;
  }>;
};

export type TransactionSummaryQueryVariables = Exact<{
  orderId: Scalars["UUID"];
}>;

export type TransactionSummaryQuery = {
  __typename?: "Query";
  transactionSummary: {
    __typename?: "TransactionSummary";
    transactions: Array<{
      __typename?: "Transaction";
      id: any;
      quantity: number;
      deliveryDate: string;
      trackingSystem: string;
      generator?:
        | {
            __typename?: "Generator";
            id: any;
            name: string;
            description?: string | null | undefined;
            crsEffectiveDate?: string | null | undefined;
            crsExpiryDate?: string | null | undefined;
          }
        | null
        | undefined;
      commodityGenerationSummary?:
        | Array<{ __typename?: "CommodityGenerationSummary"; id: any; date: string; volume: any } | null | undefined>
        | null
        | undefined;
      buyersDetails: {
        __typename?: "TransactionDetails";
        id: any;
        partyType: PartyType;
        executedPrice: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
        totalContractPrice: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
        fee: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
      };
      order: {
        __typename?: "Order";
        id: any;
        tenantId: any;
        accountId: any;
        orderNumber: string;
        assetType: AssetType;
        position: OrderPosition;
        type: OrderType;
        status: OrderStatus;
        statusMessage?: string | null | undefined;
        initialVolume: number;
        currentVolume: number;
        completedOn?: string | null | undefined;
        createdOn?: string | null | undefined;
        updatedOn?: string | null | undefined;
        price: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
        attributes:
          | { __typename?: "CCOrderAttributes" }
          | {
              __typename?: "RecOrderAttributes";
              vintages?: Array<string> | null | undefined;
              locations?: Array<string> | null | undefined;
              fuelSources?: Array<string> | null | undefined;
              eligibilities?: Array<string> | null | undefined;
              projects?: Array<string> | null | undefined;
              certifications?: Array<string> | null | undefined;
              commencementDate?: string | null | undefined;
            };
      };
      sellersDetails: {
        __typename?: "TransactionDetails";
        id: any;
        partyType: PartyType;
        executedPrice: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
        totalContractPrice: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
        fee: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
      };
    }>;
    order: {
      __typename?: "Order";
      id: any;
      tenantId: any;
      accountId: any;
      orderNumber: string;
      assetType: AssetType;
      position: OrderPosition;
      type: OrderType;
      status: OrderStatus;
      statusMessage?: string | null | undefined;
      initialVolume: number;
      currentVolume: number;
      completedOn?: string | null | undefined;
      createdOn?: string | null | undefined;
      updatedOn?: string | null | undefined;
      price: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
      attributes:
        | { __typename?: "CCOrderAttributes" }
        | {
            __typename?: "RecOrderAttributes";
            vintages?: Array<string> | null | undefined;
            locations?: Array<string> | null | undefined;
            fuelSources?: Array<string> | null | undefined;
            eligibilities?: Array<string> | null | undefined;
            projects?: Array<string> | null | undefined;
            certifications?: Array<string> | null | undefined;
            commencementDate?: string | null | undefined;
          };
    };
  };
};

export type CurrentTradingHaltQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentTradingHaltQuery = {
  __typename?: "Query";
  currentTradingHalt?:
    | {
        __typename?: "TradingHalt";
        id: any;
        tenantId: any;
        startDateTime: string;
        endDateTime: string;
        isApplicable: boolean;
        isPlanned: boolean;
      }
    | null
    | undefined;
};

export type RegistriesQueryVariables = Exact<{
  where?: Maybe<RegistryFilter>;
}>;

export type RegistriesQuery = {
  __typename?: "Query";
  registries?:
    | Array<{ __typename?: "Registry"; id: any; code: string; shortName: string; fullName?: string | null | undefined }>
    | null
    | undefined;
};

export type InboxMessagesQueryVariables = Exact<{
  input: MessagesInput;
}>;

export type InboxMessagesQuery = {
  __typename?: "Query";
  messages: {
    __typename?: "MessagesResponse";
    messages: Array<{
      __typename?: "InboxMessage";
      status: MessageStatus;
      id: string;
      subject?: string | null | undefined;
      body?: string | null | undefined;
      dateCreated: string;
      dateRead?: string | null | undefined;
      recipient: {
        __typename?: "MessageContact";
        contactId?: any | null | undefined;
        contactName?: string | null | undefined;
      };
      sender?:
        | { __typename?: "MessageContact"; contactId?: any | null | undefined; contactName?: string | null | undefined }
        | null
        | undefined;
    }>;
    offsetInfo: {
      __typename?: "PageDetail";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
  };
};

export type UnreadMessagesCountQueryVariables = Exact<{
  input: MessagesInput;
}>;

export type UnreadMessagesCountQuery = {
  __typename?: "Query";
  messages: {
    __typename?: "MessagesResponse";
    offsetInfo: { __typename?: "PageDetail"; total?: number | null | undefined };
  };
};

export type TradingSummaryQueryVariables = Exact<{
  where: TradingSummaryInput;
}>;

export type TradingSummaryQuery = {
  __typename?: "Query";
  tradingSummary?:
    | {
        __typename?: "TradingSummary";
        startDate: string;
        endDate: string;
        totalTradedVolume: any;
        totalAskVolume: any;
        tradingSummaryDetails?:
          | Array<
              | {
                  __typename?: "TradingSummaryDetail";
                  totalAskVolume: any;
                  totalBidVolume: any;
                  tradedAskVolume: any;
                  tradedBidVolume: any;
                  account: {
                    __typename?: "Account";
                    id: any;
                    email: string;
                    company?: { __typename?: "Company"; businessName?: string | null | undefined } | null | undefined;
                  };
                  totalFees: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
                  receivedAmount: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
                  paidAmount: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CounteredOrdersQueryVariables = Exact<{
  input: CounteredOrdersInput;
}>;

export type CounteredOrdersQuery = {
  __typename?: "Query";
  counteredOrders: {
    __typename?: "CounteredOrdersResponse";
    offsetInfo: {
      __typename?: "PageDetail";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
    orders: Array<{
      __typename?: "Order";
      id: any;
      tenantId: any;
      accountId: any;
      assetType: AssetType;
      position: OrderPosition;
      status: OrderStatus;
      initialVolume: number;
      currentVolume: number;
      completedOn?: string | null | undefined;
      createdOn?: string | null | undefined;
      orderNumber: string;
      allowCounterOffers: boolean;
      canCounter?: boolean | null | undefined;
      type: OrderType;
      updatedOn?: string | null | undefined;
      price: { __typename?: "Money"; currencyCode: CurrencyCode; amount: string };
      attributes:
        | { __typename?: "CCOrderAttributes" }
        | {
            __typename?: "RecOrderAttributes";
            vintages?: Array<string> | null | undefined;
            locations?: Array<string> | null | undefined;
            fuelSources?: Array<string> | null | undefined;
            eligibilities?: Array<string> | null | undefined;
            projects?: Array<string> | null | undefined;
            certifications?: Array<string> | null | undefined;
            commencementDate?: string | null | undefined;
          };
    }>;
  };
};

export type OfferGroupsQueryVariables = Exact<{
  filter: OfferGroupFilter;
}>;

export type OfferGroupsQuery = {
  __typename?: "Query";
  offerGroups?:
    | Array<{
        __typename?: "OfferGroup";
        tenantId: any;
        offerGroupNumber: string;
        accountId: any;
        sourceOrderNumber: string;
        issuedOrderNumber?: string | null | undefined;
        status: OfferGroupStatus;
        createdOn: string;
      }>
    | null
    | undefined;
};

export type CounterOffersInOfferGroupQueryVariables = Exact<{
  filter: OfferFilter;
}>;

export type CounterOffersInOfferGroupQuery = {
  __typename?: "Query";
  offers?:
    | Array<{
        __typename?: "Offer";
        tenantId: any;
        offerGroupNumber: string;
        offerNumber: string;
        fromAccountId: any;
        toAccountId: any;
        unitPrice: any;
        volume: any;
        status: OfferStatus;
        statusMessage?: string | null | undefined;
        counteredOn?: string | null | undefined;
        requestedOn?: string | null | undefined;
        createdOn: string;
      }>
    | null
    | undefined;
};

export type TradeContractHistoryQueryVariables = Exact<{
  input: TradeContractHistoryInput;
}>;

export type TradeContractHistoryQuery = {
  __typename?: "Query";
  tradeContractHistory: {
    __typename?: "TradeContractResponse";
    offsetInfo: {
      __typename?: "PageDetail";
      offset?: number | null | undefined;
      limit?: number | null | undefined;
      total?: number | null | undefined;
    };
    tradeContracts: Array<{
      __typename?: "TradeContract";
      id: any;
      tenantId: any;
      tradeContractNumber: string;
      createdDate: string;
      tradeSettlementDate: string;
      tradeType: TradeType;
      updatedOn?: string | null | undefined;
      volume: number;
      statusMessage?: string | null | undefined;
      status: TradeContractStatus;
      registryCode: RegistryCode;
      unitPrice: any;
      position: TradePosition;
      currencyCode: CurrencyCode;
      assetType: AssetType;
      attributes: {
        __typename?: "RecAttributes";
        vintages?: Array<string> | null | undefined;
        locations?: Array<string> | null | undefined;
        fuelSources?: Array<string> | null | undefined;
        eligibilities?: Array<string> | null | undefined;
        projects?: Array<string> | null | undefined;
        certifications?: Array<string> | null | undefined;
        commencementDate?: string | null | undefined;
      };
      account: {
        __typename?: "Account";
        id: any;
        company?: { __typename?: "Company"; businessName?: string | null | undefined; id: any } | null | undefined;
      };
      counterAccount?:
        | {
            __typename?: "Account";
            id: any;
            email: string;
            company?: { __typename?: "Company"; id: any; businessName?: string | null | undefined } | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type TradeContractAssetsByTradeContractIdQueryVariables = Exact<{
  tradeContractId: Scalars["UUID"];
}>;

export type TradeContractAssetsByTradeContractIdQuery = {
  __typename?: "Query";
  tradeContractAssetsByTradeContractId?:
    | Array<{
        __typename?: "TradeContractAsset";
        assetType: AssetType;
        id: any;
        matchedContractId: any;
        status: TradeContractAssetStatus;
        statusMessage?: string | null | undefined;
        tenantId: any;
        tradeContractId: any;
        volume: number;
        attributes: {
          __typename?: "RecAttributes";
          vintages?: Array<string> | null | undefined;
          locations?: Array<string> | null | undefined;
          fuelSources?: Array<string> | null | undefined;
          eligibilities?: Array<string> | null | undefined;
          projects?: Array<string> | null | undefined;
          certifications?: Array<string> | null | undefined;
          commencementDate?: string | null | undefined;
        };
      }>
    | null
    | undefined;
};

export type TradeContractTransactionSummaryQueryVariables = Exact<{
  tradeContractId: Scalars["UUID"];
}>;

export type TradeContractTransactionSummaryQuery = {
  __typename?: "Query";
  tradeContractTransactionSummary: {
    __typename?: "TradeContractTransactionSummary";
    tradeContract: {
      __typename?: "TradeContract";
      id: any;
      tenantId: any;
      tradeContractNumber: string;
      createdDate: string;
      position: TradePosition;
      volume: number;
      assetType: AssetType;
      registryCode: RegistryCode;
      status: TradeContractStatus;
      statusMessage?: string | null | undefined;
      unitPrice: any;
      currencyCode: CurrencyCode;
      account: {
        __typename?: "Account";
        id: any;
        company?: { __typename?: "Company"; businessName?: string | null | undefined } | null | undefined;
      };
      counterAccount?:
        | {
            __typename?: "Account";
            id: any;
            company?: { __typename?: "Company"; businessName?: string | null | undefined } | null | undefined;
          }
        | null
        | undefined;
      attributes: {
        __typename?: "RecAttributes";
        vintages?: Array<string> | null | undefined;
        locations?: Array<string> | null | undefined;
        projects?: Array<string> | null | undefined;
        fuelSources?: Array<string> | null | undefined;
        eligibilities?: Array<string> | null | undefined;
        certifications?: Array<string> | null | undefined;
        commencementDate?: string | null | undefined;
      };
    };
    tradeTransactions: Array<{
      __typename?: "TradeTransaction";
      transactionNumber?: string | null | undefined;
      position: TradePosition;
      matchedDate: string;
      completedDate: string;
      unitPrice: any;
      volume: number;
      transactionFee: any;
      assetType: AssetType;
      currencyCode: CurrencyCode;
      transactionDetails: Array<{
        __typename?: "TradeTransactionDetails";
        id?: any | null | undefined;
        volume: number;
        trackingSystem: string;
        attributes: {
          __typename?: "RecAttributes";
          vintages?: Array<string> | null | undefined;
          locations?: Array<string> | null | undefined;
          projects?: Array<string> | null | undefined;
          fuelSources?: Array<string> | null | undefined;
          eligibilities?: Array<string> | null | undefined;
          certifications?: Array<string> | null | undefined;
          commencementDate?: string | null | undefined;
        };
        commodityGenerationSummary?:
          | Array<{ __typename?: "CommodityGenerationSummary"; id: any; date: string; volume: any }>
          | null
          | undefined;
        generator?:
          | {
              __typename?: "Generator";
              id: any;
              name: string;
              commencementDate?: string | null | undefined;
              crsEffectiveDate?: string | null | undefined;
              crsExpiryDate?: string | null | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
  };
};

export type SpotTradeTransactionSummaryQueryVariables = Exact<{
  orderId: Scalars["UUID"];
}>;

export type SpotTradeTransactionSummaryQuery = {
  __typename?: "Query";
  spotTradeTransactionSummary: {
    __typename?: "SpotTradeTransactionSummary";
    order?:
      | {
          __typename?: "Order";
          id: any;
          orderNumber: string;
          tenantId: any;
          position: OrderPosition;
          type: OrderType;
          assetType: AssetType;
          status: OrderStatus;
          statusMessage?: string | null | undefined;
          initialVolume: number;
          completedOn?: string | null | undefined;
          createdOn?: string | null | undefined;
          account?:
            | {
                __typename?: "Account";
                id: any;
                company?: { __typename?: "Company"; businessName?: string | null | undefined } | null | undefined;
              }
            | null
            | undefined;
          price: { __typename?: "Money"; amount: string; currencyCode: CurrencyCode };
          attributes:
            | { __typename?: "CCOrderAttributes" }
            | {
                __typename?: "RecOrderAttributes";
                vintages?: Array<string> | null | undefined;
                vintage?: number | null | undefined;
                locations?: Array<string> | null | undefined;
                projects?: Array<string> | null | undefined;
                project?: string | null | undefined;
                location?: string | null | undefined;
                fuelSources?: Array<string> | null | undefined;
                eligibilities?: Array<string> | null | undefined;
                certifications?: Array<string> | null | undefined;
                vintageHalf?: VintageHalf | null | undefined;
              };
        }
      | null
      | undefined;
    tradeTransactions: Array<{
      __typename?: "TradeTransaction";
      transactionNumber?: string | null | undefined;
      position: TradePosition;
      matchedDate: string;
      completedDate: string;
      assetType: AssetType;
      volume: number;
      unitPrice: any;
      currencyCode: CurrencyCode;
      transactionFee: any;
      transactionDetails: Array<{
        __typename?: "TradeTransactionDetails";
        id?: any | null | undefined;
        volume: number;
        trackingSystem: string;
        attributes: {
          __typename?: "RecAttributes";
          vintages?: Array<string> | null | undefined;
          locations?: Array<string> | null | undefined;
          projects?: Array<string> | null | undefined;
          fuelSources?: Array<string> | null | undefined;
          eligibilities?: Array<string> | null | undefined;
          certifications?: Array<string> | null | undefined;
          commencementDate?: string | null | undefined;
        };
        commodityGenerationSummary?:
          | Array<{ __typename?: "CommodityGenerationSummary"; id: any; date: string; volume: any }>
          | null
          | undefined;
        generator?:
          | {
              __typename?: "Generator";
              id: any;
              name: string;
              commencementDate?: string | null | undefined;
              crsEffectiveDate?: string | null | undefined;
              crsExpiryDate?: string | null | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
  };
};

export type AssetByAssetCodeQueryVariables = Exact<{
  assetCode: AssetType;
  registryCode?: Maybe<RegistryCode>;
}>;

export type AssetByAssetCodeQuery = {
  __typename?: "Query";
  assetByAssetCode: {
    __typename?: "Asset";
    id: any;
    code: string;
    name: string;
    displayName?: string | null | undefined;
    attributes?:
      | Array<{
          __typename?: "AssetAttribute";
          id: any;
          name: string;
          registryCode: RegistryCode;
          mandatory: boolean;
          values?:
            | Array<{
                __typename?: "AttributeValue";
                code: string;
                displayName?: string | null | undefined;
                key?: string | null | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export const CommodityTransactionFieldsFragmentDoc = gql`
  fragment CommodityTransactionFields on CommodityTransaction {
    id
    status
    statusMessage
    volume
    generator {
      id
      crsEffectiveDate
      crsExpiryDate
    }
    commoditySerial {
      serialPrefix
      serialFrom
      serialTo
    }
  }
`;
export const RecOrderAttributesFieldsFragmentDoc = gql`
  fragment RecOrderAttributesFields on RecOrderAttributes {
    vintages
    locations
    fuelSources
    eligibilities
    projects
    certifications
    commencementDate
  }
`;
export const RecAssetAttributesFieldsFragmentDoc = gql`
  fragment RecAssetAttributesFields on RecAssetAttributes {
    vintage
    vintageHalf
    location
    fuelSources
    eligibilities
    project
    certifications
    commencementDate
  }
`;
export const RetireRequestDetailsFieldsFragmentDoc = gql`
  fragment RetireRequestDetailsFields on RetireCommodityDetail {
    id
    volume
    attributes {
      ...RecAssetAttributesFields
    }
    status
    statusMessage
  }
  ${RecAssetAttributesFieldsFragmentDoc}
`;
export const ExportRequestDetailsFieldsFragmentDoc = gql`
  fragment ExportRequestDetailsFields on ExportRequestDetail {
    id
    volume
    attributes {
      ...RecAssetAttributesFields
    }
    status
    statusMessage
  }
  ${RecAssetAttributesFieldsFragmentDoc}
`;
export const LinkedRegistryFieldsFragmentDoc = gql`
  fragment LinkedRegistryFields on LinkedRegistry {
    id
    registryId
    status
    accountType
    registryCode
    registryOrgLinkId
  }
`;
export const LinkedPaymentFieldsFragmentDoc = gql`
  fragment LinkedPaymentFields on LinkedPaymentMethod {
    id
    status
    statusMessage
    paymentMethod {
      id
      code
      displayName
      description
      paymentProvider {
        description
        displayName
        id
        name
      }
    }
    fundingSource {
      id
      currencyCode
      status
      statusMessage
      errorCode
    }
  }
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    line1
    line2
    city
    state
    postalCode
    countryCode
  }
`;
export const ApprovalStateFieldsFragmentDoc = gql`
  fragment ApprovalStateFields on ApprovalState {
    id
    status
    errorCode
    errorMessage
  }
`;
export const DocumentFieldsFragmentDoc = gql`
  fragment DocumentFields on Document {
    id
    fileName
    type
    mediaType
    size
    status
    errorMessage
    signedUrl
    approvalState {
      ...ApprovalStateFields
    }
  }
  ${ApprovalStateFieldsFragmentDoc}
`;
export const CompanyFieldsFragmentDoc = gql`
  fragment CompanyFields on Company {
    id
    businessName
    businessType
    industryClassification {
      id
      name
      businessClassificationName
    }
    employerIdentificationNumber
    physicalAddress {
      ...AddressFields
    }
    approvalState {
      ...ApprovalStateFields
    }
    documents {
      ...DocumentFields
    }
    contacts {
      id
      firstName
      lastName
      jobTitle
      dateOfBirth
      identityNumber
      physicalAddress {
        ...AddressFields
      }
      passportNumber
      passportCountryCode
      type
      approvalState {
        ...ApprovalStateFields
      }
      documents {
        ...DocumentFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${ApprovalStateFieldsFragmentDoc}
  ${DocumentFieldsFragmentDoc}
`;
export const RecTradeAttributesFieldsFragmentDoc = gql`
  fragment RecTradeAttributesFields on RecAttributes {
    vintages
    locations
    fuelSources
    eligibilities
    projects
    certifications
    commencementDate
  }
`;
export const ExportCommoditiesByVolumeDocument = gql`
  mutation ExportCommoditiesByVolume($exportCommoditiesByVolumeInput: ExportCommoditiesByVolumeInput!) {
    exportCommoditiesByVolume(exportCommoditiesByVolumeInput: $exportCommoditiesByVolumeInput) {
      id
    }
  }
`;
export type ExportCommoditiesByVolumeMutationFn = Apollo.MutationFunction<
  ExportCommoditiesByVolumeMutation,
  ExportCommoditiesByVolumeMutationVariables
>;

/**
 * __useExportCommoditiesByVolumeMutation__
 *
 * To run a mutation, you first call `useExportCommoditiesByVolumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCommoditiesByVolumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCommoditiesByVolumeMutation, { data, loading, error }] = useExportCommoditiesByVolumeMutation({
 *   variables: {
 *      exportCommoditiesByVolumeInput: // value for 'exportCommoditiesByVolumeInput'
 *   },
 * });
 */
export function useExportCommoditiesByVolumeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportCommoditiesByVolumeMutation,
    ExportCommoditiesByVolumeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportCommoditiesByVolumeMutation, ExportCommoditiesByVolumeMutationVariables>(
    ExportCommoditiesByVolumeDocument,
    options,
  );
}
export type ExportCommoditiesByVolumeMutationHookResult = ReturnType<typeof useExportCommoditiesByVolumeMutation>;
export type ExportCommoditiesByVolumeMutationResult = Apollo.MutationResult<ExportCommoditiesByVolumeMutation>;
export type ExportCommoditiesByVolumeMutationOptions = Apollo.BaseMutationOptions<
  ExportCommoditiesByVolumeMutation,
  ExportCommoditiesByVolumeMutationVariables
>;
export const RetireCommoditiesDocument = gql`
  mutation RetireCommodities($retireCommoditiesInput: RetireCommoditiesInput!) {
    retireCommodities(retireCommoditiesInput: $retireCommoditiesInput) {
      retirementId
    }
  }
`;
export type RetireCommoditiesMutationFn = Apollo.MutationFunction<
  RetireCommoditiesMutation,
  RetireCommoditiesMutationVariables
>;

/**
 * __useRetireCommoditiesMutation__
 *
 * To run a mutation, you first call `useRetireCommoditiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetireCommoditiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retireCommoditiesMutation, { data, loading, error }] = useRetireCommoditiesMutation({
 *   variables: {
 *      retireCommoditiesInput: // value for 'retireCommoditiesInput'
 *   },
 * });
 */
export function useRetireCommoditiesMutation(
  baseOptions?: Apollo.MutationHookOptions<RetireCommoditiesMutation, RetireCommoditiesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetireCommoditiesMutation, RetireCommoditiesMutationVariables>(
    RetireCommoditiesDocument,
    options,
  );
}
export type RetireCommoditiesMutationHookResult = ReturnType<typeof useRetireCommoditiesMutation>;
export type RetireCommoditiesMutationResult = Apollo.MutationResult<RetireCommoditiesMutation>;
export type RetireCommoditiesMutationOptions = Apollo.BaseMutationOptions<
  RetireCommoditiesMutation,
  RetireCommoditiesMutationVariables
>;
export const RemoveDocumentDocument = gql`
  mutation RemoveDocument($input: RemoveDocumentInput!) {
    removeDocument(input: $input) {
      document {
        id
      }
    }
  }
`;
export type RemoveDocumentMutationFn = Apollo.MutationFunction<RemoveDocumentMutation, RemoveDocumentMutationVariables>;

/**
 * __useRemoveDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentMutation, { data, loading, error }] = useRemoveDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveDocumentMutation, RemoveDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDocumentMutation, RemoveDocumentMutationVariables>(RemoveDocumentDocument, options);
}
export type RemoveDocumentMutationHookResult = ReturnType<typeof useRemoveDocumentMutation>;
export type RemoveDocumentMutationResult = Apollo.MutationResult<RemoveDocumentMutation>;
export type RemoveDocumentMutationOptions = Apollo.BaseMutationOptions<
  RemoveDocumentMutation,
  RemoveDocumentMutationVariables
>;
export const ResubmitApplicationDocument = gql`
  mutation ResubmitApplication {
    resubmitApplication {
      account {
        id
        status
      }
    }
  }
`;
export type ResubmitApplicationMutationFn = Apollo.MutationFunction<
  ResubmitApplicationMutation,
  ResubmitApplicationMutationVariables
>;

/**
 * __useResubmitApplicationMutation__
 *
 * To run a mutation, you first call `useResubmitApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitApplicationMutation, { data, loading, error }] = useResubmitApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResubmitApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<ResubmitApplicationMutation, ResubmitApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResubmitApplicationMutation, ResubmitApplicationMutationVariables>(
    ResubmitApplicationDocument,
    options,
  );
}
export type ResubmitApplicationMutationHookResult = ReturnType<typeof useResubmitApplicationMutation>;
export type ResubmitApplicationMutationResult = Apollo.MutationResult<ResubmitApplicationMutation>;
export type ResubmitApplicationMutationOptions = Apollo.BaseMutationOptions<
  ResubmitApplicationMutation,
  ResubmitApplicationMutationVariables
>;
export const EditTradingAccountUserTypeDocument = gql`
  mutation EditTradingAccountUserType($input: EditTradingAccountUserTypeInput!) {
    editTradingAccountUserType(input: $input) {
      tradingAccountUser {
        id
      }
    }
  }
`;
export type EditTradingAccountUserTypeMutationFn = Apollo.MutationFunction<
  EditTradingAccountUserTypeMutation,
  EditTradingAccountUserTypeMutationVariables
>;

/**
 * __useEditTradingAccountUserTypeMutation__
 *
 * To run a mutation, you first call `useEditTradingAccountUserTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTradingAccountUserTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTradingAccountUserTypeMutation, { data, loading, error }] = useEditTradingAccountUserTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTradingAccountUserTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditTradingAccountUserTypeMutation,
    EditTradingAccountUserTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditTradingAccountUserTypeMutation, EditTradingAccountUserTypeMutationVariables>(
    EditTradingAccountUserTypeDocument,
    options,
  );
}
export type EditTradingAccountUserTypeMutationHookResult = ReturnType<typeof useEditTradingAccountUserTypeMutation>;
export type EditTradingAccountUserTypeMutationResult = Apollo.MutationResult<EditTradingAccountUserTypeMutation>;
export type EditTradingAccountUserTypeMutationOptions = Apollo.BaseMutationOptions<
  EditTradingAccountUserTypeMutation,
  EditTradingAccountUserTypeMutationVariables
>;
export const AddTradingAccountUserDocument = gql`
  mutation AddTradingAccountUser($input: AddTradingAccountUserInput!) {
    addTradingAccountUser(input: $input) {
      tradingAccountUser {
        id
        email
        firstName
        lastName
        tradingAccountUserType
        position
        status
        isAccountOwner
      }
    }
  }
`;
export type AddTradingAccountUserMutationFn = Apollo.MutationFunction<
  AddTradingAccountUserMutation,
  AddTradingAccountUserMutationVariables
>;

/**
 * __useAddTradingAccountUserMutation__
 *
 * To run a mutation, you first call `useAddTradingAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTradingAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTradingAccountUserMutation, { data, loading, error }] = useAddTradingAccountUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTradingAccountUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTradingAccountUserMutation, AddTradingAccountUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTradingAccountUserMutation, AddTradingAccountUserMutationVariables>(
    AddTradingAccountUserDocument,
    options,
  );
}
export type AddTradingAccountUserMutationHookResult = ReturnType<typeof useAddTradingAccountUserMutation>;
export type AddTradingAccountUserMutationResult = Apollo.MutationResult<AddTradingAccountUserMutation>;
export type AddTradingAccountUserMutationOptions = Apollo.BaseMutationOptions<
  AddTradingAccountUserMutation,
  AddTradingAccountUserMutationVariables
>;
export const RemoveTradingAccountUserDocument = gql`
  mutation RemoveTradingAccountUser($input: RemoveTradingAccountUserInput!) {
    removeTradingAccountUser(input: $input) {
      tradingAccountUser {
        id
        email
        firstName
        lastName
        tradingAccountUserType
        position
        status
        isAccountOwner
      }
    }
  }
`;
export type RemoveTradingAccountUserMutationFn = Apollo.MutationFunction<
  RemoveTradingAccountUserMutation,
  RemoveTradingAccountUserMutationVariables
>;

/**
 * __useRemoveTradingAccountUserMutation__
 *
 * To run a mutation, you first call `useRemoveTradingAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTradingAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTradingAccountUserMutation, { data, loading, error }] = useRemoveTradingAccountUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTradingAccountUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTradingAccountUserMutation, RemoveTradingAccountUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTradingAccountUserMutation, RemoveTradingAccountUserMutationVariables>(
    RemoveTradingAccountUserDocument,
    options,
  );
}
export type RemoveTradingAccountUserMutationHookResult = ReturnType<typeof useRemoveTradingAccountUserMutation>;
export type RemoveTradingAccountUserMutationResult = Apollo.MutationResult<RemoveTradingAccountUserMutation>;
export type RemoveTradingAccountUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveTradingAccountUserMutation,
  RemoveTradingAccountUserMutationVariables
>;
export const AcceptPoliciesDocument = gql`
  mutation AcceptPolicies($input: AcceptPoliciesInput!) {
    acceptPolicies(input: $input) {
      account {
        termsAcceptedDate
        rulesAcceptedDate
        privacyPolicyAcceptedDate
      }
    }
  }
`;
export type AcceptPoliciesMutationFn = Apollo.MutationFunction<AcceptPoliciesMutation, AcceptPoliciesMutationVariables>;

/**
 * __useAcceptPoliciesMutation__
 *
 * To run a mutation, you first call `useAcceptPoliciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPoliciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPoliciesMutation, { data, loading, error }] = useAcceptPoliciesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptPoliciesMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptPoliciesMutation, AcceptPoliciesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptPoliciesMutation, AcceptPoliciesMutationVariables>(AcceptPoliciesDocument, options);
}
export type AcceptPoliciesMutationHookResult = ReturnType<typeof useAcceptPoliciesMutation>;
export type AcceptPoliciesMutationResult = Apollo.MutationResult<AcceptPoliciesMutation>;
export type AcceptPoliciesMutationOptions = Apollo.BaseMutationOptions<
  AcceptPoliciesMutation,
  AcceptPoliciesMutationVariables
>;
export const AddFundingSourceDocument = gql`
  mutation AddFundingSource($addFundingSourceInput: AddFundingSourceInput!) {
    addFundingSource(input: $addFundingSourceInput) {
      linkedPaymentMethod {
        id
        status
        statusMessage
        initialisingToken
        paymentMethod {
          id
          code
          displayName
          description
          paymentProvider {
            description
            displayName
            id
            name
          }
        }
        fundingSource {
          id
          currencyCode
          status
          statusMessage
          errorCode
          ... on BankAccountV2 {
            accountName
            accountNumberMasked
            accountType
          }
        }
      }
    }
  }
`;
export type AddFundingSourceMutationFn = Apollo.MutationFunction<
  AddFundingSourceMutation,
  AddFundingSourceMutationVariables
>;

/**
 * __useAddFundingSourceMutation__
 *
 * To run a mutation, you first call `useAddFundingSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFundingSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFundingSourceMutation, { data, loading, error }] = useAddFundingSourceMutation({
 *   variables: {
 *      addFundingSourceInput: // value for 'addFundingSourceInput'
 *   },
 * });
 */
export function useAddFundingSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<AddFundingSourceMutation, AddFundingSourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFundingSourceMutation, AddFundingSourceMutationVariables>(
    AddFundingSourceDocument,
    options,
  );
}
export type AddFundingSourceMutationHookResult = ReturnType<typeof useAddFundingSourceMutation>;
export type AddFundingSourceMutationResult = Apollo.MutationResult<AddFundingSourceMutation>;
export type AddFundingSourceMutationOptions = Apollo.BaseMutationOptions<
  AddFundingSourceMutation,
  AddFundingSourceMutationVariables
>;
export const RemoveFundingSourceDocument = gql`
  mutation RemoveFundingSource($removeFundingSourceInput: RemoveFundingSourceInput!) {
    removeFundingSource(input: $removeFundingSourceInput) {
      linkedPaymentMethod {
        id
        status
        statusMessage
        initialisingToken
        paymentMethod {
          id
          code
          displayName
          description
          paymentProvider {
            description
            displayName
            id
            name
          }
        }
        fundingSource {
          id
          currencyCode
          status
          statusMessage
          errorCode
          ... on BankAccountV2 {
            accountName
            accountNumberMasked
            accountType
          }
        }
      }
    }
  }
`;
export type RemoveFundingSourceMutationFn = Apollo.MutationFunction<
  RemoveFundingSourceMutation,
  RemoveFundingSourceMutationVariables
>;

/**
 * __useRemoveFundingSourceMutation__
 *
 * To run a mutation, you first call `useRemoveFundingSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFundingSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFundingSourceMutation, { data, loading, error }] = useRemoveFundingSourceMutation({
 *   variables: {
 *      removeFundingSourceInput: // value for 'removeFundingSourceInput'
 *   },
 * });
 */
export function useRemoveFundingSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFundingSourceMutation, RemoveFundingSourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFundingSourceMutation, RemoveFundingSourceMutationVariables>(
    RemoveFundingSourceDocument,
    options,
  );
}
export type RemoveFundingSourceMutationHookResult = ReturnType<typeof useRemoveFundingSourceMutation>;
export type RemoveFundingSourceMutationResult = Apollo.MutationResult<RemoveFundingSourceMutation>;
export type RemoveFundingSourceMutationOptions = Apollo.BaseMutationOptions<
  RemoveFundingSourceMutation,
  RemoveFundingSourceMutationVariables
>;
export const LinkPaymentMethodDocument = gql`
  mutation LinkPaymentMethod($input: LinkPaymentMethodInput!) {
    linkPaymentMethod(input: $input) {
      linkedPaymentMethod {
        id
        paymentMethod {
          id
          code
          displayName
          description
          paymentProvider {
            id
            name
            displayName
            description
          }
        }
        status
        statusMessage
        initialisingToken
        fundingSource {
          id
          currencyCode
          errorCode
          status
          statusMessage
        }
      }
    }
  }
`;
export type LinkPaymentMethodMutationFn = Apollo.MutationFunction<
  LinkPaymentMethodMutation,
  LinkPaymentMethodMutationVariables
>;

/**
 * __useLinkPaymentMethodMutation__
 *
 * To run a mutation, you first call `useLinkPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPaymentMethodMutation, { data, loading, error }] = useLinkPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkPaymentMethodMutation, LinkPaymentMethodMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkPaymentMethodMutation, LinkPaymentMethodMutationVariables>(
    LinkPaymentMethodDocument,
    options,
  );
}
export type LinkPaymentMethodMutationHookResult = ReturnType<typeof useLinkPaymentMethodMutation>;
export type LinkPaymentMethodMutationResult = Apollo.MutationResult<LinkPaymentMethodMutation>;
export type LinkPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  LinkPaymentMethodMutation,
  LinkPaymentMethodMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($company: CompanyInput!) {
    updateCompany(company: $company) {
      id
      businessName
      businessType
      employerIdentificationNumber
      physicalAddress {
        id
        line1
        line2
        city
        state
        postalCode
        countryCode
      }
      contacts {
        id
        firstName
        lastName
        jobTitle
        dateOfBirth
        identityNumber
        physicalAddress {
          id
          line1
          line2
          city
          state
          postalCode
          countryCode
        }
        passportNumber
        passportCountryCode
        type
      }
    }
  }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const AddCompanyContactDocument = gql`
  mutation AddCompanyContact($companyContact: CompanyContactInput!) {
    addCompanyContact(companyContact: $companyContact) {
      id
      firstName
      lastName
      jobTitle
      dateOfBirth
      identityNumber
      physicalAddress {
        id
        line1
        line2
        city
        state
        postalCode
        countryCode
      }
      passportNumber
      passportCountryCode
      type
    }
  }
`;
export type AddCompanyContactMutationFn = Apollo.MutationFunction<
  AddCompanyContactMutation,
  AddCompanyContactMutationVariables
>;

/**
 * __useAddCompanyContactMutation__
 *
 * To run a mutation, you first call `useAddCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyContactMutation, { data, loading, error }] = useAddCompanyContactMutation({
 *   variables: {
 *      companyContact: // value for 'companyContact'
 *   },
 * });
 */
export function useAddCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCompanyContactMutation, AddCompanyContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompanyContactMutation, AddCompanyContactMutationVariables>(
    AddCompanyContactDocument,
    options,
  );
}
export type AddCompanyContactMutationHookResult = ReturnType<typeof useAddCompanyContactMutation>;
export type AddCompanyContactMutationResult = Apollo.MutationResult<AddCompanyContactMutation>;
export type AddCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyContactMutation,
  AddCompanyContactMutationVariables
>;
export const UpdateCompanyContactDocument = gql`
  mutation UpdateCompanyContact($companyContact: CompanyContactInput!) {
    updateCompanyContact(companyContact: $companyContact) {
      id
      firstName
      lastName
      jobTitle
      dateOfBirth
      identityNumber
      physicalAddress {
        id
        line1
        line2
        city
        state
        postalCode
        countryCode
      }
      passportNumber
      passportCountryCode
      type
    }
  }
`;
export type UpdateCompanyContactMutationFn = Apollo.MutationFunction<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>;

/**
 * __useUpdateCompanyContactMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyContactMutation, { data, loading, error }] = useUpdateCompanyContactMutation({
 *   variables: {
 *      companyContact: // value for 'companyContact'
 *   },
 * });
 */
export function useUpdateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>(
    UpdateCompanyContactDocument,
    options,
  );
}
export type UpdateCompanyContactMutationHookResult = ReturnType<typeof useUpdateCompanyContactMutation>;
export type UpdateCompanyContactMutationResult = Apollo.MutationResult<UpdateCompanyContactMutation>;
export type UpdateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>;
export const DeleteCompanyContactDocument = gql`
  mutation DeleteCompanyContact($deleteCompanyContactId: UUID!) {
    deleteCompanyContact(id: $deleteCompanyContactId)
  }
`;
export type DeleteCompanyContactMutationFn = Apollo.MutationFunction<
  DeleteCompanyContactMutation,
  DeleteCompanyContactMutationVariables
>;

/**
 * __useDeleteCompanyContactMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyContactMutation, { data, loading, error }] = useDeleteCompanyContactMutation({
 *   variables: {
 *      deleteCompanyContactId: // value for 'deleteCompanyContactId'
 *   },
 * });
 */
export function useDeleteCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyContactMutation, DeleteCompanyContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompanyContactMutation, DeleteCompanyContactMutationVariables>(
    DeleteCompanyContactDocument,
    options,
  );
}
export type DeleteCompanyContactMutationHookResult = ReturnType<typeof useDeleteCompanyContactMutation>;
export type DeleteCompanyContactMutationResult = Apollo.MutationResult<DeleteCompanyContactMutation>;
export type DeleteCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyContactMutation,
  DeleteCompanyContactMutationVariables
>;
export const UpdateAccountDetailsDocument = gql`
  mutation UpdateAccountDetails($accountDetails: AccountDetailsInput!) {
    updateAccountDetails(accountDetails: $accountDetails) {
      id
    }
  }
`;
export type UpdateAccountDetailsMutationFn = Apollo.MutationFunction<
  UpdateAccountDetailsMutation,
  UpdateAccountDetailsMutationVariables
>;

/**
 * __useUpdateAccountDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountDetailsMutation, { data, loading, error }] = useUpdateAccountDetailsMutation({
 *   variables: {
 *      accountDetails: // value for 'accountDetails'
 *   },
 * });
 */
export function useUpdateAccountDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountDetailsMutation, UpdateAccountDetailsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountDetailsMutation, UpdateAccountDetailsMutationVariables>(
    UpdateAccountDetailsDocument,
    options,
  );
}
export type UpdateAccountDetailsMutationHookResult = ReturnType<typeof useUpdateAccountDetailsMutation>;
export type UpdateAccountDetailsMutationResult = Apollo.MutationResult<UpdateAccountDetailsMutation>;
export type UpdateAccountDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountDetailsMutation,
  UpdateAccountDetailsMutationVariables
>;
export const LinkRegistryDocument = gql`
  mutation LinkRegistry($registryLink: LinkRegistryInput!) {
    linkRegistry(registryLink: $registryLink) {
      id
      status
    }
  }
`;
export type LinkRegistryMutationFn = Apollo.MutationFunction<LinkRegistryMutation, LinkRegistryMutationVariables>;

/**
 * __useLinkRegistryMutation__
 *
 * To run a mutation, you first call `useLinkRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkRegistryMutation, { data, loading, error }] = useLinkRegistryMutation({
 *   variables: {
 *      registryLink: // value for 'registryLink'
 *   },
 * });
 */
export function useLinkRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkRegistryMutation, LinkRegistryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkRegistryMutation, LinkRegistryMutationVariables>(LinkRegistryDocument, options);
}
export type LinkRegistryMutationHookResult = ReturnType<typeof useLinkRegistryMutation>;
export type LinkRegistryMutationResult = Apollo.MutationResult<LinkRegistryMutation>;
export type LinkRegistryMutationOptions = Apollo.BaseMutationOptions<
  LinkRegistryMutation,
  LinkRegistryMutationVariables
>;
export const BeginDocumentUploadV2Document = gql`
  mutation BeginDocumentUploadV2($document: DocumentInputV2!) {
    beginDocumentUploadV2(document: $document) {
      id
      url
      path
    }
  }
`;
export type BeginDocumentUploadV2MutationFn = Apollo.MutationFunction<
  BeginDocumentUploadV2Mutation,
  BeginDocumentUploadV2MutationVariables
>;

/**
 * __useBeginDocumentUploadV2Mutation__
 *
 * To run a mutation, you first call `useBeginDocumentUploadV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginDocumentUploadV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginDocumentUploadV2Mutation, { data, loading, error }] = useBeginDocumentUploadV2Mutation({
 *   variables: {
 *      document: // value for 'document'
 *   },
 * });
 */
export function useBeginDocumentUploadV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<BeginDocumentUploadV2Mutation, BeginDocumentUploadV2MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BeginDocumentUploadV2Mutation, BeginDocumentUploadV2MutationVariables>(
    BeginDocumentUploadV2Document,
    options,
  );
}
export type BeginDocumentUploadV2MutationHookResult = ReturnType<typeof useBeginDocumentUploadV2Mutation>;
export type BeginDocumentUploadV2MutationResult = Apollo.MutationResult<BeginDocumentUploadV2Mutation>;
export type BeginDocumentUploadV2MutationOptions = Apollo.BaseMutationOptions<
  BeginDocumentUploadV2Mutation,
  BeginDocumentUploadV2MutationVariables
>;
export const EndDocumentUploadDocument = gql`
  mutation EndDocumentUpload($documentId: UUID!) {
    endDocumentUpload(documentId: $documentId)
  }
`;
export type EndDocumentUploadMutationFn = Apollo.MutationFunction<
  EndDocumentUploadMutation,
  EndDocumentUploadMutationVariables
>;

/**
 * __useEndDocumentUploadMutation__
 *
 * To run a mutation, you first call `useEndDocumentUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndDocumentUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endDocumentUploadMutation, { data, loading, error }] = useEndDocumentUploadMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useEndDocumentUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<EndDocumentUploadMutation, EndDocumentUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndDocumentUploadMutation, EndDocumentUploadMutationVariables>(
    EndDocumentUploadDocument,
    options,
  );
}
export type EndDocumentUploadMutationHookResult = ReturnType<typeof useEndDocumentUploadMutation>;
export type EndDocumentUploadMutationResult = Apollo.MutationResult<EndDocumentUploadMutation>;
export type EndDocumentUploadMutationOptions = Apollo.BaseMutationOptions<
  EndDocumentUploadMutation,
  EndDocumentUploadMutationVariables
>;
export const SendActivationEmailDocument = gql`
  mutation SendActivationEmail($email: String!) {
    sendActivationEmail(email: $email)
  }
`;
export type SendActivationEmailMutationFn = Apollo.MutationFunction<
  SendActivationEmailMutation,
  SendActivationEmailMutationVariables
>;

/**
 * __useSendActivationEmailMutation__
 *
 * To run a mutation, you first call `useSendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendActivationEmailMutation, { data, loading, error }] = useSendActivationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendActivationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendActivationEmailMutation, SendActivationEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendActivationEmailMutation, SendActivationEmailMutationVariables>(
    SendActivationEmailDocument,
    options,
  );
}
export type SendActivationEmailMutationHookResult = ReturnType<typeof useSendActivationEmailMutation>;
export type SendActivationEmailMutationResult = Apollo.MutationResult<SendActivationEmailMutation>;
export type SendActivationEmailMutationOptions = Apollo.BaseMutationOptions<
  SendActivationEmailMutation,
  SendActivationEmailMutationVariables
>;
export const SubmitApplicationDocument = gql`
  mutation SubmitApplication {
    submitApplication {
      id
      status
    }
  }
`;
export type SubmitApplicationMutationFn = Apollo.MutationFunction<
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables
>;

/**
 * __useSubmitApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApplicationMutation, { data, loading, error }] = useSubmitApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitApplicationMutation, SubmitApplicationMutationVariables>(
    SubmitApplicationDocument,
    options,
  );
}
export type SubmitApplicationMutationHookResult = ReturnType<typeof useSubmitApplicationMutation>;
export type SubmitApplicationMutationResult = Apollo.MutationResult<SubmitApplicationMutation>;
export type SubmitApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables
>;
export const EditUserPasswordDocument = gql`
  mutation EditUserPassword($oldPassword: String!, $newPassword: String!) {
    editUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
export type EditUserPasswordMutationFn = Apollo.MutationFunction<
  EditUserPasswordMutation,
  EditUserPasswordMutationVariables
>;

/**
 * __useEditUserPasswordMutation__
 *
 * To run a mutation, you first call `useEditUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserPasswordMutation, { data, loading, error }] = useEditUserPasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useEditUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<EditUserPasswordMutation, EditUserPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserPasswordMutation, EditUserPasswordMutationVariables>(
    EditUserPasswordDocument,
    options,
  );
}
export type EditUserPasswordMutationHookResult = ReturnType<typeof useEditUserPasswordMutation>;
export type EditUserPasswordMutationResult = Apollo.MutationResult<EditUserPasswordMutation>;
export type EditUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  EditUserPasswordMutation,
  EditUserPasswordMutationVariables
>;
export const EnrollDocument = gql`
  mutation Enroll($enroll: Factor!) {
    enrollFactor(enroll: $enroll) {
      id
      status
      sharedSecret
      qrCodeHref
    }
  }
`;
export type EnrollMutationFn = Apollo.MutationFunction<EnrollMutation, EnrollMutationVariables>;

/**
 * __useEnrollMutation__
 *
 * To run a mutation, you first call `useEnrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollMutation, { data, loading, error }] = useEnrollMutation({
 *   variables: {
 *      enroll: // value for 'enroll'
 *   },
 * });
 */
export function useEnrollMutation(baseOptions?: Apollo.MutationHookOptions<EnrollMutation, EnrollMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnrollMutation, EnrollMutationVariables>(EnrollDocument, options);
}
export type EnrollMutationHookResult = ReturnType<typeof useEnrollMutation>;
export type EnrollMutationResult = Apollo.MutationResult<EnrollMutation>;
export type EnrollMutationOptions = Apollo.BaseMutationOptions<EnrollMutation, EnrollMutationVariables>;
export const ActivateDocument = gql`
  mutation Activate($activate: ActivateFactor!) {
    activateFactor(activate: $activate)
  }
`;
export type ActivateMutationFn = Apollo.MutationFunction<ActivateMutation, ActivateMutationVariables>;

/**
 * __useActivateMutation__
 *
 * To run a mutation, you first call `useActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMutation, { data, loading, error }] = useActivateMutation({
 *   variables: {
 *      activate: // value for 'activate'
 *   },
 * });
 */
export function useActivateMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateMutation, ActivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateMutation, ActivateMutationVariables>(ActivateDocument, options);
}
export type ActivateMutationHookResult = ReturnType<typeof useActivateMutation>;
export type ActivateMutationResult = Apollo.MutationResult<ActivateMutation>;
export type ActivateMutationOptions = Apollo.BaseMutationOptions<ActivateMutation, ActivateMutationVariables>;
export const ResetFactorDocument = gql`
  mutation ResetFactor($reset: Factor!) {
    resetFactor(reset: $reset)
  }
`;
export type ResetFactorMutationFn = Apollo.MutationFunction<ResetFactorMutation, ResetFactorMutationVariables>;

/**
 * __useResetFactorMutation__
 *
 * To run a mutation, you first call `useResetFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetFactorMutation, { data, loading, error }] = useResetFactorMutation({
 *   variables: {
 *      reset: // value for 'reset'
 *   },
 * });
 */
export function useResetFactorMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetFactorMutation, ResetFactorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetFactorMutation, ResetFactorMutationVariables>(ResetFactorDocument, options);
}
export type ResetFactorMutationHookResult = ReturnType<typeof useResetFactorMutation>;
export type ResetFactorMutationResult = Apollo.MutationResult<ResetFactorMutation>;
export type ResetFactorMutationOptions = Apollo.BaseMutationOptions<ResetFactorMutation, ResetFactorMutationVariables>;
export const CreateRecOrderDocument = gql`
  mutation CreateRecOrder($order: OrderInput!, $attributes: RecOrderAttributeInput!) {
    createRecOrder(order: $order, attributes: $attributes) {
      id
    }
  }
`;
export type CreateRecOrderMutationFn = Apollo.MutationFunction<CreateRecOrderMutation, CreateRecOrderMutationVariables>;

/**
 * __useCreateRecOrderMutation__
 *
 * To run a mutation, you first call `useCreateRecOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecOrderMutation, { data, loading, error }] = useCreateRecOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateRecOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRecOrderMutation, CreateRecOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRecOrderMutation, CreateRecOrderMutationVariables>(CreateRecOrderDocument, options);
}
export type CreateRecOrderMutationHookResult = ReturnType<typeof useCreateRecOrderMutation>;
export type CreateRecOrderMutationResult = Apollo.MutationResult<CreateRecOrderMutation>;
export type CreateRecOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateRecOrderMutation,
  CreateRecOrderMutationVariables
>;
export const CreateTradeContractDocument = gql`
  mutation CreateTradeContract($input: CreateTradeContractInput!) {
    createTradeContract(input: $input) {
      id
    }
  }
`;
export type CreateTradeContractMutationFn = Apollo.MutationFunction<
  CreateTradeContractMutation,
  CreateTradeContractMutationVariables
>;

/**
 * __useCreateTradeContractMutation__
 *
 * To run a mutation, you first call `useCreateTradeContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTradeContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTradeContractMutation, { data, loading, error }] = useCreateTradeContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTradeContractMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTradeContractMutation, CreateTradeContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTradeContractMutation, CreateTradeContractMutationVariables>(
    CreateTradeContractDocument,
    options,
  );
}
export type CreateTradeContractMutationHookResult = ReturnType<typeof useCreateTradeContractMutation>;
export type CreateTradeContractMutationResult = Apollo.MutationResult<CreateTradeContractMutation>;
export type CreateTradeContractMutationOptions = Apollo.BaseMutationOptions<
  CreateTradeContractMutation,
  CreateTradeContractMutationVariables
>;
export const ReviewTradeContractDocument = gql`
  mutation ReviewTradeContract($input: ReviewTradeContractInput!) {
    reviewTradeContract(input: $input) {
      id
    }
  }
`;
export type ReviewTradeContractMutationFn = Apollo.MutationFunction<
  ReviewTradeContractMutation,
  ReviewTradeContractMutationVariables
>;

/**
 * __useReviewTradeContractMutation__
 *
 * To run a mutation, you first call `useReviewTradeContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewTradeContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewTradeContractMutation, { data, loading, error }] = useReviewTradeContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewTradeContractMutation(
  baseOptions?: Apollo.MutationHookOptions<ReviewTradeContractMutation, ReviewTradeContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReviewTradeContractMutation, ReviewTradeContractMutationVariables>(
    ReviewTradeContractDocument,
    options,
  );
}
export type ReviewTradeContractMutationHookResult = ReturnType<typeof useReviewTradeContractMutation>;
export type ReviewTradeContractMutationResult = Apollo.MutationResult<ReviewTradeContractMutation>;
export type ReviewTradeContractMutationOptions = Apollo.BaseMutationOptions<
  ReviewTradeContractMutation,
  ReviewTradeContractMutationVariables
>;
export const CancelOrderDocument = gql`
  mutation CancelOrder($orderId: UUID!) {
    cancelOrder(id: $orderId) {
      id
      initialVolume
      status
      tenantId
      accountId
      assetType
      position
      type
      currentVolume
      completedOn
      price {
        currencyCode
        amount
      }
      createdOn
      updatedOn
      attributes {
        ... on RecOrderAttributes {
          vintage
          location
          fuelSources
          eligibilities
          certifications
        }
      }
    }
  }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const MarkAsReadDocument = gql`
  mutation MarkAsRead($markAsReadId: String!) {
    markAsRead(id: $markAsReadId) {
      id
      status
    }
  }
`;
export type MarkAsReadMutationFn = Apollo.MutationFunction<MarkAsReadMutation, MarkAsReadMutationVariables>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      markAsReadId: // value for 'markAsReadId'
 *   },
 * });
 */
export function useMarkAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAsReadMutation, MarkAsReadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadDocument, options);
}
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMutation, MarkAsReadMutationVariables>;
export const CreateOfferDocument = gql`
  mutation createOffer($input: CreateOfferInput!) {
    createOffer(input: $input) {
      status
      statusMessage
    }
  }
`;
export type CreateOfferMutationFn = Apollo.MutationFunction<CreateOfferMutation, CreateOfferMutationVariables>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CreateOfferDocument, options);
}
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<CreateOfferMutation, CreateOfferMutationVariables>;
export const ReviewOfferDocument = gql`
  mutation ReviewOffer($input: ReviewOfferInput!) {
    reviewOffer(input: $input) {
      processing
      message
    }
  }
`;
export type ReviewOfferMutationFn = Apollo.MutationFunction<ReviewOfferMutation, ReviewOfferMutationVariables>;

/**
 * __useReviewOfferMutation__
 *
 * To run a mutation, you first call `useReviewOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewOfferMutation, { data, loading, error }] = useReviewOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<ReviewOfferMutation, ReviewOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReviewOfferMutation, ReviewOfferMutationVariables>(ReviewOfferDocument, options);
}
export type ReviewOfferMutationHookResult = ReturnType<typeof useReviewOfferMutation>;
export type ReviewOfferMutationResult = Apollo.MutationResult<ReviewOfferMutation>;
export type ReviewOfferMutationOptions = Apollo.BaseMutationOptions<ReviewOfferMutation, ReviewOfferMutationVariables>;
export const AssignAssetsDocument = gql`
  mutation AssignAssets($input: AssetAssignmentInput) {
    assignAssetsToTradeContract(input: $input) {
      status
      statusMessage
      id
    }
  }
`;
export type AssignAssetsMutationFn = Apollo.MutationFunction<AssignAssetsMutation, AssignAssetsMutationVariables>;

/**
 * __useAssignAssetsMutation__
 *
 * To run a mutation, you first call `useAssignAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAssetsMutation, { data, loading, error }] = useAssignAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignAssetsMutation, AssignAssetsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignAssetsMutation, AssignAssetsMutationVariables>(AssignAssetsDocument, options);
}
export type AssignAssetsMutationHookResult = ReturnType<typeof useAssignAssetsMutation>;
export type AssignAssetsMutationResult = Apollo.MutationResult<AssignAssetsMutation>;
export type AssignAssetsMutationOptions = Apollo.BaseMutationOptions<
  AssignAssetsMutation,
  AssignAssetsMutationVariables
>;
export const ExportSummaryDocument = gql`
  query ExportSummary($id: UUID!) {
    exportRequestById(id: $id) {
      id
      exportNumber
      requestedOn
      completedOn
      totalVolume
      status
      statusMessage
      exportRequestDetails {
        ...ExportRequestDetailsFields
        commodityTransactions {
          ...CommodityTransactionFields
        }
      }
    }
  }
  ${ExportRequestDetailsFieldsFragmentDoc}
  ${CommodityTransactionFieldsFragmentDoc}
`;

/**
 * __useExportSummaryQuery__
 *
 * To run a query within a React component, call `useExportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<ExportSummaryQuery, ExportSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportSummaryQuery, ExportSummaryQueryVariables>(ExportSummaryDocument, options);
}
export function useExportSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportSummaryQuery, ExportSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportSummaryQuery, ExportSummaryQueryVariables>(ExportSummaryDocument, options);
}
export type ExportSummaryQueryHookResult = ReturnType<typeof useExportSummaryQuery>;
export type ExportSummaryLazyQueryHookResult = ReturnType<typeof useExportSummaryLazyQuery>;
export type ExportSummaryQueryResult = Apollo.QueryResult<ExportSummaryQuery, ExportSummaryQueryVariables>;
export const ExportDetailsDocument = gql`
  query ExportDetails($id: UUID!) {
    exportRequestById(id: $id) {
      id
      exportNumber
      requestedOn
      status
      statusMessage
      totalVolume
      exportRequestDetails {
        ...ExportRequestDetailsFields
      }
    }
  }
  ${ExportRequestDetailsFieldsFragmentDoc}
`;

/**
 * __useExportDetailsQuery__
 *
 * To run a query within a React component, call `useExportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ExportDetailsQuery, ExportDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportDetailsQuery, ExportDetailsQueryVariables>(ExportDetailsDocument, options);
}
export function useExportDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportDetailsQuery, ExportDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportDetailsQuery, ExportDetailsQueryVariables>(ExportDetailsDocument, options);
}
export type ExportDetailsQueryHookResult = ReturnType<typeof useExportDetailsQuery>;
export type ExportDetailsLazyQueryHookResult = ReturnType<typeof useExportDetailsLazyQuery>;
export type ExportDetailsQueryResult = Apollo.QueryResult<ExportDetailsQuery, ExportDetailsQueryVariables>;
export const ExportRequestsDocument = gql`
  query ExportRequests($input: ExportRequestInput!) {
    exportRequests(input: $input) {
      exportRequests {
        id
        statusMessage
        totalVolume
        requestedOn
        exportNumber
        status
        initiatedBy
      }
      offsetInfo {
        offset
        limit
        total
      }
    }
  }
`;

/**
 * __useExportRequestsQuery__
 *
 * To run a query within a React component, call `useExportRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<ExportRequestsQuery, ExportRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportRequestsQuery, ExportRequestsQueryVariables>(ExportRequestsDocument, options);
}
export function useExportRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportRequestsQuery, ExportRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportRequestsQuery, ExportRequestsQueryVariables>(ExportRequestsDocument, options);
}
export type ExportRequestsQueryHookResult = ReturnType<typeof useExportRequestsQuery>;
export type ExportRequestsLazyQueryHookResult = ReturnType<typeof useExportRequestsLazyQuery>;
export type ExportRequestsQueryResult = Apollo.QueryResult<ExportRequestsQuery, ExportRequestsQueryVariables>;
export const RetirementDetailsDocument = gql`
  query RetirementDetails($id: UUID!, $registryCode: RegistryCode) {
    retirementById(id: $id, registryCode: $registryCode) {
      id
      retirementNumber
      requestedOn
      retirementCategory
      retirementType
      retirementDetails
      status
      statusMessage
      totalVolume
      assetType
      commodityDetails {
        ...RetireRequestDetailsFields
      }
    }
  }
  ${RetireRequestDetailsFieldsFragmentDoc}
`;

/**
 * __useRetirementDetailsQuery__
 *
 * To run a query within a React component, call `useRetirementDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetirementDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetirementDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      registryCode: // value for 'registryCode'
 *   },
 * });
 */
export function useRetirementDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RetirementDetailsQuery, RetirementDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetirementDetailsQuery, RetirementDetailsQueryVariables>(RetirementDetailsDocument, options);
}
export function useRetirementDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetirementDetailsQuery, RetirementDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetirementDetailsQuery, RetirementDetailsQueryVariables>(
    RetirementDetailsDocument,
    options,
  );
}
export type RetirementDetailsQueryHookResult = ReturnType<typeof useRetirementDetailsQuery>;
export type RetirementDetailsLazyQueryHookResult = ReturnType<typeof useRetirementDetailsLazyQuery>;
export type RetirementDetailsQueryResult = Apollo.QueryResult<RetirementDetailsQuery, RetirementDetailsQueryVariables>;
export const RetirementHistoryDocument = gql`
  query RetirementHistory($input: RetirementHistoryInput!) {
    retirementHistory(input: $input) {
      offsetInfo {
        offset
        limit
        total
      }
      retirements {
        id
        retirementNumber
        requestedOn
        retirementCategory
        retirementType
        retirementDetails
        status
        statusMessage
        assetType
        totalVolume
      }
    }
  }
`;

/**
 * __useRetirementHistoryQuery__
 *
 * To run a query within a React component, call `useRetirementHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetirementHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetirementHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetirementHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<RetirementHistoryQuery, RetirementHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetirementHistoryQuery, RetirementHistoryQueryVariables>(RetirementHistoryDocument, options);
}
export function useRetirementHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetirementHistoryQuery, RetirementHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetirementHistoryQuery, RetirementHistoryQueryVariables>(
    RetirementHistoryDocument,
    options,
  );
}
export type RetirementHistoryQueryHookResult = ReturnType<typeof useRetirementHistoryQuery>;
export type RetirementHistoryLazyQueryHookResult = ReturnType<typeof useRetirementHistoryLazyQuery>;
export type RetirementHistoryQueryResult = Apollo.QueryResult<RetirementHistoryQuery, RetirementHistoryQueryVariables>;
export const RetirementSummaryDocument = gql`
  query RetirementSummary($id: UUID!) {
    retirementById(id: $id) {
      id
      accountId
      retirementNumber
      requestedOn
      completedOn
      retirementCategory
      retirementType
      retirementDetails
      totalVolume
      status
      statusMessage
      assetType
      commodityDetails {
        ...RetireRequestDetailsFields
        commodityTransactions {
          ...CommodityTransactionFields
        }
      }
    }
  }
  ${RetireRequestDetailsFieldsFragmentDoc}
  ${CommodityTransactionFieldsFragmentDoc}
`;

/**
 * __useRetirementSummaryQuery__
 *
 * To run a query within a React component, call `useRetirementSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetirementSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetirementSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetirementSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<RetirementSummaryQuery, RetirementSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetirementSummaryQuery, RetirementSummaryQueryVariables>(RetirementSummaryDocument, options);
}
export function useRetirementSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetirementSummaryQuery, RetirementSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetirementSummaryQuery, RetirementSummaryQueryVariables>(
    RetirementSummaryDocument,
    options,
  );
}
export type RetirementSummaryQueryHookResult = ReturnType<typeof useRetirementSummaryQuery>;
export type RetirementSummaryLazyQueryHookResult = ReturnType<typeof useRetirementSummaryLazyQuery>;
export type RetirementSummaryQueryResult = Apollo.QueryResult<RetirementSummaryQuery, RetirementSummaryQueryVariables>;
export const TransactionFeeDocument = gql`
  query TransactionFee($input: TransactionFeeInput!) {
    transactionFee(input: $input) {
      id
      assetType
      buyerTxnFeePercent
      sellerTxnFeePercent
      minimumBuyerFee
      minimumSellerFee
      taxPercentage
    }
  }
`;

/**
 * __useTransactionFeeQuery__
 *
 * To run a query within a React component, call `useTransactionFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionFeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransactionFeeQuery(
  baseOptions: Apollo.QueryHookOptions<TransactionFeeQuery, TransactionFeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionFeeQuery, TransactionFeeQueryVariables>(TransactionFeeDocument, options);
}
export function useTransactionFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionFeeQuery, TransactionFeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionFeeQuery, TransactionFeeQueryVariables>(TransactionFeeDocument, options);
}
export type TransactionFeeQueryHookResult = ReturnType<typeof useTransactionFeeQuery>;
export type TransactionFeeLazyQueryHookResult = ReturnType<typeof useTransactionFeeLazyQuery>;
export type TransactionFeeQueryResult = Apollo.QueryResult<TransactionFeeQuery, TransactionFeeQueryVariables>;
export const PaginatedHoldingsDocument = gql`
  query PaginatedHoldings($input: PaginatedHoldingsInput) {
    paginatedHoldings(input: $input) {
      holdings {
        id
        availableBalance
        currentBalance
        project
        attributes {
          ...RecAssetAttributesFields
        }
      }
      offsetInfo {
        offset
        limit
        total
      }
    }
  }
  ${RecAssetAttributesFieldsFragmentDoc}
`;

/**
 * __usePaginatedHoldingsQuery__
 *
 * To run a query within a React component, call `usePaginatedHoldingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedHoldingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedHoldingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedHoldingsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaginatedHoldingsQuery, PaginatedHoldingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedHoldingsQuery, PaginatedHoldingsQueryVariables>(PaginatedHoldingsDocument, options);
}
export function usePaginatedHoldingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedHoldingsQuery, PaginatedHoldingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedHoldingsQuery, PaginatedHoldingsQueryVariables>(
    PaginatedHoldingsDocument,
    options,
  );
}
export type PaginatedHoldingsQueryHookResult = ReturnType<typeof usePaginatedHoldingsQuery>;
export type PaginatedHoldingsLazyQueryHookResult = ReturnType<typeof usePaginatedHoldingsLazyQuery>;
export type PaginatedHoldingsQueryResult = Apollo.QueryResult<PaginatedHoldingsQuery, PaginatedHoldingsQueryVariables>;
export const MarketOrdersV2Document = gql`
  query MarketOrdersV2($input: MarketOrdersInput) {
    marketOrdersV2(input: $input) {
      data {
        totalAsks
        totalBids
      }
      offsetInfo {
        offset
        limit
        total
      }
      orders {
        id
        tenantId
        accountId
        assetType
        position
        status
        initialVolume
        currentVolume
        completedOn
        createdOn
        orderNumber
        allowCounterOffers
        canCounter
        type
        price {
          currencyCode
          amount
        }
        updatedOn
        attributes {
          ...RecOrderAttributesFields
        }
      }
    }
  }
  ${RecOrderAttributesFieldsFragmentDoc}
`;

/**
 * __useMarketOrdersV2Query__
 *
 * To run a query within a React component, call `useMarketOrdersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useMarketOrdersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketOrdersV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketOrdersV2Query(
  baseOptions?: Apollo.QueryHookOptions<MarketOrdersV2Query, MarketOrdersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketOrdersV2Query, MarketOrdersV2QueryVariables>(MarketOrdersV2Document, options);
}
export function useMarketOrdersV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MarketOrdersV2Query, MarketOrdersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketOrdersV2Query, MarketOrdersV2QueryVariables>(MarketOrdersV2Document, options);
}
export type MarketOrdersV2QueryHookResult = ReturnType<typeof useMarketOrdersV2Query>;
export type MarketOrdersV2LazyQueryHookResult = ReturnType<typeof useMarketOrdersV2LazyQuery>;
export type MarketOrdersV2QueryResult = Apollo.QueryResult<MarketOrdersV2Query, MarketOrdersV2QueryVariables>;
export const TradeContractsDocument = gql`
  query TradeContracts($input: TradeContractMarketQueryInput!) {
    tradeContractMarketQuery(input: $input) {
      tradeContracts {
        id
        account {
          id
          company {
            businessName
            id
          }
        }
        position
        volume
        unitPrice
        currencyCode
        tradeSettlementDate
        createdDate
        attributes {
          ... on RecAttributes {
            vintages
            locations
            projects
            fuelSources
            eligibilities
            certifications
            commencementDate
          }
        }
      }
      offsetInfo {
        offset
        limit
        total
      }
    }
  }
`;

/**
 * __useTradeContractsQuery__
 *
 * To run a query within a React component, call `useTradeContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeContractsQuery(
  baseOptions: Apollo.QueryHookOptions<TradeContractsQuery, TradeContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradeContractsQuery, TradeContractsQueryVariables>(TradeContractsDocument, options);
}
export function useTradeContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TradeContractsQuery, TradeContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradeContractsQuery, TradeContractsQueryVariables>(TradeContractsDocument, options);
}
export type TradeContractsQueryHookResult = ReturnType<typeof useTradeContractsQuery>;
export type TradeContractsLazyQueryHookResult = ReturnType<typeof useTradeContractsLazyQuery>;
export type TradeContractsQueryResult = Apollo.QueryResult<TradeContractsQuery, TradeContractsQueryVariables>;
export const OrderHistoryV2Document = gql`
  query OrderHistoryV2($input: OrderHistoryInput) {
    orderHistoryV2(input: $input) {
      orders {
        id
        tenantId
        accountId
        orderNumber
        assetType
        position
        type
        status
        statusMessage
        initialVolume
        currentVolume
        cancelledVolume
        price {
          amount
          currencyCode
        }
        allowCounterOffers
        completedOn
        createdOn
        updatedOn
        attributes {
          ...RecOrderAttributesFields
        }
        orderExpiry
        expiryDate
        type
      }
      offsetInfo {
        offset
        limit
        total
      }
    }
  }
  ${RecOrderAttributesFieldsFragmentDoc}
`;

/**
 * __useOrderHistoryV2Query__
 *
 * To run a query within a React component, call `useOrderHistoryV2Query` and pass it any options that fit your needs.
 * When your component renders, `useOrderHistoryV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderHistoryV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderHistoryV2Query(
  baseOptions?: Apollo.QueryHookOptions<OrderHistoryV2Query, OrderHistoryV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderHistoryV2Query, OrderHistoryV2QueryVariables>(OrderHistoryV2Document, options);
}
export function useOrderHistoryV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderHistoryV2Query, OrderHistoryV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderHistoryV2Query, OrderHistoryV2QueryVariables>(OrderHistoryV2Document, options);
}
export type OrderHistoryV2QueryHookResult = ReturnType<typeof useOrderHistoryV2Query>;
export type OrderHistoryV2LazyQueryHookResult = ReturnType<typeof useOrderHistoryV2LazyQuery>;
export type OrderHistoryV2QueryResult = Apollo.QueryResult<OrderHistoryV2Query, OrderHistoryV2QueryVariables>;
export const TradingAccountUsersDocument = gql`
  query TradingAccountUsers {
    tradingAccountUsers {
      id
      email
      firstName
      lastName
      tradingAccountUserType
      position
      status
      isAccountOwner
    }
  }
`;

/**
 * __useTradingAccountUsersQuery__
 *
 * To run a query within a React component, call `useTradingAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingAccountUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTradingAccountUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<TradingAccountUsersQuery, TradingAccountUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradingAccountUsersQuery, TradingAccountUsersQueryVariables>(
    TradingAccountUsersDocument,
    options,
  );
}
export function useTradingAccountUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TradingAccountUsersQuery, TradingAccountUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradingAccountUsersQuery, TradingAccountUsersQueryVariables>(
    TradingAccountUsersDocument,
    options,
  );
}
export type TradingAccountUsersQueryHookResult = ReturnType<typeof useTradingAccountUsersQuery>;
export type TradingAccountUsersLazyQueryHookResult = ReturnType<typeof useTradingAccountUsersLazyQuery>;
export type TradingAccountUsersQueryResult = Apollo.QueryResult<
  TradingAccountUsersQuery,
  TradingAccountUsersQueryVariables
>;
export const FewAccountDetailsDocument = gql`
  query FewAccountDetails {
    account {
      id
      termsAcceptedDate
      rulesAcceptedDate
      privacyPolicyAcceptedDate
      status
      tenantId
      firstName
      lastName
      email
      type
      linkedRegistries {
        id
        registryId
        status
        accountType
        registryCode
        registryOrgLinkId
      }
    }
  }
`;

/**
 * __useFewAccountDetailsQuery__
 *
 * To run a query within a React component, call `useFewAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFewAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFewAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFewAccountDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<FewAccountDetailsQuery, FewAccountDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FewAccountDetailsQuery, FewAccountDetailsQueryVariables>(FewAccountDetailsDocument, options);
}
export function useFewAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FewAccountDetailsQuery, FewAccountDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FewAccountDetailsQuery, FewAccountDetailsQueryVariables>(
    FewAccountDetailsDocument,
    options,
  );
}
export type FewAccountDetailsQueryHookResult = ReturnType<typeof useFewAccountDetailsQuery>;
export type FewAccountDetailsLazyQueryHookResult = ReturnType<typeof useFewAccountDetailsLazyQuery>;
export type FewAccountDetailsQueryResult = Apollo.QueryResult<FewAccountDetailsQuery, FewAccountDetailsQueryVariables>;
export const AccountDocument = gql`
  query Account {
    account {
      id
      tenantId
      firstName
      lastName
      email
      privacyPolicyAcceptedDate
      termsAcceptedDate
      rulesAcceptedDate
      nrmTermsAcceptedDate
      linkedRegistries {
        ...LinkedRegistryFields
      }
      type
      status
      company {
        ...CompanyFields
      }
      linkedPaymentMethods {
        ...LinkedPaymentFields
      }
    }
  }
  ${LinkedRegistryFieldsFragmentDoc}
  ${CompanyFieldsFragmentDoc}
  ${LinkedPaymentFieldsFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
}
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const TenantsDocument = gql`
  query Tenants {
    tenants {
      id
      name
      displayName
      startDateTime
      endDateTime
      electricityUom
      localisation {
        id
        country
        countryCode
        currencyCode
        currencySymbol
        currencyConversionRate
      }
      paymentProviders {
        id
        name
        displayName
        description
        paymentMethods {
          id
          code
          displayName
          description
          paymentProvider {
            id
            name
            displayName
            description
          }
        }
      }
    }
  }
`;

/**
 * __useTenantsQuery__
 *
 * To run a query within a React component, call `useTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantsQuery(baseOptions?: Apollo.QueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
}
export function useTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
}
export type TenantsQueryHookResult = ReturnType<typeof useTenantsQuery>;
export type TenantsLazyQueryHookResult = ReturnType<typeof useTenantsLazyQuery>;
export type TenantsQueryResult = Apollo.QueryResult<TenantsQuery, TenantsQueryVariables>;
export const FundingSourceInitialisingTokenDocument = gql`
  query FundingSourceInitialisingToken {
    account {
      id
      linkedPaymentMethods {
        id
        initialisingToken
      }
    }
  }
`;

/**
 * __useFundingSourceInitialisingTokenQuery__
 *
 * To run a query within a React component, call `useFundingSourceInitialisingTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundingSourceInitialisingTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundingSourceInitialisingTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useFundingSourceInitialisingTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FundingSourceInitialisingTokenQuery,
    FundingSourceInitialisingTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FundingSourceInitialisingTokenQuery, FundingSourceInitialisingTokenQueryVariables>(
    FundingSourceInitialisingTokenDocument,
    options,
  );
}
export function useFundingSourceInitialisingTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FundingSourceInitialisingTokenQuery,
    FundingSourceInitialisingTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FundingSourceInitialisingTokenQuery, FundingSourceInitialisingTokenQueryVariables>(
    FundingSourceInitialisingTokenDocument,
    options,
  );
}
export type FundingSourceInitialisingTokenQueryHookResult = ReturnType<typeof useFundingSourceInitialisingTokenQuery>;
export type FundingSourceInitialisingTokenLazyQueryHookResult = ReturnType<
  typeof useFundingSourceInitialisingTokenLazyQuery
>;
export type FundingSourceInitialisingTokenQueryResult = Apollo.QueryResult<
  FundingSourceInitialisingTokenQuery,
  FundingSourceInitialisingTokenQueryVariables
>;
export const AccountPaymentDetailsDocument = gql`
  query AccountPaymentDetails {
    account {
      id
      tenantId
      status
      type
      linkedPaymentMethods {
        id
        status
        statusMessage
        fundingSource {
          id
          status
          statusMessage
          currencyCode
          errorCode
          ... on BankAccountV2 {
            accountName
            accountNumberMasked
            accountType
          }
        }
        paymentMethod {
          id
          code
          displayName
          description
          paymentProvider {
            description
            displayName
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useAccountPaymentDetailsQuery__
 *
 * To run a query within a React component, call `useAccountPaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPaymentDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountPaymentDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountPaymentDetailsQuery, AccountPaymentDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountPaymentDetailsQuery, AccountPaymentDetailsQueryVariables>(
    AccountPaymentDetailsDocument,
    options,
  );
}
export function useAccountPaymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountPaymentDetailsQuery, AccountPaymentDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountPaymentDetailsQuery, AccountPaymentDetailsQueryVariables>(
    AccountPaymentDetailsDocument,
    options,
  );
}
export type AccountPaymentDetailsQueryHookResult = ReturnType<typeof useAccountPaymentDetailsQuery>;
export type AccountPaymentDetailsLazyQueryHookResult = ReturnType<typeof useAccountPaymentDetailsLazyQuery>;
export type AccountPaymentDetailsQueryResult = Apollo.QueryResult<
  AccountPaymentDetailsQuery,
  AccountPaymentDetailsQueryVariables
>;
export const AccountEnrolledFactorDocument = gql`
  query AccountEnrolledFactor {
    account {
      id
      enrolledFactors {
        id
        status
        provider
        type
      }
    }
  }
`;

/**
 * __useAccountEnrolledFactorQuery__
 *
 * To run a query within a React component, call `useAccountEnrolledFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountEnrolledFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEnrolledFactorQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountEnrolledFactorQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountEnrolledFactorQuery, AccountEnrolledFactorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountEnrolledFactorQuery, AccountEnrolledFactorQueryVariables>(
    AccountEnrolledFactorDocument,
    options,
  );
}
export function useAccountEnrolledFactorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountEnrolledFactorQuery, AccountEnrolledFactorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountEnrolledFactorQuery, AccountEnrolledFactorQueryVariables>(
    AccountEnrolledFactorDocument,
    options,
  );
}
export type AccountEnrolledFactorQueryHookResult = ReturnType<typeof useAccountEnrolledFactorQuery>;
export type AccountEnrolledFactorLazyQueryHookResult = ReturnType<typeof useAccountEnrolledFactorLazyQuery>;
export type AccountEnrolledFactorQueryResult = Apollo.QueryResult<
  AccountEnrolledFactorQuery,
  AccountEnrolledFactorQueryVariables
>;
export const AccountLinkedRegistriesDocument = gql`
  query AccountLinkedRegistries {
    account {
      id
      type
      linkedRegistries {
        id
        registryCode
        accountType
        registryOrgLinkId
        registryCode
        accountType
        registryId
        status
      }
    }
  }
`;

/**
 * __useAccountLinkedRegistriesQuery__
 *
 * To run a query within a React component, call `useAccountLinkedRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLinkedRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLinkedRegistriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountLinkedRegistriesQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountLinkedRegistriesQuery, AccountLinkedRegistriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountLinkedRegistriesQuery, AccountLinkedRegistriesQueryVariables>(
    AccountLinkedRegistriesDocument,
    options,
  );
}
export function useAccountLinkedRegistriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountLinkedRegistriesQuery, AccountLinkedRegistriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountLinkedRegistriesQuery, AccountLinkedRegistriesQueryVariables>(
    AccountLinkedRegistriesDocument,
    options,
  );
}
export type AccountLinkedRegistriesQueryHookResult = ReturnType<typeof useAccountLinkedRegistriesQuery>;
export type AccountLinkedRegistriesLazyQueryHookResult = ReturnType<typeof useAccountLinkedRegistriesLazyQuery>;
export type AccountLinkedRegistriesQueryResult = Apollo.QueryResult<
  AccountLinkedRegistriesQuery,
  AccountLinkedRegistriesQueryVariables
>;
export const AccountTenantIdDocument = gql`
  query AccountTenantId {
    account {
      id
      tenantId
    }
  }
`;

/**
 * __useAccountTenantIdQuery__
 *
 * To run a query within a React component, call `useAccountTenantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTenantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTenantIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountTenantIdQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountTenantIdQuery, AccountTenantIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountTenantIdQuery, AccountTenantIdQueryVariables>(AccountTenantIdDocument, options);
}
export function useAccountTenantIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountTenantIdQuery, AccountTenantIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountTenantIdQuery, AccountTenantIdQueryVariables>(AccountTenantIdDocument, options);
}
export type AccountTenantIdQueryHookResult = ReturnType<typeof useAccountTenantIdQuery>;
export type AccountTenantIdLazyQueryHookResult = ReturnType<typeof useAccountTenantIdLazyQuery>;
export type AccountTenantIdQueryResult = Apollo.QueryResult<AccountTenantIdQuery, AccountTenantIdQueryVariables>;
export const BusinessClassificationsDocument = gql`
  query BusinessClassifications {
    businessClassifications {
      id
      name
      industryClassifications {
        id
        name
        businessClassificationName
      }
    }
  }
`;

/**
 * __useBusinessClassificationsQuery__
 *
 * To run a query within a React component, call `useBusinessClassificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessClassificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessClassificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessClassificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessClassificationsQuery, BusinessClassificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessClassificationsQuery, BusinessClassificationsQueryVariables>(
    BusinessClassificationsDocument,
    options,
  );
}
export function useBusinessClassificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessClassificationsQuery, BusinessClassificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessClassificationsQuery, BusinessClassificationsQueryVariables>(
    BusinessClassificationsDocument,
    options,
  );
}
export type BusinessClassificationsQueryHookResult = ReturnType<typeof useBusinessClassificationsQuery>;
export type BusinessClassificationsLazyQueryHookResult = ReturnType<typeof useBusinessClassificationsLazyQuery>;
export type BusinessClassificationsQueryResult = Apollo.QueryResult<
  BusinessClassificationsQuery,
  BusinessClassificationsQueryVariables
>;
export const TransactionSummaryDocument = gql`
  query TransactionSummary($orderId: UUID!) {
    transactionSummary(orderId: $orderId) {
      transactions {
        id
        quantity
        deliveryDate
        trackingSystem
        generator {
          id
          name
          description
          crsEffectiveDate
          crsExpiryDate
        }
        commodityGenerationSummary {
          id
          date
          volume
        }
        buyersDetails {
          id
          partyType
          executedPrice {
            amount
            currencyCode
          }
          totalContractPrice {
            amount
            currencyCode
          }
          fee {
            amount
            currencyCode
          }
        }
        order {
          id
          tenantId
          accountId
          orderNumber
          assetType
          position
          type
          status
          statusMessage
          initialVolume
          currentVolume
          price {
            amount
            currencyCode
          }
          completedOn
          createdOn
          updatedOn
          attributes {
            ...RecOrderAttributesFields
          }
        }
        sellersDetails {
          id
          partyType
          executedPrice {
            amount
            currencyCode
          }
          totalContractPrice {
            amount
            currencyCode
          }
          fee {
            amount
            currencyCode
          }
        }
      }
      order {
        id
        tenantId
        accountId
        orderNumber
        assetType
        position
        type
        status
        statusMessage
        initialVolume
        currentVolume
        price {
          amount
          currencyCode
        }
        completedOn
        createdOn
        updatedOn
        attributes {
          ...RecOrderAttributesFields
        }
      }
    }
  }
  ${RecOrderAttributesFieldsFragmentDoc}
`;

/**
 * __useTransactionSummaryQuery__
 *
 * To run a query within a React component, call `useTransactionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionSummaryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useTransactionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<TransactionSummaryQuery, TransactionSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionSummaryQuery, TransactionSummaryQueryVariables>(
    TransactionSummaryDocument,
    options,
  );
}
export function useTransactionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionSummaryQuery, TransactionSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionSummaryQuery, TransactionSummaryQueryVariables>(
    TransactionSummaryDocument,
    options,
  );
}
export type TransactionSummaryQueryHookResult = ReturnType<typeof useTransactionSummaryQuery>;
export type TransactionSummaryLazyQueryHookResult = ReturnType<typeof useTransactionSummaryLazyQuery>;
export type TransactionSummaryQueryResult = Apollo.QueryResult<
  TransactionSummaryQuery,
  TransactionSummaryQueryVariables
>;
export const CurrentTradingHaltDocument = gql`
  query CurrentTradingHalt {
    currentTradingHalt {
      id
      tenantId
      startDateTime
      endDateTime
      isApplicable
      isPlanned
    }
  }
`;

/**
 * __useCurrentTradingHaltQuery__
 *
 * To run a query within a React component, call `useCurrentTradingHaltQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTradingHaltQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTradingHaltQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentTradingHaltQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentTradingHaltQuery, CurrentTradingHaltQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentTradingHaltQuery, CurrentTradingHaltQueryVariables>(
    CurrentTradingHaltDocument,
    options,
  );
}
export function useCurrentTradingHaltLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentTradingHaltQuery, CurrentTradingHaltQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentTradingHaltQuery, CurrentTradingHaltQueryVariables>(
    CurrentTradingHaltDocument,
    options,
  );
}
export type CurrentTradingHaltQueryHookResult = ReturnType<typeof useCurrentTradingHaltQuery>;
export type CurrentTradingHaltLazyQueryHookResult = ReturnType<typeof useCurrentTradingHaltLazyQuery>;
export type CurrentTradingHaltQueryResult = Apollo.QueryResult<
  CurrentTradingHaltQuery,
  CurrentTradingHaltQueryVariables
>;
export const RegistriesDocument = gql`
  query Registries($where: RegistryFilter) {
    registries(where: $where) {
      id
      code
      shortName
      fullName
    }
  }
`;

/**
 * __useRegistriesQuery__
 *
 * To run a query within a React component, call `useRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRegistriesQuery(baseOptions?: Apollo.QueryHookOptions<RegistriesQuery, RegistriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistriesQuery, RegistriesQueryVariables>(RegistriesDocument, options);
}
export function useRegistriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegistriesQuery, RegistriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegistriesQuery, RegistriesQueryVariables>(RegistriesDocument, options);
}
export type RegistriesQueryHookResult = ReturnType<typeof useRegistriesQuery>;
export type RegistriesLazyQueryHookResult = ReturnType<typeof useRegistriesLazyQuery>;
export type RegistriesQueryResult = Apollo.QueryResult<RegistriesQuery, RegistriesQueryVariables>;
export const InboxMessagesDocument = gql`
  query InboxMessages($input: MessagesInput!) {
    messages(input: $input) {
      messages {
        status
        id
        recipient {
          contactId
          contactName
        }
        sender {
          contactId
          contactName
        }
        subject
        body
        dateCreated
        dateRead
      }
      offsetInfo {
        offset
        limit
        total
      }
    }
  }
`;

/**
 * __useInboxMessagesQuery__
 *
 * To run a query within a React component, call `useInboxMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInboxMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<InboxMessagesQuery, InboxMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxMessagesQuery, InboxMessagesQueryVariables>(InboxMessagesDocument, options);
}
export function useInboxMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InboxMessagesQuery, InboxMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxMessagesQuery, InboxMessagesQueryVariables>(InboxMessagesDocument, options);
}
export type InboxMessagesQueryHookResult = ReturnType<typeof useInboxMessagesQuery>;
export type InboxMessagesLazyQueryHookResult = ReturnType<typeof useInboxMessagesLazyQuery>;
export type InboxMessagesQueryResult = Apollo.QueryResult<InboxMessagesQuery, InboxMessagesQueryVariables>;
export const UnreadMessagesCountDocument = gql`
  query UnreadMessagesCount($input: MessagesInput!) {
    messages(input: $input) {
      offsetInfo {
        total
      }
    }
  }
`;

/**
 * __useUnreadMessagesCountQuery__
 *
 * To run a query within a React component, call `useUnreadMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadMessagesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnreadMessagesCountQuery(
  baseOptions: Apollo.QueryHookOptions<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>(
    UnreadMessagesCountDocument,
    options,
  );
}
export function useUnreadMessagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>(
    UnreadMessagesCountDocument,
    options,
  );
}
export type UnreadMessagesCountQueryHookResult = ReturnType<typeof useUnreadMessagesCountQuery>;
export type UnreadMessagesCountLazyQueryHookResult = ReturnType<typeof useUnreadMessagesCountLazyQuery>;
export type UnreadMessagesCountQueryResult = Apollo.QueryResult<
  UnreadMessagesCountQuery,
  UnreadMessagesCountQueryVariables
>;
export const TradingSummaryDocument = gql`
  query TradingSummary($where: TradingSummaryInput!) {
    tradingSummary(where: $where) {
      startDate
      endDate
      totalTradedVolume
      totalAskVolume
      tradingSummaryDetails {
        account {
          id
          email
          company {
            businessName
          }
        }
        totalFees {
          amount
          currencyCode
        }
        totalAskVolume
        totalBidVolume
        tradedAskVolume
        tradedBidVolume
        receivedAmount {
          amount
          currencyCode
        }
        paidAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;

/**
 * __useTradingSummaryQuery__
 *
 * To run a query within a React component, call `useTradingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingSummaryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTradingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<TradingSummaryQuery, TradingSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradingSummaryQuery, TradingSummaryQueryVariables>(TradingSummaryDocument, options);
}
export function useTradingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TradingSummaryQuery, TradingSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradingSummaryQuery, TradingSummaryQueryVariables>(TradingSummaryDocument, options);
}
export type TradingSummaryQueryHookResult = ReturnType<typeof useTradingSummaryQuery>;
export type TradingSummaryLazyQueryHookResult = ReturnType<typeof useTradingSummaryLazyQuery>;
export type TradingSummaryQueryResult = Apollo.QueryResult<TradingSummaryQuery, TradingSummaryQueryVariables>;
export const CounteredOrdersDocument = gql`
  query CounteredOrders($input: CounteredOrdersInput!) {
    counteredOrders(input: $input) {
      offsetInfo {
        offset
        limit
        total
      }
      orders {
        id
        tenantId
        accountId
        assetType
        position
        status
        initialVolume
        currentVolume
        completedOn
        createdOn
        orderNumber
        allowCounterOffers
        canCounter
        type
        price {
          currencyCode
          amount
        }
        updatedOn
        attributes {
          ...RecOrderAttributesFields
        }
      }
    }
  }
  ${RecOrderAttributesFieldsFragmentDoc}
`;

/**
 * __useCounteredOrdersQuery__
 *
 * To run a query within a React component, call `useCounteredOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounteredOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounteredOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCounteredOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<CounteredOrdersQuery, CounteredOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CounteredOrdersQuery, CounteredOrdersQueryVariables>(CounteredOrdersDocument, options);
}
export function useCounteredOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CounteredOrdersQuery, CounteredOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CounteredOrdersQuery, CounteredOrdersQueryVariables>(CounteredOrdersDocument, options);
}
export type CounteredOrdersQueryHookResult = ReturnType<typeof useCounteredOrdersQuery>;
export type CounteredOrdersLazyQueryHookResult = ReturnType<typeof useCounteredOrdersLazyQuery>;
export type CounteredOrdersQueryResult = Apollo.QueryResult<CounteredOrdersQuery, CounteredOrdersQueryVariables>;
export const OfferGroupsDocument = gql`
  query OfferGroups($filter: OfferGroupFilter!) {
    offerGroups(filter: $filter) {
      tenantId
      offerGroupNumber
      accountId
      sourceOrderNumber
      issuedOrderNumber
      status
      createdOn
    }
  }
`;

/**
 * __useOfferGroupsQuery__
 *
 * To run a query within a React component, call `useOfferGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOfferGroupsQuery(baseOptions: Apollo.QueryHookOptions<OfferGroupsQuery, OfferGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferGroupsQuery, OfferGroupsQueryVariables>(OfferGroupsDocument, options);
}
export function useOfferGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OfferGroupsQuery, OfferGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferGroupsQuery, OfferGroupsQueryVariables>(OfferGroupsDocument, options);
}
export type OfferGroupsQueryHookResult = ReturnType<typeof useOfferGroupsQuery>;
export type OfferGroupsLazyQueryHookResult = ReturnType<typeof useOfferGroupsLazyQuery>;
export type OfferGroupsQueryResult = Apollo.QueryResult<OfferGroupsQuery, OfferGroupsQueryVariables>;
export const CounterOffersInOfferGroupDocument = gql`
  query CounterOffersInOfferGroup($filter: OfferFilter!) {
    offers(filter: $filter) {
      tenantId
      offerGroupNumber
      offerNumber
      fromAccountId
      toAccountId
      unitPrice
      volume
      status
      statusMessage
      counteredOn
      requestedOn
      createdOn
    }
  }
`;

/**
 * __useCounterOffersInOfferGroupQuery__
 *
 * To run a query within a React component, call `useCounterOffersInOfferGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterOffersInOfferGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterOffersInOfferGroupQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCounterOffersInOfferGroupQuery(
  baseOptions: Apollo.QueryHookOptions<CounterOffersInOfferGroupQuery, CounterOffersInOfferGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CounterOffersInOfferGroupQuery, CounterOffersInOfferGroupQueryVariables>(
    CounterOffersInOfferGroupDocument,
    options,
  );
}
export function useCounterOffersInOfferGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CounterOffersInOfferGroupQuery, CounterOffersInOfferGroupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CounterOffersInOfferGroupQuery, CounterOffersInOfferGroupQueryVariables>(
    CounterOffersInOfferGroupDocument,
    options,
  );
}
export type CounterOffersInOfferGroupQueryHookResult = ReturnType<typeof useCounterOffersInOfferGroupQuery>;
export type CounterOffersInOfferGroupLazyQueryHookResult = ReturnType<typeof useCounterOffersInOfferGroupLazyQuery>;
export type CounterOffersInOfferGroupQueryResult = Apollo.QueryResult<
  CounterOffersInOfferGroupQuery,
  CounterOffersInOfferGroupQueryVariables
>;
export const TradeContractHistoryDocument = gql`
  query TradeContractHistory($input: TradeContractHistoryInput!) {
    tradeContractHistory(input: $input) {
      offsetInfo {
        offset
        limit
        total
      }
      tradeContracts {
        id
        tenantId
        tradeContractNumber
        createdDate
        tradeSettlementDate
        tradeType
        updatedOn
        volume
        statusMessage
        status
        registryCode
        unitPrice
        position
        currencyCode
        assetType
        attributes {
          ...RecTradeAttributesFields
        }
        account {
          id
          company {
            businessName
            id
          }
        }
        counterAccount {
          id
          email
          company {
            id
            businessName
          }
        }
      }
    }
  }
  ${RecTradeAttributesFieldsFragmentDoc}
`;

/**
 * __useTradeContractHistoryQuery__
 *
 * To run a query within a React component, call `useTradeContractHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeContractHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeContractHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradeContractHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<TradeContractHistoryQuery, TradeContractHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradeContractHistoryQuery, TradeContractHistoryQueryVariables>(
    TradeContractHistoryDocument,
    options,
  );
}
export function useTradeContractHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TradeContractHistoryQuery, TradeContractHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradeContractHistoryQuery, TradeContractHistoryQueryVariables>(
    TradeContractHistoryDocument,
    options,
  );
}
export type TradeContractHistoryQueryHookResult = ReturnType<typeof useTradeContractHistoryQuery>;
export type TradeContractHistoryLazyQueryHookResult = ReturnType<typeof useTradeContractHistoryLazyQuery>;
export type TradeContractHistoryQueryResult = Apollo.QueryResult<
  TradeContractHistoryQuery,
  TradeContractHistoryQueryVariables
>;
export const TradeContractAssetsByTradeContractIdDocument = gql`
  query TradeContractAssetsByTradeContractId($tradeContractId: UUID!) {
    tradeContractAssetsByTradeContractId(tradeContractId: $tradeContractId) {
      assetType
      id
      matchedContractId
      status
      statusMessage
      tenantId
      tradeContractId
      volume
      attributes {
        ...RecTradeAttributesFields
      }
    }
  }
  ${RecTradeAttributesFieldsFragmentDoc}
`;

/**
 * __useTradeContractAssetsByTradeContractIdQuery__
 *
 * To run a query within a React component, call `useTradeContractAssetsByTradeContractIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeContractAssetsByTradeContractIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeContractAssetsByTradeContractIdQuery({
 *   variables: {
 *      tradeContractId: // value for 'tradeContractId'
 *   },
 * });
 */
export function useTradeContractAssetsByTradeContractIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TradeContractAssetsByTradeContractIdQuery,
    TradeContractAssetsByTradeContractIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradeContractAssetsByTradeContractIdQuery, TradeContractAssetsByTradeContractIdQueryVariables>(
    TradeContractAssetsByTradeContractIdDocument,
    options,
  );
}
export function useTradeContractAssetsByTradeContractIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeContractAssetsByTradeContractIdQuery,
    TradeContractAssetsByTradeContractIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TradeContractAssetsByTradeContractIdQuery,
    TradeContractAssetsByTradeContractIdQueryVariables
  >(TradeContractAssetsByTradeContractIdDocument, options);
}
export type TradeContractAssetsByTradeContractIdQueryHookResult = ReturnType<
  typeof useTradeContractAssetsByTradeContractIdQuery
>;
export type TradeContractAssetsByTradeContractIdLazyQueryHookResult = ReturnType<
  typeof useTradeContractAssetsByTradeContractIdLazyQuery
>;
export type TradeContractAssetsByTradeContractIdQueryResult = Apollo.QueryResult<
  TradeContractAssetsByTradeContractIdQuery,
  TradeContractAssetsByTradeContractIdQueryVariables
>;
export const TradeContractTransactionSummaryDocument = gql`
  query TradeContractTransactionSummary($tradeContractId: UUID!) {
    tradeContractTransactionSummary(tradeContractId: $tradeContractId) {
      tradeContract {
        id
        tenantId
        tradeContractNumber
        createdDate
        account {
          id
          company {
            businessName
          }
        }
        counterAccount {
          id
          company {
            businessName
          }
        }
        position
        volume
        assetType
        attributes {
          ... on RecAttributes {
            vintages
            locations
            projects
            fuelSources
            eligibilities
            certifications
            commencementDate
          }
        }
        registryCode
        status
        statusMessage
        unitPrice
        currencyCode
      }
      tradeTransactions {
        transactionNumber
        position
        matchedDate
        completedDate
        unitPrice
        volume
        transactionFee
        assetType
        currencyCode
        transactionDetails {
          id
          volume
          attributes {
            ... on RecAttributes {
              vintages
              locations
              projects
              fuelSources
              eligibilities
              certifications
              commencementDate
            }
          }
          commodityGenerationSummary {
            id
            date
            volume
          }
          generator {
            id
            name
            commencementDate
            crsEffectiveDate
            crsExpiryDate
          }
          trackingSystem
        }
      }
    }
  }
`;

/**
 * __useTradeContractTransactionSummaryQuery__
 *
 * To run a query within a React component, call `useTradeContractTransactionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeContractTransactionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeContractTransactionSummaryQuery({
 *   variables: {
 *      tradeContractId: // value for 'tradeContractId'
 *   },
 * });
 */
export function useTradeContractTransactionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    TradeContractTransactionSummaryQuery,
    TradeContractTransactionSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradeContractTransactionSummaryQuery, TradeContractTransactionSummaryQueryVariables>(
    TradeContractTransactionSummaryDocument,
    options,
  );
}
export function useTradeContractTransactionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeContractTransactionSummaryQuery,
    TradeContractTransactionSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradeContractTransactionSummaryQuery, TradeContractTransactionSummaryQueryVariables>(
    TradeContractTransactionSummaryDocument,
    options,
  );
}
export type TradeContractTransactionSummaryQueryHookResult = ReturnType<typeof useTradeContractTransactionSummaryQuery>;
export type TradeContractTransactionSummaryLazyQueryHookResult = ReturnType<
  typeof useTradeContractTransactionSummaryLazyQuery
>;
export type TradeContractTransactionSummaryQueryResult = Apollo.QueryResult<
  TradeContractTransactionSummaryQuery,
  TradeContractTransactionSummaryQueryVariables
>;
export const SpotTradeTransactionSummaryDocument = gql`
  query SpotTradeTransactionSummary($orderId: UUID!) {
    spotTradeTransactionSummary(orderId: $orderId) {
      order {
        id
        orderNumber
        tenantId
        account {
          id
          company {
            businessName
          }
        }
        position
        type
        assetType
        status
        statusMessage
        initialVolume
        price {
          amount
          currencyCode
        }
        completedOn
        createdOn
        attributes {
          ... on RecOrderAttributes {
            vintages
            vintage
            locations
            projects
            project
            location
            fuelSources
            eligibilities
            certifications
            vintageHalf
          }
        }
      }
      tradeTransactions {
        transactionNumber
        position
        matchedDate
        completedDate
        assetType
        volume
        unitPrice
        currencyCode
        transactionFee
        transactionDetails {
          id
          volume
          attributes {
            ... on RecAttributes {
              vintages
              locations
              projects
              fuelSources
              eligibilities
              certifications
              commencementDate
            }
          }
          commodityGenerationSummary {
            id
            date
            volume
          }
          generator {
            id
            name
            commencementDate
            crsEffectiveDate
            crsExpiryDate
          }
          trackingSystem
        }
      }
    }
  }
`;

/**
 * __useSpotTradeTransactionSummaryQuery__
 *
 * To run a query within a React component, call `useSpotTradeTransactionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpotTradeTransactionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpotTradeTransactionSummaryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useSpotTradeTransactionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<SpotTradeTransactionSummaryQuery, SpotTradeTransactionSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpotTradeTransactionSummaryQuery, SpotTradeTransactionSummaryQueryVariables>(
    SpotTradeTransactionSummaryDocument,
    options,
  );
}
export function useSpotTradeTransactionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpotTradeTransactionSummaryQuery,
    SpotTradeTransactionSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpotTradeTransactionSummaryQuery, SpotTradeTransactionSummaryQueryVariables>(
    SpotTradeTransactionSummaryDocument,
    options,
  );
}
export type SpotTradeTransactionSummaryQueryHookResult = ReturnType<typeof useSpotTradeTransactionSummaryQuery>;
export type SpotTradeTransactionSummaryLazyQueryHookResult = ReturnType<typeof useSpotTradeTransactionSummaryLazyQuery>;
export type SpotTradeTransactionSummaryQueryResult = Apollo.QueryResult<
  SpotTradeTransactionSummaryQuery,
  SpotTradeTransactionSummaryQueryVariables
>;
export const AssetByAssetCodeDocument = gql`
  query AssetByAssetCode($assetCode: AssetType!, $registryCode: RegistryCode) {
    assetByAssetCode(assetCode: $assetCode, registryCode: $registryCode) {
      id
      code
      name
      displayName
      attributes {
        id
        name
        registryCode
        values {
          code
          displayName
          key
        }
        mandatory
      }
    }
  }
`;

/**
 * __useAssetByAssetCodeQuery__
 *
 * To run a query within a React component, call `useAssetByAssetCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetByAssetCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetByAssetCodeQuery({
 *   variables: {
 *      assetCode: // value for 'assetCode'
 *      registryCode: // value for 'registryCode'
 *   },
 * });
 */
export function useAssetByAssetCodeQuery(
  baseOptions: Apollo.QueryHookOptions<AssetByAssetCodeQuery, AssetByAssetCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetByAssetCodeQuery, AssetByAssetCodeQueryVariables>(AssetByAssetCodeDocument, options);
}
export function useAssetByAssetCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssetByAssetCodeQuery, AssetByAssetCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetByAssetCodeQuery, AssetByAssetCodeQueryVariables>(AssetByAssetCodeDocument, options);
}
export type AssetByAssetCodeQueryHookResult = ReturnType<typeof useAssetByAssetCodeQuery>;
export type AssetByAssetCodeLazyQueryHookResult = ReturnType<typeof useAssetByAssetCodeLazyQuery>;
export type AssetByAssetCodeQueryResult = Apollo.QueryResult<AssetByAssetCodeQuery, AssetByAssetCodeQueryVariables>;
